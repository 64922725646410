
    .banner,
    .banner-with-alert, 
    .banner-with-events,
    .banner-photobkg,
    .banner-event-countdown,
    .banner-2 {
        @include media-breakpoint-down(sm)  {
           padding: 1rem!important;
        }
        @include media-breakpoint-up(md)  {
            padding:2rem!important;
        }
        @include media-breakpoint-up(lg) {
            padding: 2.5rem 8vw!important;
        }
        h1,
        h2,
        h3 {
            font-weight: 500!important;
            font-variation-settings: 'wght' 500!important;
            width: 100%;
            color: $blue-darker;
            font-family: 'Oswald',  'HeaderFallBackMac', 'HeaderFallBackWin', sans-serif!important;
        }
    }
    .banner,
    .banner-2  {
        h1,
        h2,
        h3 {
            text-align:center;
            font-size: 3rem;
            @include media-breakpoint-up(xl) {
                font-size: 3.8vw!important;
            }
        }
        h2 {
            @include media-breakpoint-down(sm) {
                padding-top: .7rem;
            }       
        }
        p {
            font-weight: 500;
            font-variation-settings: 'wght' 500;
            @include media-breakpoint-down(md)  {
                font-size: 1.5rem;
                letter-spacing: 0.007rem;
                font-weight: 500;
                font-variation-settings: 'wght' 500;
            }
            @include media-breakpoint-up(lg) {
                font-size: 1.8vw!important;
                letter-spacing: 0.05rem;
            }
        }
        .banner-image {
            @media screen and (min-device-width:768px) and (max-device-width: 1099px) and (orientation:portrait) {
                text-align: center;
            }
        }
        img {
            width:100%;
            height:auto;
            max-height: 450px;
            @media screen and (min-device-width:768px) and (max-device-width: 1099px) and (orientation:portrait) {
                margin-left:auto;
                margin-right: auto;
                text-align: center;
            }
        }
        .banner-text {
            padding: 1rem;
            .banner-text-div {
                text-align:center;
                width: 100%;
                padding: 1rem;
            }
        }
    }
   
    .hero-photobkg {
        background-repeat: no-repeat;
        background-size: cover;
        @include media-breakpoint-down(md) {
            padding: 1rem;
        }
        @include media-breakpoint-up(lg) { 
            min-height: 500px;
        }
        .hero-photobkg-overlay {
            background: $white;
            text-align: left;
            -webkit-box-shadow: 5px 5px 15px -1px $black-opaque-40;
            box-shadow: 5px 5px 15px -1px $black-opaque-40;
            @include media-breakpoint-down(md) {
                padding:40px 30px 30px;
                background: $blue-lightest;
            }
            @include media-breakpoint-up(lg) { 
                display: flex;
                padding: 40px 60px;
            }
            @media screen and (min-device-width:768px) and (max-device-width: 1099px) and (orientation:landscape) {
                padding: 0 40px 30px;
                
            }
            .broken-line {
                img {
                    aspect-ratio: 100 / 1;
                    @include media-breakpoint-down(md) {
                        width: 100%;
                    }
                }
            }
            .banner-text-box {
                padding: 1rem;
                text-align: left!important;
                h2 {
                    text-align: left!important;
                }
            }
            .banner-text-div {
                width: 100%;
                @include media-breakpoint-down(md) { 
                    padding: 0;
                    text-align: center;
                }
                @include media-breakpoint-up(lg) { 
                    text-align: center;
                    padding: 0;
                    align-self: center;
                }
                @media screen and (min-device-width:768px) and (max-device-width: 1099px) and (orientation:landscape) {
                    top: 10%;
                    padding:0 42px;
                    text-align:center;
                }
                h2 {
                    @include media-breakpoint-down(md) { 
                        margin-top: 0.6rem;
                    }
                }
                p {
                    font-weight: 500;
                    font-variation-settings: 'wght' 500;
                    width: 100%;
                    @include media-breakpoint-down(md) { 
                        text-align: left;
                        font-size: 1.5rem;
                    }
                    @include media-breakpoint-up(lg) {
                        font-size: 1.6rem;
                        line-height: 1.3;
                        letter-spacing: 0.05rem;
                    }
                }
                .banner-button {
                    @include media-breakpoint-down(md) { 
                        padding: 7px 30px !important;
                        margin-bottom: 20px;
                    }
                }
            }
            .banner-button  {
                @media screen and (min-width: 64.063em) and (max-width: 80em){
                    margin-left:auto;
                    margin-right: auto;
                 }
                 @media screen and (min-device-width:768px) and (max-device-width: 1099px) and (orientation:landscape) {
                   margin-left: -20px!important;
                }
            }
        }
    }

    .banner-2 {
        @include media-breakpoint-down(sm) {
            min-height: 350px;
        }
    }
 
    /*.banner-with-events {
        h4 {
            font-weight: 600;
            padding-top:30px;
        }
        p {
            font-weight: 400;
            letter-spacing: 0.05rem;
            @include media-breakpoint-down(md) { 
                font-size: 1.0rem;
            }
            @include media-breakpoint-up(lg) {
                font-size: 1.6rem;
            }
        }
        .events-component {
            @include media-breakpoint-up(lg) {
                padding-right: 120px;
                padding-top:30px;
                padding-left:0;
                margin-left: -14px;
            }
            .small {
                text-align: center;
                font-weight: 400;
            }
        }
    }  
    .banner-event-countdown  {
        .header-cell  {
            display: flex;
            flex-direction: column;
            height: 4.2rem;
            align-items: center;
            justify-content: space-evenly;
            h5, p {
                width: 100%;
                text-align:center;
            }
            p.small {
                font-weight:400;
                font-size: .85rem;
                padding-top: 0;
                margin-top: -2px;
                margin-bottom: auto;
            }
        }
        .lower-grid {
            width: 100%;
        }
        .event-countdown-boxes {
            width: 100%;
            @media screen and (min-width: 64.063em) {
                max-width: 40vw;
            }
        }
        .countdown-boxes {
            padding: 10px;
            @include media-breakpoint-down(md) { 
                width: 100%;
            }
        }
        h5,
        .countdown-box-header {
            text-align: center;
            font-weight: bold;
            line-height: 1;
            padding-top:12px;
            color: $blue-darker;
        }
        h3, 
        .countdown-date {
            text-align: right;
            font-weight: bold;
            font-size: 4.5rem;
            color: $blue-darker;
            margin-left: -20px;
            letter-spacing: -.1rem;
        }
        p {
            padding-top:12px;
            padding-left:0;
            margin-left: -12px;
            line-height: 1.1;
            font-weight: 400;
            margin-top:auto;
            margin-bottom:auto;
        }
        p.event-text {
            font-weight: 500!important;
            padding-top: 18px;
            line-height: 1.4;
            font-size: 1.4rem;
            color: $blue-darker;
            padding-left:0;
            margin-right: -10px;
        }
        .countdown-boxes-flex {
            display:flex;
            width: 100%;
            padding: 10px;
            .countdown-date-cell {
                width: 45%;
                align-self: center;
                @include media-breakpoint-down(md) { 
                    margin-left: 16px;
                }
                h3, 
                .countdown-date {
                    text-align: right;
                    font-weight: bold;
                    font-size: 4.5rem;
                    color: $blue-darker;
                    letter-spacing: -.1rem;
                    padding-top: 10px;
                    padding-right: 10px;
                }
            }
            .countdown-text-cell {          
                display: flex;
                justify-content:center;
                width: 55%;
                p.event-text {
                    font-weight: 500!important;
                    padding-top:0;
                    line-height: 1.4;
                    font-size: 1.4rem;
                    color: $blue-darker;
                    text-align: left;
                    padding-left: 14px;
                }
            }
        }
    } */
    .banner-2 {
        @include media-breakpoint-up(lg) {
            width: 100%;
        }
        .mobile-banner-text {
            @include media-breakpoint-down(md) { 
                letter-spacing: 0.007rem!important;
            }
        }
        .banner-text-div {
            text-align: center;
            width:100%;
        }                     
    }
    .news-events {
        @include media-breakpoint-down(sm) { 
            padding: 30px 20px; 
        }
        @include media-breakpoint-only(md) {
            padding: 10px 20px;
            margin-top: 20px;
          }
          @include media-breakpoint-up(lg) {
            padding-left: 8vw!important;
            padding-right: 8vw!important;
            margin-top: 100px;
          }
        .shadow-box  {
            @include media-breakpoint-down(sm) { 
                margin-bottom: 18px;
                padding: 10px;
            }
            @include media-breakpoint-only(md) {
                padding: 10px;
                width: 100%;
                margin-bottom: 18px;
            }
            @include media-breakpoint-up(lg) {
                width: calc(25% - 2rem);
                margin-left: 1rem;
                margin-right: 1rem;
                padding: 10px;
            }
        }
        .news-date {
            border-right: 1px solid  $red-darker;
            justify-content: center;
            align-content: center;
            display:flex;
            .date-div {
                margin-top: auto;
                margin-bottom: auto;
            }
            h4, h2 {
                color: $blue-darker; 
                margin-left: auto;
                margin-right: auto;
            }
            h4.month {
                line-height:1;
                padding-bottom: 0;
                margin-bottom: 2px;
                padding-top: 10px;
                font-weight: normal;
            }
            h2.day {
                line-height: 1;
                padding-top: 0;
                font-weight: 700;
                font-variation-settings: 'wght' 700;
                font-size: 3.2rem;
                font-family: "Open Sans", sans-serif;
            }
        }
        .news-headline {
            padding:10px 15px;
            h5 {
                color: $gray-darker; 
                font-weight: 700;
                font-variation-settings: 'wght' 700;
                font-size: 1.0rem;
                letter-spacing: 0.02rem;
                margin-bottom: .4rem;
            }
            p {
                font-size: 0.6rem!important;
                color: $black;
                letter-spacing: 0;
                padding-bottom: .4rem;
                margin-bottom: 0;
            }
    
        }
        svg {
            fill: $gray-lighter;
            path {
                fill: $gray-lighter;
            }  
        }  
        .headline-img {
            color: $gray-lighter;
            fill: $gray-lighter;
            width: 1.5rem;
            height: auto;
            svg {
                fill:$gray-lighter;
                path {
                    fill: $gray-lighter;
                }
            }
            &:hover,
            &:active {
                -webkit-filter: hue-rotate(125deg) saturate(200%) sepia(60%) invert(60%);
                filter: hue-rotate(125deg) saturate(200%) sepia(60%) invert(60%);
            }
        }
    } 
    
    .news-events-cell {
        @media screen and (min-width:64.063em) {
            margin-top:-60px;
            margin-bottom: 40px;
        }
        h2.title {
            @include media-breakpoint-down(md) { 
                font-size: 2rem;
                margin-bottom:-30px;
            }
        }
    }  
    .news-events {
        @include media-breakpoint-down(sm) { 
            padding: 30px 20px; 
        }
        @include media-breakpoint-only(md) {
            padding: 10px 20px;
            margin-top: 20px;
          }
          @include media-breakpoint-up(lg) {
            padding-left: 8vw!important;
            padding-right: 8vw!important;
            margin-top: 100px;
          }
        .shadow-box  {
            -webkit-box-shadow: 5px 5px 15px -1px $gray; 
            box-shadow: 5px 5px 15px -1px  $gray;
            min-height: 100px;
            @media screen and (max-width: 40em) {
                margin-bottom: 18px;
                padding: 10px;
            }
            @include media-breakpoint-only(md) {
                padding: 10px;
                width: 100%;
                margin-bottom: 18px;
            }
            @include media-breakpoint-up(lg) {
                width: calc(25% - 2rem);
                margin-left: 1rem;
                margin-right: 1rem;
                padding: 10px;
            }
        }
        .news-date {
            border-right: 1px solid  $red-darker;
            justify-content: center;
            align-content: center;
            display:flex;
            .date-div {
                margin-top: auto;
                margin-bottom: auto;
            }
            h4, h2 {
                color: $blue-darker; 
                margin-left: auto;
                margin-right: auto;
            }
            h4.month,
            .month {
                line-height:1;
                padding-bottom: 0;
                margin-bottom: 2px;
                padding-top: 10px;
                font-weight: normal;
                color: $gray-darker!important;
            }
            h2.day,
            .day {
                line-height: 1;
                padding-top: 0;
                font-weight: 700;
                font-variation-settings: 'wght' 700;
                font-size: 3.2rem;
                font-family: "Open Sans", sans-serif;
                color: $gray-darker!important;
            }
        }
        .news-headline {
            padding:10px 15px;
            h5 {
                color: $gray-darkest; 
                font-weight: 700;
                font-variation-settings: 'wght' 700;
                font-size: 1.0rem;
                letter-spacing: 0.02rem;
                margin-bottom: .4rem;
            }
            p {
                font-size: 0.7rem!important;
                color: $black;
                letter-spacing: 0;
                padding-bottom: .4rem;
                margin-bottom: 0;
            }
    
        }
        svg {
            fill: $gray-lighter;
            path {
                fill: $gray-lighter;
            }  
        }  
        .headline-img {
            color: $gray-lighter;
            fill: $gray-lighter;
            width: 1.5rem;
            height: auto;
            svg {
                fill:$gray-lighter;
                path {
                    fill: $gray-lighter;
                }
            }
            &:hover,
            &:active {
                -webkit-filter: hue-rotate(125deg) saturate(200%) sepia(60%) invert(60%);
                filter: hue-rotate(125deg) saturate(200%) sepia(60%) invert(60%);
            }
        }
    }
    .line-event-banner {
        width: 100%;
        @include media-breakpoint-down(md) {
            padding: 1rem 8vw!important;
        }
        @include media-breakpoint-only(lg) {
            padding: 2rem 8vw;
        }
        @include media-breakpoint-up(xl) {
            // extra large screens
            padding: 2rem 12vw!important;
            //min-height:320px;
        }
        .banner-text {
            text-align: center;
            h1,
            h2,
            h3 {
                text-align:center;
            }
            p {
                padding-top:.4rem;
                text-align:center;
            }
            button {
                margin-left:auto;
                margin-right:auto;
            }   
        }
        .event-boxes {
            padding-top: 2rem;
            padding-left:0;
            padding-right:0;
        }
        .event-box {
            padding: .8rem;
            background: $white;
            margin-bottom: 1rem;
            border-radius: 4px;
            -webkit-box-shadow: 5px 5px 15px -1px $gray-darkest; 
            box-shadow: 5px 5px 15px -1px  $gray-darkest;
            .news-headline {
                padding: 3% 0 0 .3rem;
                text-align:center;
                .grid-y {
                    .small-9 {
                        padding-left:.1rem;
                    }
                }
                h5 {
                    margin-bottom: 0.2rem;
                    line-height: 1.2;
                }
                p {
                    font-size: 1rem;
                    line-height: 1.1;
                    margin-bottom: 0.2rem;
                }
            }
            .news-date {
                border-right: 5px solid  $red-darker;
                justify-content: center;
                align-content: center;
                display:flex;
                .date-div {
                    margin-top: auto;
                    margin-bottom: auto;
                    text-align:center;
                }
                h4, h2 {
                    color: $blue-darker; 
                    margin-left: auto;
                    margin-right: auto;
                }
                .month {
                    line-height:1;
                    padding-bottom: 0;
                    margin-bottom: 2px;
                    padding-top: 10px;
                    font-weight: bold;
                    color: $gray-darker;
                }
                .day {
                    line-height: 1;
                    padding-top: 0;
                    font-weight: 700;
                    font-variation-settings: 'wght' 700;
                    font-size: 3.2rem;
                    font-family: "Open Sans", sans-serif;
                    color: $gray-darker;
                }
            }
        
            .headline-img {
                color: $gray-lighter;
                fill: $gray-lighter;
                width: 1.5rem;
                height: auto;
                margin-top:.2rem;
                svg {
                    fill:$gray-lighter;
                    path {
                        fill: $gray-lighter;
                    }
                }
                &:hover,
                &:active {
                    -webkit-filter: hue-rotate(125deg) saturate(200%) sepia(60%) invert(60%);
                    filter: hue-rotate(125deg) saturate(200%) sepia(60%) invert(60%);
                }
            }
        }
    } 
    .lower-page-banner {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
    .horiz-text-banner {
        .banner-text-div {
            padding: 1rem;
        }
        width: 100%;
        .banner-text {
            text-align: center;
            @include media-breakpoint-down(md) {
                padding: 1rem 8vw!important;
            }
           @include media-breakpoint-only(lg) {
                padding: 3.8rem 12vw !important;
                min-height:320px;
            }
           @include media-breakpoint-up(xl) {
                padding: 4rem 16vw!important;
                min-height:320px;
            }
            h1,
            h2,
            h3 {
                text-align:center;
                font-size: 2.8rem;
                @include media-breakpoint-up(xl) {
                   font-size: 3.5vw!important;
                }
            }
            h2 {
                @include media-breakpoint-down(sm) {
                    padding-top: .7rem;
                }
            }
            .text-div {
                p {
                    padding-top:.4rem;
                    text-align:center;
                    font-size: 1.5rem;
                }
                &.color-blue-darker,
                &.color-blue-darkest,
                &.color-black,
                &.color-red-darkest,
                &.color-red-darker,
                &.color-red,
                &.color-red-orange,
                &.color-gray-darker,
                &.color-gray-darkest,
                &.color-teal {
                    a,
                    p a {
                        color: $blue-darker!important;
                        text-decoration: underline!important;
                        &:hover,
                        &:active,
                        &:visited,
                        &:focus {
                            color: $red-darkest!important;
                            text-decoration: underline!important;
                        }
                    }  
                }
                &.color-white,
                &.color-blue-lightest,
                &.color-blue-lighter,
                &.color-yellow-lightest,
                &.color-yellow-lighter,
                &.color-red-lightest,
                &.color-gray-lightest,
                &.color-gray-lighter {
                    //light tones
                    a,
                    p a {
                        color: $yellow-lighter!important;
                        text-decoration: underline!important;
                        &:hover,
                        &:active,
                        &:visited,
                        &:focus {
                            color: $yellow-lightest!important;
                            text-decoration: underline!important;
                        }
                    } 
                }     
                &.color-mint,
                &.color-blue,
                &.color-blue2,
                &.color-gray {
                    a,
                    p a  {
                        color: $yellow-lightest!important;
                        text-decoration: underline!important;
                        &:hover,
                        &:active,
                        &:visited,
                        &:focus {
                            color: $white!important;
                            text-decoration: underline!important;
                        }
                    }
                }
                &.color-yellow {
                    a,
                    p a {
                        color: $black!important;
                        text-decoration: underline!important;
                        &:hover,
                        &:active,
                        &:visited,
                        &:focus {
                            color: $blue-darker!important;
                            text-decoration: underline!important;
                        }
                    }
                }
            }
            button {
                margin-left:auto;
                margin-right:auto;
            }   
        }
    } 
    .three-button-banner {
        @include media-breakpoint-up(lg) {
            padding-top: 2rem!important;
            padding-bottom: 1.4rem!important; 
            min-height: 260px;           
        }
        h1,
        h2,
        h3 {
            font-size: 3rem;
            @include media-breakpoint-up(xl) {
                font-size: 4vw;
            }
        }
        hr {
            margin-left:auto;
            margin-right: auto;
            border-top: 3px solid;
            @include media-breakpoint-down(sm) {
                width: 70%;
                margin-bottom: 0;
            }
            @include media-breakpoint-only(md) {
                width: 60%;
                margin-bottom: 10px;
            }
            @include media-breakpoint-up(lg) {
                width: 50%;
            }
        }
        hr.last {
            @include media-breakpoint-down(sm) {
                margin-top: -.2rem;
                margin-bottom: 16px;
            }
            @include media-breakpoint-only(md) {
                margin-bottom: 24px;
                margin-top: 12px!important;
            }
            @include media-breakpoint-up(lg) {
                margin-top: 1rem;
            }
        }
        .button-grid {
            @include media-breakpoint-up(lg) {
                margin-top:.5rem;
                padding-bottom: .8rem;
            }
            .banner-button {
                @include media-breakpoint-down (sm) {
                    width: 100%;
                }
            }
        }

    }  

    .banner-lg-headline {
        @include media-breakpoint-up(lg) {
            padding: 2rem 6vw!important;
        }
        h1,
        h2,
        h3 {
            font-size: 3.8rem;
            @include media-breakpoint-up(xl) {
                font-size: 4vw;
            }
        }
    }

