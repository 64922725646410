//@import "node_modules/bootstrap/scss/bootstrap";
@import "bootstrap-custom";
    //
    // Custom styles
    //
    
  @import "icon-list";
  @import "components/colors";
  @import "components/breadcrumbs";
  @import "components/buttons";
  @import "components/header3";
  @import "components/skip-to-main";
  @import "components/gtranslate";
  @import "components/search-dropdown";
  @import "components/search-masthead";
  @import "components/accordion";
  @import "components/alerts";
  @import "components/banners";
  @import "components/elect-card";
  @import "components/left-nav";
  @import "components/disclaimer";
  @import "components/footer";
  @import "components/feature-icon-section";
  @import "components/highlight";
  @import "components/responsive-card-table";
  @import "components/news-releases";
  @import "components/primary-banner";
  @import "tablefilter/tablefilter";
  @import "sortable";

  $enable-gradients: true;
  $enable-shadows: true;

@font-face {
  font-family: 'font-awesome';
  src: url('../fonts/fontawesome-webfont.woff');
  font-display: swap;
}

@font-face {
  font-family: 'Oswald';
  src: 
    url('../fonts/Oswald-VariableFont_wght.ttf') 
    format('truetype-variations'),
    url('../fonts/oswald-variablefont-wght-webfont.woff2') 
    format(‘woff2 supports variations’),
    url('../fonts/oswald-variablefont-wght-webfont.woff2') 
    format('woff2-variations');
  font-weight: 200 700;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: 
    url('../fonts/OpenSans-VariableFont-wdth-wght.woff2') 
    format(‘woff2 supports variations’),
    url('../fonts/OpenSans-VariableFont-wdth-wght.woff2') 
    format('woff2-variations');
  font-weight: 300 800;
  font-display: swap;
}

@font-face {
  font-family: 'HeaderFallbackMac';
  src: local('Arial Narrow Bold');
  font-weight: 700!important;
  size-adjust: 105%;
  ascent-override: 108%;
  descent-override: 20%;
  line-gap-override: 6%;
}

@font-face {
  font-family: 'HeaderFallbackWin';
  src: local('Franklin Gothic Demi Cond');
  //font-weight: bold!important;
  size-adjust: 110%;
  ascent-override: 108%;
  descent-override: 20%;
  line-gap-override: 6%;
}   
    :root {
      --textfont-wght: 300;
      --header-wght: 800;
      --menu-wght:600;
    }

    body {
      margin:0; 
      padding:0;
      background: $white;
    }

    p,
    li,
    td {
      font-variation-settings: "wght" var(--textfont-wght);
    }
    p {
      line-height:1.5;
      margin: .6rem 0;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-variation-settings: "wght" var(--header-wght);
    }
    
    // Style Bootstrap icons
    .bi {
      fill: currentcolor;
    }

    h1, 
    h2, 
    h3, 
    h4, 
    h5, 
    h6,
    .search-drop-title {
        font-family: 'Oswald', 'HeaderFallBackMac', 'HeaderFallBackWin', sans-serif!important;
    }

    h1 {
      @include media-breakpoint-up(lg) {
        font-size: 2.3rem;
      }
    }
    h2 {
      @include media-breakpoint-up(lg) {
        font-size: 1.9rem;
      }
    }
    h3 {
      @include media-breakpoint-up(lg) {
        font-size: 1.65em;
      }
    }
    h4 {
      @include media-breakpoint-up(lg) {
        font-size: 1.4rem;
      }
    }
    h5 {
      @include media-breakpoint-up(lg) {
        font-size: 1.2rem;
      }
    }
    a {
      color: #485fae;
      font-weight: 500;
    }
    .elect-home {
      margin-left:0;
      margin-right: 0;
      padding-left:0;
      padding-right: 0;
    }
    
  .elect-wide {
    @include media-breakpoint-up(lg) {
      padding-left: 14vw;
      padding-right: 14vw;
      max-width: 1600px;
    }
  }

  .img-responsive {
    width: 100%!important;
    padding-bottom: .5rem;
  }

  .manual-language-disclaimer {
    padding: .5rem;
    font-size: .9rem;
    margin-bottom: 1rem;
    margin-top:.8rem;
    background: $blue-lightest;
    border: 1px solid $blue-darker;
    .cell {
        padding:10px 20px;
        h1,
        h2,
        h3,
        h4,
        h5 {
            text-align:center;
            color:$blue-darkest;
            font-size: 1.4rem;
        }
        p {
            text-align:center;
        }
        small {
            text-align:center;
            font-weight:normal;
            @media screen and (max-width: 64em) {
                font-size: .8rem;
            }
        }
    }
}


.content-box-component {
  padding: .7rem 1rem 0;
  margin-bottom: 1rem;
}

#quick-link-drop {
  padding: 3vw 10vw;
  ul {
    margin-left: auto;
    margin-right:auto;
    @include media-breakpoint-down(sm) {
      width: 90%;
    }
    @include media-breakpoint-only(md) {
      width: 70%;
    }
    @include media-breakpoint-up(lg) {
      width: 30%;
    }
  }
}

$theme-colors: ("primary": $blue-darker,"dark": $gray-darkest);
$body-color: $gray-darkest;
$body-bg:$white;
$border-radius: .4rem;
$success: $blue;

html {
    visibility: visible;
    opacity: 1;
}

html {
  scroll-behavior: smooth;
}

body {
  position: relative;
}

@mixin setScrolltopColors($bgColor: #333, $arrowColor: white, $bgColorHover: $bgColor, $arrowColorHover: $arrowColor) {
  #scrolltop-bg {
    fill: $bgColor;
  }
  #scrolltop-arrow {
    fill: $arrowColor;
  }
  a:hover {
    #scrolltop-bg {
      fill: $bgColorHover;
    }
    #scrolltop-arrow {
      fill: $arrowColorHover;
    }
  }
}

.scrolltop-wrap {
  $size: 3rem;
  $offsetBottom: 2rem;
  $offsetHorizontal: 2rem;
  $scrollToRevealDistance: 12rem; // scroll offset to reveal scroll-to-top link
  $color: $blue2;
  box-sizing: border-box;
  position: absolute;
  top: $scrollToRevealDistance;
  right: $offsetHorizontal;
  bottom: 0;
  pointer-events: none;
  backface-visibility: hidden;
  @include setScrolltopColors($color, white, lighten($color, 8%));
  // prevent extra page height if content is smaller than viewport
  // Firefox only
  @supports (-moz-appearance: meterbar) {
    clip: rect(0, $size, auto, 0);
  }
  a {
    $offset: - ($size + $offsetBottom); // pull up + add a small bottom space
    position: fixed; // fallback
    position: sticky;
    top: $offset;
    width: $size;
    height: $size;
    margin-bottom: $offset;
    transform: translateY(100vh); // push to bottom from top (when stuck)
    backface-visibility: hidden;
    display: inline-block;
    text-decoration: none;
    user-select: none;
    pointer-events: all;
    outline: none;
    overflow: hidden;
    svg {
      display: block;
      border-radius: 50%;
      width: 100%;
      height: 100%;
      path {
        transition: all 0.1s;
      }
    }
    #scrolltop-arrow {
      transform: scale(0.66);
      transform-origin: center;
    }
  }
  @media print {
    display: none !important;
  }
}

// presentation styles

/*html {
  overflow-y: scroll;
  overflow-x: hidden;
  &::before {
    content: "";
    display: block;
    backface-visibility: hidden;
    position: fixed;
    top: 0;
    right: -5px;
    bottom: 0;
    left: -5px;
    background: url("https://images.pexels.com/photos/62693/pexels-photo-62693.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260")
      center center no-repeat;
    background-size: cover;
    filter: brightness(1.14) blur(2px);
  }
} */







