
.search-bar-drop {
    border-bottom: none !important;
}
.search-bar-home {
    text-align: center;
    @include media-breakpoint-down(md) {
        margin-left:auto!important;
        margin-right:auto!important;
        padding-left:20px;
    }
    @include media-breakpoint-up(lg) {
        padding-left: 8vw;
    }
}
.search-dropdown {
    display:none;
    width: 100vw!important;
    text-align: center!important;
    background: $blue-lighter!important;
    -webkit-box-shadow:  0px 15px 10px -15px $black-opaque-20;
    box-shadow: 0px 15px 10px -15px $black-opaque-20;
    z-index:11000;
    @include media-breakpoint-down(md) {
        margin-left:0!important;
    }
    @include media-breakpoint-only(md) {
        padding: 1rem 4vw 1.8rem;
    }
    @include media-breakpoint-down(sm) {
        padding: 6px 10px 14px;
    }
    @include media-breakpoint-up(lg) {
        padding: 48px 40px 28px!important;
        position:relative;
    }
    h1,
    h2,
    .search-drop-title,
    .search-drop-subtitle {
        text-align:left!important;
        font-family: 'Oswald', 'HeaderFallBackMac', 'HeaderFallBackWin', sans-serif;
    }
    h1,
    .search-drop-title {
        font-weight:400;
        font-variation-settings: 'wght' 400;
        @include media-breakpoint-down(md) {
            font-size: 1rem!important;
            padding-top: 0.4rem;
        }
        @include media-breakpoint-up(lg) {
            font-size: 1.6rem!important;
        }
    }
    h2,
    .search-drop-subtitle {
        font-weight: 500;
        font-variation-settings: 'wght' 500;
        color: $blue-darker;
        @include media-breakpoint-down(md) {
            font-size: 2.3rem !important;
            line-height: 1;
            margin-top: -4px;
            margin-bottom: -8px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 2.6rem!important;
            line-height: 1.1;  
            margin-bottom:-3px;  
        }
    }
    form {
        margin-left:auto;
        margin-right:auto;
        background:transparent;
        z-index:899;
        @include media-breakpoint-down(sm) {
            width: 100%;
            padding-left:0;
            margin-bottom: -1.7rem;
        }
        @include media-breakpoint-only(md){
            padding-left: 10px!important;
            padding-right: 10px!important;
            margin-bottom: -1.7rem;
            padding-bottom:2rem;
        }
        @include media-breakpoint-up(lg) {
            text-align:left;
            padding-bottom: 14px;
        }
        .label-hide {
            display: none;
        }
        input[type="text"],
        .navbar-search {
            border-radius: 6px;
            border: 1px solid $blue;
            outline: 1px solid $blue;
            box-shadow: none !important;
            -webkit-box-shadow: none !important;
            background: $blue-lightest;
            font-style: italic;
            padding: 8px;
            margin-top: 24px;
            margin-bottom:0;
            color: $gray;
            text-align: left;
            @include media-breakpoint-down(md) {
                font-size: 1.5rem;
                height: 3rem;
                margin-left: -10px;
                width: 100%
            }
            @include media-breakpoint-only(md) {
                width: 48vw;
                font-size:1.2rem;
                margin-top: 1rem;
            }
            @include media-breakpoint-up(lg) {
                font-size: 2.6rem;
                height: 4.0rem; 
                width: 36vw;
                margin-left: 0;
            }
            &:active,
            &:hover,
            &:focus {
                border: 2px solid $blue-darker!important;
                outline:2px solid $blue-darker!important;
                @include media-breakpoint-down(md) {
                    -webkit-box-shadow: 5px 5px 15px -1px $blue-lighter!important;
                    box-shadow: 5px 5px 15px -1px $blue-lighter!important;
                    border: 3px solid $blue-lightest!important;
                    background: $white;
                }
                @include media-breakpoint-up(lg) {
                    -webkit-box-shadow: 5px 5px 15px -1px #adc7de!important;
                    box-shadow: 5px 5px 15px -1px #adc7de!important;
                    background: $white;
                }
            }
        }   
        .search-go-button {
            background: none;
            color: $blue-darker;
            border:0;
            width: fit-content;
            z-index:10;
            padding:0;
            position:relative;

            @include media-breakpoint-down(sm) {
                left: 32vw;
                margin-left: auto;
                margin-right: -5vw;
                position: relative;
                top: -3rem;
                .fa-search {
                     font-size:2.4rem!important;
                }
            }
            @include media-breakpoint-only(md) {
                margin-left: -4vw;
                margin-top:-20rem; 
                .fa-search {
                    font-size: 3rem!important;
                }
            }
            @include media-breakpoint-only(lg) {
                right: 5vw;
                font-size: 3rem!important;
                margin-top: -4rem;
            }
            @include media-breakpoint-only(xl) {
                font-size: 3rem!important;
                left: -4.5vw;
                margin-top: -4rem;
                top: -0.1rem;
            }
            @include media-breakpoint-up(xxl) {
                font-size: 3rem!important;
                left: -4vw;
                margin-top: -4rem;
                top: -0.1rem;
            }
            .search-go {
                @include media-breakpoint-up(lg) {
                    &:active,
                    &:hover,
                    &:focus {
                        border:0!important;
                    }
                }
            }
        }
    }
    /* .search-tags {
        display: flex;
        flex-direction: row;
        padding: 0;
        justify-content: center;
        align-items: flex-start;
        margin-top: -3px;
        @include media-breakpoint-down(md)  {
            width: 100%;
            font-size: 0;
        }
        @include media-breakpoint-up(lg) {
            width: 90%;
            font-size:1.2rem;
        }
       .search-tag {
            @include media-breakpoint-up(lg) {
                display: inline;
                margin: 0.3rem 0.6rem;
                padding: 6px 0 0;
                a {
                    display: block;
                    color: $blue-darker;
                    text-decoration:underline;
                    text-align: center;
                    font-weight:300;
                    margin-top:-4px;
                    padding-top:0;
                    &:hover {
                        color: $blue;
                    } 
                }             
            }
        } 
    } */
}

