button,
.button {
    font-family:'Open Sans', sans-serif;
    border-radius: .5rem;
    padding: 12px 52px;
    cursor: pointer;
    font-weight: 500;
    font-variation-settings: 'wght' 500;
    text-align: center;
    line-height: 1;
    font-size: 1.1rem;
    border: 0!important;
    margin: 0 0 1.25rem;
    color: $white;
    transition: background-color 300ms ease-out;
    &:hover,
    &:active,
    &:focus {           
        filter: saturate(0.8) brightness(110%)!important;
        -webkit-filter: saturate(0.8) brightness(110%)!important;
        -webkit-box-shadow:0px 0px 10px 3px rgba(26, 26, 26, 0.4);
        -moz-box-shadow:0px 0px 10px 3px rgba(26, 26, 26, 0.4);
        box-shadow:0px 0px 10px 3px rgba(26, 26, 26, 0.4);
    }
}
.banner-button {
    -webkit-box-shadow: 5px 5px 15px -1px $black-opaque-20;
    box-shadow: 5px 5px 15px -1px $black-opaque-20;
    margin-top: 0;
    @media screen and (min-width: 64.063em) and (max-width: 80em){
        margin-left:auto;
        margin-right: auto;
     }
    @media screen and (min-device-width:768px) and (max-device-width: 1099px) and (orientation:landscape) {
        margin-right: auto;
    }
    @media screen and (min-width: 88.063em) {
        margin-top:10px;
    }
}
.action-button {
    text-align: center;
    -webkit-box-shadow: 5px 5px 15px -1px $black-opaque-20;
    box-shadow: 5px 5px 15px -1px $black-opaque-20;
    margin-top: 0;
    @media screen and (min-width: 64.063em) {
        // width: 160px;
    }
}
/*
.button-yellow, 
.banner-button-yellow,
.action-button-yellow {
    background: $yellow;
    color: $blue-darkest;
    &:hover,
    &:focus {
        background: $yellow-lighter;
    }
    &:active {
        background: $yellow-lightest;
    }
}
.button-green, 
.banner-button-green,
.action-button-green {
    background: $green;
    color: $white;
    &:hover,
    &:focus {
        background: $green-lighter;
    }
    &:active {
        background: $green-lightest;
        color: $green-darkest;
    }
} */
.button-white,
.banner-button-white {
    background: $white!important;
    color: $blue-darkest;
    &:hover,
    &:focus {
        background: $gray-lightest;
        color: $blue-darker;
    }
    &:active {
        background: $gray-lightest;
        color: $blue2;
    }
}
.button-red,
.banner-button-red,
.action-button-red {
    background: $red!important;
    color: $white;
    &:hover,
    &:focus {
        background: $red-orange!important;
    }
    &:active {
        background: $red-lighter;
        color: $gray-darker;
    }
}
.button-black,
.banner-button-black,
.action-button-black {
    background: $black!important;
    color: $white;
    &:hover,
    &:focus,
    &:active {
        background: $gray-darker!important;
    }
    &:active {
        background: $gray!important;
    }
}
.button-blue,
.banner-button-blue,
.action-button-blue {
    background: $blue-darker!important;
    color: $white;
    &:hover,
    &:focus {
        background: $blue2!important;
    }
    &:active {
        background: $blue-lighter!important;
        color: $gray-darker;
    }
}
/*
.button-teal,
.banner-button-teal,
.action-button-teal {
    background: $teal;
    color: $white;
    &:hover,
    &:focus,
    &:active {
        background: $aqua;
    }
    &:active {
        background: $mint;
        color: $gray-darker;
    }
}  */
.button-icon {
    height: 1.1rem;
    width: auto;
}
.button-icon-left {
    padding-right: 8px;
}
.button-icon-right {
    padding-left: 8px;
}  
     
@media only screen and (min-width: 40.063em) {
    button,
    .button {
        display: inline-block;
    }
}
@media only screen and (max-width: 767px) {
    .side-menu-button {
        transform: rotate(90deg);
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        position: sticky;
        left: -30%;
        margin-left:-70px;
        top:170px;
        height:40px;
        line-height:1.0;
        padding-top:0.6rem;
        padding-bottom: 0.4rem;
        background: $red-darker;
    }
}
