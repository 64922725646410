
 .lang-select {
    @media screen and (max-width: 40em) {
      padding-top: 0;
      margin-top: -6px;
    }
    @media screen and (min-width:40.063em) and (max-width: 64em) {
      padding-top: 0;
      margin-top: 0;
    }
 }
 
 
 #google_translate_element {
    position:static;
    width: 187px;
    height: 28px;
    color: $gray-darkest;
    border-radius: 0 4px;
    padding-left:20px;  
    z-index: 2100;
    border:0!important;
    @media screen and (max-width:40em) {
      margin-right: auto;
      margin-left: 22vw;
      margin-top: -3.2rem;
      z-index:10000;
    }
    @media screen and (min-width:40.063em) and (max-width:64em) {
      margin-right: auto;
      margin-left: 38vw;
      margin-top:-10px;
    }
    @media screen and (min-width: 64.063em) {
      float:right;
      margin-right: 1vw!important;
      margin-top: -1.6rem;
    }
  }
 .VIpgJd-ZVi9od-vH1Gmf {
    margin-left: 40vw;
  } 
  .google_translate_element-fixed {
    @media screen and (min-width: 64.063em) {
      margin-top:0;
    }
    .goog-te-gadget,
    .goog-te-gadget-simple {
      border: 1px solid $gray-darker!important;
      background:$white;
    }
    .goog-te-gadget-simple {
       width:170px;
            }                                                                                        
    .goog-te-gadget-simple img {
      margin-right: 4px;
      padding-right:0;
    }
    .goog-te-menu-value {
      border-bottom:0!important;
    }
}
a .goog-te-menu-value, 
.goog-te-menu-value span {
  color: #000;
}
.goog-te-gadget-simple {
  padding-left:10px!important;
  padding-top:4px!important;
  position: relative;
  z-index: 1000;
}

.goog-te-banner-frame.skiptranslate {
  display: none!important;
} 
