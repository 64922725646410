.search-form-masthead {
    form {
        z-index:11000;
        background:transparent;   
        @include media-breakpoint-down(sm) {
            width: 100%;
            padding-left:0;
            margin-bottom: -1.7rem;
        }
        @include media-breakpoint-only(md){
            padding-left: 10px!important;
            padding-right: 10px!important;
           // margin-bottom: -1.7rem;
            padding-bottom:2rem;
        }
        @include media-breakpoint-up(lg) {
            margin-right: 0;
            width: 14vw;
            text-align: right;
            margin-left: auto;
            margin-top: 0.3rem;
        }
        .label-hide {
            display: none;
        }
        input[type="text"],
        input[type="search"],
        .navbar-search {
            border-radius: 6px;
            border: 1px solid $blue2;
            outline: 1px solid $blue2;
            box-shadow: none !important;
            -webkit-box-shadow: none !important;
            background: $blue-lightest;
            font-style: italic;
            padding: 2px 8px;
            margin-top: .2rem;
            margin-bottom:0;
            color: $gray-darker;
            text-align: left;
            @include media-breakpoint-down(md) {
                font-size: 1.5rem;
                height: 3rem;
                margin-left: -10px;
                width: 100%
            }
            @include media-breakpoint-only(md) {
                width: 24vw;
                font-size:1rem;
                margin-top: .2rem;
            }
            @include media-breakpoint-up(lg) {
                font-size: 1.1rem;
                max-width: 20vw;
            }

            &:active,
            &:hover,
            &:focus {
                border: 1px solid $blue!important;
                outline:1px solid $blue!important;
                @include media-breakpoint-down(md) {
                    -webkit-box-shadow: 5px 5px 15px -1px $blue-lighter!important;
                    box-shadow: 5px 5px 15px -1px $blue-lighter!important;
                    border: 3px solid $blue-lightest!important;
                    background: $white;
                }
                @include media-breakpoint-up(lg) {
                    -webkit-box-shadow: 5px 5px 15px -1px #adc7de!important;
                    box-shadow: 5px 5px 15px -1px #adc7de!important;
                    background: $white;
                }
            }
        }   
        .search-go-button {
            background: none;
            color: $blue;
            border:0;
            width: fit-content;
            z-index:10;
            padding:0;
            position:relative;

            @include media-breakpoint-down(sm) {
                left: 32vw;
                margin-left: auto;
                margin-right: -5vw;
                position: relative;
                top: -3rem;
                .fa-search {
                     font-size:2.4rem!important;
                }
            }
            @include media-breakpoint-only(md) {
                margin-left: -4vw;
                margin-top:-20rem; 
                .fa-search {
                    font-size: 2rem!important;
                }
            }
            @include media-breakpoint-only(lg) {
                font-size: 1.6rem !important;
                margin-top: -1.9rem;
                float: right;
                margin-right: 0.4rem;
            }
            @include media-breakpoint-only(xl) {
                font-size: 1.7rem!important;
                margin-top: -1.9rem;
                float: right;
                margin-right: 0.4rem;
            }
            @include media-breakpoint-up(xxl) {
                font-size: 1.6rem !important;
                margin-top: -1.9rem;
                float: right;
                margin-right: 0.4rem;
            }
            &:active,
            &:hover,
            &:focus {
                box-shadow:none!important;
                -webkit-box-shadow: none!important;
            }
            .search-go {
                @include media-breakpoint-up(lg) {
                    &:active,
                    &:hover,
                    &:focus {
                        color:$blue-darker;
                        box-shadow:none;
                        -webkit-box-shadow: none;
                    }
                }
            }
        }
    }
}

