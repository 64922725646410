
@mixin scrollbars($size, $foreground-color, $background: mix($foreground-color, white, 50%)) {
    ::-webkit-scrollbar {
        width:  $size;
        height: $size;
    }
  
    ::-webkit-scrollbar-thumb {
        background: $foreground-color;
    }
  
    ::-webkit-scrollbar-track {
        background: $background;
    }
}   

tr.hidden {
	display: none;
}
    
.wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
}


.previous-page-link {
    @include media-breakpoint-down(sm) {
        float:left;
        padding-left: 10px;
    }
}

.table-wrapper {
    margin: 0 auto 0;
    max-height: 500px;
    overflow-y: scroll;
    position: relative;
    transition: all .2s ease-out;
    @include media-breakpoint-up(md) {
        width: 100%;
    }
    @include media-breakpoint-up(1g) {
        max-width: 2000px;
    }
}
.card-list-table {
    table-layout: fixed;
    margin-bottom: 0;
    width: 100%;
    font-size: 1rem;
    @include media-breakpoint-down(sm) {
        background: transparent;
    }
    @include media-breakpoint-up(md) {
        background: $white;
    }
    tbody {
        tr {
            background: $white;
            @include media-breakpoint-down(sm) {
                display: block;
                padding: 5px 10px;
                margin: 0 0 10px 0;
                border: 1px solid $gray-lighter; 
                -webkit-box-shadow:0px 0px 4px 3px rgba(26, 26, 26, 0.1);
                -moz-box-shadow:0px 0px 4px 3px rgba(26, 26, 26, 0.1);
                box-shadow:0px 0px 4px 3px rgba(26, 26, 26, 0.1);
            }
            @include media-breakpoint-up(md) {
                margin: 0;
                display: table-row;
                padding-bottom: 0;
                border-bottom: 1px solid $gray-lighter; 
            }    
        }
        td {                
            border: 0;
            position: relative;
            @include media-breakpoint-down(sm) {    
                display: block;
                padding: 0 10px 10px 40%;
            }
            @include media-breakpoint-up(md) {
                cursor: pointer;
                display: table-cell;
                padding: 20px 24px;
                transition: background .2s ease-out;
                vertical-align: middle;
            }
            &:last-of-type {
                @include media-breakpoint-down(sm) {
                    margin-bottom:-5px!important;
                }
            }   
            &:after {
                content: '';
                width: calc(100% - 30px);
                margin: 0 auto;
                height: 1px;
                background: $gray-lighter;
                position: absolute;
                left: 0;
                right: 0;
                top: -6px;
                @include media-breakpoint-down(sm) {
                    display: block;
                }
                @include media-breakpoint-up(md) {
                    display: none;
                }
            }
            &:before {
                color: $blue-darker;
                font-size: 1rem;
                display: table-cell;
                font-weight: 700;
                font-variation-settings: 'wght' 700;
                height: 100%;
                left: 15px;
                margin: auto;
                position: absolute;
                vertical-align: middle;
                white-space: nowrap;
                width: 40%;
                }
            &:first-of-type::after {
                visibility: hidden;
            }

            tr:hover td {
                @include media-breakpoint-up(md) {
                    background: $yellow-lighter;
                }
            }
        }
    }
    thead {
        @include media-breakpoint-down(sm) {
            display: none;
        }
        tr {
            @include media-breakpoint-up(md) {
              background: $gray-lightest;
              color: $blue-darker;
              border-bottom: 1px $gray-lighter;
            }
        }
        th {
            color: $blue-darker;
            letter-spacing: .5pt;
            @include media-breakpoint-up(md) {
                padding: 12px 24px;
            }

        }
    }
}
.table-responsive-stack {
    tr {
        font-size: .8rem;
        @include media-breakpoint-down(sm) {
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
                -ms-flex-direction: column;
                    flex-direction: column;
            border-bottom: 3px solid #ccc;
            display:block;
            
         }        
    }
    td,
    th {
        @include media-breakpoint-down(sm) {
            -ms-flex: 1 1 auto;
             flex: 1 1 auto;
        }
    }

    td {
        @include media-breakpoint-down(sm){
            display:block;
            /* ie 9 fix */
            float: left\9;
            width:100%;
        }
    }     
    .table-responsive-stack-thead {
        font-weight: bold;
        font-variation-settings: 'wght' 600;
    }
}

div[id^="excel"] {
    table,
    .table-responsive-stack {
        border: 1px solid $gray-lightest;
        border-collapse: collapse;
        margin: 1rem 0;
        padding: 0;
        width: 100%;
        caption {
            font-size: 1.5em;
            margin: .5em 0 .75em;
        }
        tr {
            font-size: .8rem;
            background: $white;
            border: 1px solid $gray-lightest;
            padding: .3rem;
        }
        tr:nth-child(odd):not(first-child) td {
            background: $gray-extra-light;
        }
        tr:first-child {
            font-weight: bold;
            font-variation-settings: 'wght' 600;
        }
        th,
        td {
            padding: .3rem;
            @include media-breakpoint-up(md) {
                text-align: center;  
            }
        }
        th {
            font-size: .8rem;
            background: $gray-lightest;
           // text-transform: uppercase;
        }
    }
    @include media-breakpoint-down(sm) {
        table {
            border:0;
            thead {
                border: none;
                clip: rect(0 0 0 0);
                height: 1px;
                margin: -1px;
                overflow: hidden;
                padding: 0;
                position: absolute;
                width: 1px;
            }
            tr {
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                display:block; 
                border-bottom:0!important;
                margin-bottom: .625em; 
            }
            tr:first-of-type {
                display:none;
            }
            caption {
                font-size: 1.3em;
            }
            td,
            th {
                display:block;
                -ms-flex: 1 1 auto;
                flex: 1 1 auto;
            }
            td {
                width:100%;
                border-bottom: 1px solid $gray-lightest;
                display: block;
                font-size: .8rem;
                text-align: right;       
            }
            td::before {
                content: attr(data-label);
                float: left;
                font-weight: bold;
                font-variation-settings: 'wght' 600;
                text-transform: uppercase;
            }
            td:last-child {
                border-bottom: 0;
            } 
        }
    }       
}
 
