.accordion {
    margin: 40px 0;
    width: 100%;
    --#{$prefix}accordion-btn-focus-border-color: $white;
    --#{$prefix}accordion-btn-focus-box-shadow: 0;
    @media screen and (max-width: 40em) {
        padding: 20px;
    }
    @media screen and (min-width: 40.063em) {
        padding: 20px 100px;
    }  
    .accordion-item {
        width: 100%;
        padding-bottom:0;
        background: $white!important;
        border-radius: 8px;
    }
    .p1 {
        font-size: 1rem;
    }
  .accordion-header {
        font-size: 1.2rem;
        font-weight: 500;
        font-variation-settings: 'wght' 500;
        color: $black;
        margin-bottom:-10px;
        padding-bottom:0;
        padding-top:6px;
        &:active, 
        &:hover,
        &:focus{
            border:0!important;
            outline:0!important;
            box-shadow: 0!important;
        }
        .collapse {
            &:active, 
            &:hover,
            &:focus {
                border:0!important;
                outline:0!important;
                box-shadow: 0!important;
            }
        }
        .accordion-button {
            font-size: 1.2rem;
            &:active, 
            &:hover,
            &:focus {
                border:0!important;
                outline:0!important;
                box-shadow: 0!important;
                -webkit-box-shadow:0;
                -moz-box-shadow:0;
                border-color: $white!important;
                font-weight: 600!important; 
                font-variation-settings: 'wght' 600;
            }
            &:not(.collapsed) {
                background: $white!important;
                box-shadow: 0!important;
                -webkit-box-shadow:0;
                -moz-box-shadow:0;
            }
            .accordion-title {
                &:active,
                &:hover,
                &:focus {
                    font-weight: 700!important;
                    font-variation-settings: 'wght' 700;
                    color: $blue-darker!important;
                }
            }
        }
    }
    .accordion-body {
        font-size: 1.2rem;
        background: $white!important;
    }
} /*
.fw-accordion {
    .accordion-item {
        padding: 1rem;
        .accordion-header {
            padding-bottom: 0;
            margin-bottom: 0;
            font-weight: normal;
            font-size: 1.2rem;
            &:hover,
            &:active,
            &:focus {
                color: $blue-darker;
                font-style: normal;
            }
        }
        .accordion-body {
            p {
                font-size: 1rem;
                font-weight: normal;
            }
            img {
                float: left;
                padding-left: 1rem;
                width: 110px;
                border: 1px solid $gray-darker;
            }
        }
    }
} */
