.elect-alert-banner {
    width: 100%;
    padding: 8px;
    margin: 0;
    text-align: center;

    @include media-breakpoint-up(lg) {
        margin-top:0!important;
        margin-bottom: 0;
    }
    p {
        text-align:center;
        margin: auto; 
        font-size: 1.0rem;
        color: inherit;
        font-weight: 500;
        font-variation-settings: 'wght' 500;
        letter-spacing: 0.08rem; 
        @include media-breakpoint-up(lg) {
            font-size: 0.9rem;
        }
        a {
            text-decoration: underline!important;
            color: inherit; 
            &:hover,
            &:focus,
            &:active {
                transform: scale (1.1, 1.1);
            }
        }
    }
}
.elect-body {
    --bs-dropdown-border-width:0!important;
    --bs-dropdown-border-color:transparent!important;
}
.dropdown-banner {

    .show {
        background-color: $red-darker!important;
        border-width: 0!important;
        border-color: transparent!important;
    }
    .dropdown-toggle {
        background-color:$red-darker;
        font-size: 1.2rem;
        font-style: italic;
        font-weight: 600;
        &::after {
            background-color:$red-darker;
            content:""!important;
            display: none;
        }
        &:hover,
        &:focus,
        &:active {
            background-color: $red-darker;
            border: 1px solid $red-darker2;
            border-radius:0;
        }
    }
    a.show {
        border: 1px solid $red!important;
    }
    ul {
        width: 100%;
        margin: -1rem 0 0!important;
        padding-left: 40vw;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        border-radius:0;
        background-color: $red-darker;
        list-style: disc !important;
        color: $white;
        li {
            font-weight: 600!important;
            font-variation-settings: 'wght', 600!important;
            padding-bottom: .4rem;
            color: $white;
            a {
                background-color: $red-darker;
                font-size: 1.2rem;
                color: $white;
                text-decoration: dotted!important;
                &:hover,
                &:focus,
                &:active {
                    background-color: $red-darker;
                    color: $yellow-lightest;
                }
            }
        }
    }
    ul.show {
        -webkit-box-shadow: 5px 5px 15px -1px $black; 
        box-shadow: 5px 5px 3px -1px  $black-opaque-40;
    }
}
