.special {
    h3 {
        font-size: 1.25rem;
        text-transform: uppercase;
        font-weight:  600;
        font-variation-settings: 'wght' 600;
    }
}
.news-section {
    padding-top:20px;
    @media screen and (min-width:772px) and (max-width:769px) and (orientation: portrait) {
        margin-top: -200px!important;
        margin-left: 1em!important;
        margin-right: 1em!important;
    }
    @media screen and (min-width:770px) and (max-width:800px) and (orientation: portrait) {
        margin-top: -160px!important;
        margin-left: 1em!important;
        margin-right: 1em!important;
    }
    @include media-breakpoint-down(sm) {
        margin-top: -280px;
        margin-left: 1.5%;
        margin-right: 1.5%
    }

    @include media-breakpoint-only(md) {
        margin-top: -220px;
        margin-left: 1.5%;
        margin-right: 1.5%
    }

 @include media-breakpoint-up(lg) {
        margin-left: 16%;
        margin-right: 16%;
    }
}

.news-grid {
    max-width: 1400px;
    margin-left:auto!important;
    margin-right:auto!important;
}

.snippet,
.news-listing {
    min-height: 5em;
    margin-bottom: 1.5em;


    img,
    .date-stamp {
        float: left;
        margin-right: .5em;
    }
    p {
        margin-bottom: 5px;
        padding-top: 0;
        margin-top:-4px;
        font-size: .85rem;
        font-family: 'Roboto', sans-serif;
        text-transform: capitalize;
    }
    .title {
        border-bottom: 1px solid $blue-darker;
        font-weight: normal;
        font-size: 1.0rem;
        color: $gray-darkest;
        font-weight: 500;
        font-variation-settings: 'wght' 500;
        margin-top:10px;
        padding-top:0;
        text-decoration: none!important;
    }
    .date-stamp {
        width: 4rem;
        text-align: right;
        padding-top:0;
        .month {
            color: $blue-darker;
            padding: .05rem 0 0 0;
            font-size: 1.2em;
            text-align: right;
            font-weight: 600;
            font-variation-settings: 'wght' 600;
        }
        .day {
            padding: 0 0 0 .5em;
            font-size: 2em;
            line-height: .9;
            font-weight: 200;
            font-variation-settings: 'wght' 200;
            border-top: none;
            color: $blue-darker;
            a {
                &:hover{
                    text-decoration: underline;
                    color:$red-darker;
                    font-weight:bold;
                    font-variation-settings: 'wght' 600;
                }
            }
        }
    }
}
.news {
    a {
        &:hover, 
        &:focus {
            color:$red-darker;
            font-weight:bold;
            font-variation-settings: 'wght' 600;
            text-decoration: underline;  
        }
    }
    .snippet-text{
        padding-left:0;
        margin-left: -16px;
        padding-top:.2rem;
    }
}
 