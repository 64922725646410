
$blue:  #3d5193;
$color2: #c7ddf2;
$color3: #fce292;
$color4: #f5cf71;;
$primary:#3D5193;
$secondary: #1f6680;
$success: #159868;
$warning: #fae385;
$alert: #bb322d;
$info: #d9edff;
$red: $alert;
$red-darkest: #7f0b19;
$red-darker:#9c1212;
$red-darker2:#8f1616;
$red-lighter: #faafa7;
$red-lightest: #fcd3cd;
$blue-darker: #26366a;
$blue-darkest: #0a1535;
$blue2: #a5beda;
$blue-menu-highlight: #A0B5CC;
$blue-lighter: #cfdded;
$blue-lightest: #e8f3ff;
$blue-lighter-95: rgba(198, 220, 244, 0.9);

$green: $success;
/*$green-darkest: #255a00;
$green-darker: #027A48;
$green-lighter: #5edd86;
$green-lightest:#d2f8d7; */
$aqua: #38afae;
$teal: #008C8A;
//$mint: #8FD6D3;
$aqua-darkest: #1f6689;
$aqua-lightest: #c5eee7;
$yellow: #fae385;
$yellow-darker: #B78E3E;
$yellow-darkest: #906614;
$yellow-lightest: #f8efd4;
$yellow-lighter: #FFEAA8;
//$orange: #da6922;
$red-orange: #EA4E47;
/*$periwinkle: #4566ac;
$perwnkle-lightest: #a9a9ff;
$purple: #751975;*/
$tan: #e9ded5;
$gray: #898987;
$gray-darkest: #505050;
$gray-darker: #606060;
$gray-lighter: #bbbbbb;
$gray-lightest:  #E7E7E8;
$gray-extra-light: #f4f4f4;
$black2: #202020; 
$tan2: #a4948f;
$tan-darker: #a4948f;
$gray-opaque-50: rgba(255, 255, 255, 0.5);
$white-opaque-80: rgba(255,255,255,0.8);
$black-opaque-40: rgba(0,0,0,0.4);
$black-opaque-30: rgba(0,0,0,0.3);
$black-opaque-20: rgba(0,0,0,0.2);

$light-gray: $gray-lightest;
$medium-gray: $gray-darker;
$dark-gray: #4d4d4d;
$darkest-gray: $gray-darkest;
$black: #111111;
$white: #fcfcfc;

/*$ve-page-heading-blue: $blue2;
$ve-med-blue: $blue;
$ve-dark-blue: $blue-darker;
$ve-date-blue: $blue-darkest;*/
$ve-check-green: #03644c;
$ve-x-red: $red-darker;
$ve-dusty-red: #8d4241;
$ve-med-red: #8d2021;
/*$ve-dark-red: $red-darkest;
$ve-white: $white;
$ve-white-grey: $gray-lightest;
$ve-light-grey: $gray-lighter;
$ve-med-grey: $gray;
$ve-dark-grey: $gray-darker;
$ve-black: $black;*/


.color-blue {
    color: $blue!important;
}
.color-blue2 {
    color: $blue2!important;
}
.color-blue-darkest {
    color: $blue-darkest!important;
}
.color-blue-darker {
    color: $blue-darker!important;
}
.color-blue-lighter {
    color: $blue-lighter!important;
}
.color-blue-lightest {
    color: $blue-lightest!important;
}
.color-teal {
    color: $teal!important;
}
/*.color-mint {
    color: $mint!important;
}*/
.color-yellow {
    color: $yellow!important;
}
.color-yellow-darkest {
    color: $yellow-darkest!important;
}
.color-yellow-lighter {
    color: $yellow-lighter!important;
}
.color-yellow-lightest {
    color: $yellow-lightest!important;
}
.color-red {
    color: $red!important;
}
.color-red-darkest {
    color: $red-darkest!important;
}
.color-red-darker {
    color: $red-darker!important;
}
.color-red-lighter {
    color: $red-lighter!important;
}
.color-red-lightest {
    color: $red-lightest!important;
}
.color-red-orange {
    color: $red-orange!important;
}
.color-gray {
    color: $gray!important;
}
.color-gray-darkest {
    color: $gray-darkest!important;
}
.color-gray-darker {
    color: $gray-darker!important;
}
.color-gray-lighter {
    color: $gray-lighter!important;
}
.color-gray-lightst {
    color: $gray-lightest!important;
}
.color-black {
    color: $black!important;
}
.color-white {
    color: $white!important;
}

.background-color-blue {
    background: $blue;
}
.background-color-blue2 {
    background: $blue2;
}
.background-color-blue-darkest {
    background: $blue-darkest;
}
.background-color-blue-darker {
    background: $blue-darker;
}
.background-color-blue-lighter {
    background: $blue-lighter;
}
.background-color-blue-lightest {
    background: $blue-lightest;
}
/*.background-color-teal {
    background: $teal;
}
.background-color-mint {
    background: $mint;
}*/
.background-color-yellow {
    background: $yellow;
}
.background-color-yellow-darkest {
    background: $yellow-darkest;
}
.background-color-yellow-lighter {
    background: $yellow-lighter;
}
.background-color-yellow-lightest {
    background: $yellow-lightest;
}
.background-color-red {
    background: $red;
}
.background-color-red-darkest {
    background: $red-darkest;
}
.background-color-red-darker {
    background: $red-darker;
}
.background-color-red-lighter {
    background: $red-lighter;
}
.background-color-red-lightest {
    background: $red-lightest;
}
/*.background-color-red-orange {
    background: $red-orange;
} */
.background-color-gray {
    background: $gray;
}
.background-color-gray-darkest {
    background: $gray-darkest;
}
.background-color-gray-darker {
    background: $gray-darker;
}
.background-color-gray-lighter {
    background: $gray-lighter;
}
.background-color-gray-lightest {
    background: $gray-lightest;
}
.background-color-black {
    background: $black;
}
.background-color-white {
    background: $white;
}