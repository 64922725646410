@charset "UTF-8";
/*!
 * Bootstrap  v5.3.3 (https://getbootstrap.com/)
 * Copyright 2011-2024 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root,
[data-bs-theme=light] {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-primary-text-emphasis: #052c65;
  --bs-secondary-text-emphasis: #2b2f32;
  --bs-success-text-emphasis: #0a3622;
  --bs-info-text-emphasis: #055160;
  --bs-warning-text-emphasis: #664d03;
  --bs-danger-text-emphasis: #58151c;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #cfe2ff;
  --bs-secondary-bg-subtle: #e2e3e5;
  --bs-success-bg-subtle: #d1e7dd;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f8d7da;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #9ec5fe;
  --bs-secondary-border-subtle: #c4c8cb;
  --bs-success-border-subtle: #a3cfbb;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(33, 37, 41, 0.75);
  --bs-secondary-color-rgb: 33, 37, 41;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(33, 37, 41, 0.5);
  --bs-tertiary-color-rgb: 33, 37, 41;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: inherit;
  --bs-link-color: #0d6efd;
  --bs-link-color-rgb: 13, 110, 253;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #0a58ca;
  --bs-link-hover-color-rgb: 10, 88, 202;
  --bs-code-color: #d63384;
  --bs-highlight-color: #212529;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(13, 110, 253, 0.25);
  --bs-form-valid-color: #198754;
  --bs-form-valid-border-color: #198754;
  --bs-form-invalid-color: #dc3545;
  --bs-form-invalid-border-color: #dc3545;
}

[data-bs-theme=dark] {
  color-scheme: dark;
  --bs-body-color: #dee2e6;
  --bs-body-color-rgb: 222, 226, 230;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(222, 226, 230, 0.75);
  --bs-secondary-color-rgb: 222, 226, 230;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: rgba(222, 226, 230, 0.5);
  --bs-tertiary-color-rgb: 222, 226, 230;
  --bs-tertiary-bg: #2b3035;
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-primary-text-emphasis: #6ea8fe;
  --bs-secondary-text-emphasis: #a7acb1;
  --bs-success-text-emphasis: #75b798;
  --bs-info-text-emphasis: #6edff6;
  --bs-warning-text-emphasis: #ffda6a;
  --bs-danger-text-emphasis: #ea868f;
  --bs-light-text-emphasis: #f8f9fa;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-primary-bg-subtle: #031633;
  --bs-secondary-bg-subtle: #161719;
  --bs-success-bg-subtle: #051b11;
  --bs-info-bg-subtle: #032830;
  --bs-warning-bg-subtle: #332701;
  --bs-danger-bg-subtle: #2c0b0e;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: #084298;
  --bs-secondary-border-subtle: #41464b;
  --bs-success-border-subtle: #0f5132;
  --bs-info-border-subtle: #087990;
  --bs-warning-border-subtle: #997404;
  --bs-danger-border-subtle: #842029;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: inherit;
  --bs-link-color: #6ea8fe;
  --bs-link-hover-color: #8bb9fe;
  --bs-link-color-rgb: 110, 168, 254;
  --bs-link-hover-color-rgb: 139, 185, 254;
  --bs-code-color: #e685b5;
  --bs-highlight-color: #dee2e6;
  --bs-highlight-bg: #664d03;
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #ea868f;
  --bs-form-invalid-border-color: #ea868f;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--bs-heading-color);
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  color: var(--bs-highlight-color);
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}
a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-secondary-color);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: var(--bs-emphasis-color);
  --bs-table-bg: var(--bs-body-bg);
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-emphasis-color);
  --bs-table-striped-bg: rgba(var(--bs-emphasis-color-rgb), 0.05);
  --bs-table-active-color: var(--bs-emphasis-color);
  --bs-table-active-bg: rgba(var(--bs-emphasis-color-rgb), 0.1);
  --bs-table-hover-color: var(--bs-emphasis-color);
  --bs-table-hover-bg: rgba(var(--bs-emphasis-color-rgb), 0.075);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
  background-color: var(--bs-table-bg);
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--bs-border-width) * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: var(--bs-border-width) 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 var(--bs-border-width);
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-active {
  --bs-table-color-state: var(--bs-table-active-color);
  --bs-table-bg-state: var(--bs-table-active-bg);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #cfe2ff;
  --bs-table-border-color: #a6b5cc;
  --bs-table-striped-bg: #c5d7f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bacbe6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfd1ec;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e2e3e5;
  --bs-table-border-color: #b5b6b7;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d1e7dd;
  --bs-table-border-color: #a7b9b1;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #a6c3ca;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #ccc2a4;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #c6acae;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #c6c7c8;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #4d5154;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + var(--bs-border-width));
  padding-bottom: calc(0.375rem + var(--bs-border-width));
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + var(--bs-border-width));
  padding-bottom: calc(0.5rem + var(--bs-border-width));
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + var(--bs-border-width));
  padding-bottom: calc(0.25rem + var(--bs-border-width));
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.5em;
  margin: 0;
}
.form-control::-webkit-datetime-edit {
  display: block;
  padding: 0;
}
.form-control::placeholder {
  color: var(--bs-secondary-color);
  opacity: 1;
}
.form-control:disabled {
  background-color: var(--bs-secondary-bg);
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: var(--bs-body-color);
  background-color: var(--bs-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--bs-border-width);
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--bs-secondary-bg);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: var(--bs-border-width) 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}
.form-control-color::-webkit-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}
.form-control-color.form-control-sm {
  height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
}
.form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: var(--bs-secondary-bg);
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--bs-body-color);
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}

[data-bs-theme=dark] .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23dee2e6' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  --bs-form-check-bg: var(--bs-body-bg);
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  appearance: none;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--bs-border-width) solid var(--bs-border-color);
  print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.form-check-input:checked[type=checkbox] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #0d6efd;
  border-color: #0d6efd;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  width: 2em;
  margin-left: -2.5em;
  background-image: var(--bs-form-switch-bg);
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

[data-bs-theme=dark] .form-switch .form-check-input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  appearance: none;
  background-color: transparent;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  appearance: none;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--bs-secondary-color);
}
.form-range:disabled::-moz-range-thumb {
  background-color: var(--bs-secondary-color);
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  min-height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--bs-border-width) solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-select ~ label::after {
  position: absolute;
  inset: 1rem 0.375rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: var(--bs-body-bg);
  border-radius: var(--bs-border-radius);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: var(--bs-border-width) 0;
}
.form-floating > :disabled ~ label,
.form-floating > .form-control:disabled ~ label {
  color: #6c757d;
}
.form-floating > :disabled ~ label::after,
.form-floating > .form-control:disabled ~ label::after {
  background-color: var(--bs-secondary-bg);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-tertiary-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--bs-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-valid-color);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--bs-success);
  border-radius: var(--bs-border-radius);
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--bs-form-valid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: var(--bs-form-valid-color);
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: var(--bs-form-valid-color);
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-invalid-color);
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: var(--bs-border-radius);
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: var(--bs-form-invalid-color);
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: var(--bs-form-invalid-color);
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: var(--bs-border-radius);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}
.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked:focus-visible + .btn {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0b5ed7;
  --bs-btn-hover-border-color: #0a58ca;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0d6efd;
  --bs-btn-disabled-border-color: #0d6efd;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754;
}

.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0;
}

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

.btn-outline-primary {
  --bs-btn-color: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0d6efd;
  --bs-btn-hover-border-color: #0d6efd;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0d6efd;
  --bs-btn-active-border-color: #0d6efd;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0d6efd;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0d6efd;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #198754;
  --bs-btn-hover-border-color: #198754;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #198754;
  --bs-btn-active-border-color: #198754;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #198754;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc3545;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  text-decoration: underline;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: var(--bs-border-radius-lg);
}

.btn-sm {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: var(--bs-border-radius-sm);
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-bg: var(--bs-body-bg);
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: var(--bs-border-radius);
  --bs-dropdown-border-width: var(--bs-border-width);
  --bs-dropdown-inner-border-radius: calc(var(--bs-border-radius) - var(--bs-border-width));
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: var(--bs-box-shadow);
  --bs-dropdown-link-color: var(--bs-body-color);
  --bs-dropdown-link-hover-color: var(--bs-body-color);
  --bs-dropdown-link-hover-bg: var(--bs-tertiary-bg);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}
.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.nav-link.disabled, .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: var(--bs-border-width);
  --bs-nav-tabs-border-color: var(--bs-border-color);
  --bs-nav-tabs-border-radius: var(--bs-border-radius);
  --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg) var(--bs-secondary-bg) var(--bs-border-color);
  --bs-nav-tabs-link-active-color: var(--bs-emphasis-color);
  --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
  --bs-nav-tabs-link-active-border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-body-bg);
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: var(--bs-border-radius);
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #0d6efd;
}
.nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-underline {
  --bs-nav-underline-gap: 1rem;
  --bs-nav-underline-border-width: 0.125rem;
  --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
  gap: var(--bs-nav-underline-gap);
}
.nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--bs-nav-underline-border-width) solid transparent;
}
.nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
  border-bottom-color: currentcolor;
}
.nav-underline .nav-link.active,
.nav-underline .show > .nav-link {
  font-weight: 700;
  color: var(--bs-nav-underline-link-active-color);
  border-bottom-color: currentcolor;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), 0.65);
  --bs-navbar-hover-color: rgba(var(--bs-emphasis-color-rgb), 0.8);
  --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), 0.3);
  --bs-navbar-active-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-padding-y: 0.3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-hover-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2833, 37, 41, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), 0.15);
  --bs-navbar-toggler-border-radius: var(--bs-border-radius);
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark,
.navbar[data-bs-theme=dark] {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

[data-bs-theme=dark] .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--bs-card-subtitle-color);
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius);
  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='%23212529' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='%23052c65' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--bs-primary-text-emphasis);
  --bs-accordion-active-bg: var(--bs-primary-bg-subtle);
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:first-of-type > .accordion-header .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-item:last-of-type > .accordion-header .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type > .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush > .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush > .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush > .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush > .accordion-item > .accordion-header .accordion-button, .accordion-flush > .accordion-item > .accordion-header .accordion-button.collapsed {
  border-radius: 0;
}
.accordion-flush > .accordion-item > .accordion-collapse {
  border-radius: 0;
}

[data-bs-theme=dark] .accordion-button::after {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: var(--bs-secondary-color);
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.btn-close {
  --bs-btn-close-color: #000;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--bs-btn-close-color);
  background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: var(--bs-btn-close-opacity);
}
.btn-close:hover {
  color: var(--bs-btn-close-color);
  text-decoration: none;
  opacity: var(--bs-btn-close-hover-opacity);
}
.btn-close:focus {
  outline: 0;
  box-shadow: var(--bs-btn-close-focus-shadow);
  opacity: var(--bs-btn-close-focus-opacity);
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: var(--bs-btn-close-disabled-opacity);
}

.btn-close-white {
  filter: var(--bs-btn-close-white-filter);
}

[data-bs-theme=dark] .btn-close {
  filter: var(--bs-btn-close-white-filter);
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: var(--bs-body-bg);
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: var(--bs-border-width);
  --bs-modal-border-radius: var(--bs-border-radius-lg);
  --bs-modal-box-shadow: var(--bs-box-shadow-sm);
  --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg) - (var(--bs-border-width)));
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: var(--bs-border-width);
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: var(--bs-border-width);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: var(--bs-box-shadow);
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: var(--bs-body-color);
  --bs-offcanvas-bg: var(--bs-body-bg);
  --bs-offcanvas-border-width: var(--bs-border-width);
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: var(--bs-box-shadow-sm);
  --bs-offcanvas-transition: transform 0.3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.5;
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: var(--bs-offcanvas-transition);
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5) calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-offcanvas-padding-y)) calc(-0.5 * var(--bs-offcanvas-padding-x)) calc(-0.5 * var(--bs-offcanvas-padding-y)) auto;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--bs-offcanvas-title-line-height);
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(var(--bs-primary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(var(--bs-secondary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(var(--bs-success-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(var(--bs-info-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(var(--bs-warning-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(var(--bs-danger-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(var(--bs-light-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(var(--bs-dark-rgb), var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-primary:hover, .link-primary:focus {
  color: RGBA(10, 88, 202, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(10, 88, 202, var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary {
  color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: RGBA(86, 94, 100, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(86, 94, 100, var(--bs-link-underline-opacity, 1)) !important;
}

.link-success {
  color: RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-success:hover, .link-success:focus {
  color: RGBA(20, 108, 67, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(20, 108, 67, var(--bs-link-underline-opacity, 1)) !important;
}

.link-info {
  color: RGBA(var(--bs-info-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-info:hover, .link-info:focus {
  color: RGBA(61, 213, 243, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(61, 213, 243, var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning {
  color: RGBA(var(--bs-warning-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-warning:hover, .link-warning:focus {
  color: RGBA(255, 205, 57, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 205, 57, var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger {
  color: RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-danger:hover, .link-danger:focus {
  color: RGBA(176, 42, 55, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(176, 42, 55, var(--bs-link-underline-opacity, 1)) !important;
}

.link-light {
  color: RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-light:hover, .link-light:focus {
  color: RGBA(249, 250, 251, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(249, 250, 251, var(--bs-link-underline-opacity, 1)) !important;
}

.link-dark {
  color: RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-dark:hover, .link-dark:focus {
  color: RGBA(26, 30, 33, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(26, 30, 33, var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-emphasis {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-body-emphasis:hover, .link-body-emphasis:focus {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 0.75)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 0.75)) !important;
}

.focus-ring:focus {
  outline: 0;
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color);
}

.icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 0.5));
  text-underline-offset: 0.25em;
  backface-visibility: hidden;
}
.icon-link > .bi {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  transition: 0.2s ease-in-out transform;
}
@media (prefers-reduced-motion: reduce) {
  .icon-link > .bi {
    transition: none;
  }
}

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: var(--bs-icon-link-transform, translate3d(0.25em, 0, 0));
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.visually-hidden:not(caption),
.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: var(--bs-border-width);
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-fill {
  object-fit: fill !important;
}

.object-fit-scale {
  object-fit: scale-down !important;
}

.object-fit-none {
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: var(--bs-box-shadow) !important;
}

.shadow-sm {
  box-shadow: var(--bs-box-shadow-sm) !important;
}

.shadow-lg {
  box-shadow: var(--bs-box-shadow-lg) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: 0.25rem !important;
}

.row-gap-2 {
  row-gap: 0.5rem !important;
}

.row-gap-3 {
  row-gap: 1rem !important;
}

.row-gap-4 {
  row-gap: 1.5rem !important;
}

.row-gap-5 {
  row-gap: 3rem !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-1 {
  column-gap: 0.25rem !important;
}

.column-gap-2 {
  column-gap: 0.5rem !important;
}

.column-gap-3 {
  column-gap: 1rem !important;
}

.column-gap-4 {
  column-gap: 1.5rem !important;
}

.column-gap-5 {
  column-gap: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important;
}

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important;
}

.link-opacity-10 {
  --bs-link-opacity: 0.1;
}

.link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1;
}

.link-opacity-25 {
  --bs-link-opacity: 0.25;
}

.link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25;
}

.link-opacity-50 {
  --bs-link-opacity: 0.5;
}

.link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5;
}

.link-opacity-75 {
  --bs-link-opacity: 0.75;
}

.link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75;
}

.link-opacity-100 {
  --bs-link-opacity: 1;
}

.link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}

.link-offset-1 {
  text-underline-offset: 0.125em !important;
}

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important;
}

.link-offset-2 {
  text-underline-offset: 0.25em !important;
}

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important;
}

.link-offset-3 {
  text-underline-offset: 0.375em !important;
}

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important;
}

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-success {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-info {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-warning {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-underline-opacity-0 {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-100 {
  --bs-link-underline-opacity: 1;
}

.link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .object-fit-sm-contain {
    object-fit: contain !important;
  }
  .object-fit-sm-cover {
    object-fit: cover !important;
  }
  .object-fit-sm-fill {
    object-fit: fill !important;
  }
  .object-fit-sm-scale {
    object-fit: scale-down !important;
  }
  .object-fit-sm-none {
    object-fit: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-inline-grid {
    display: inline-grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .row-gap-sm-0 {
    row-gap: 0 !important;
  }
  .row-gap-sm-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-sm-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-sm-3 {
    row-gap: 1rem !important;
  }
  .row-gap-sm-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-sm-5 {
    row-gap: 3rem !important;
  }
  .column-gap-sm-0 {
    column-gap: 0 !important;
  }
  .column-gap-sm-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-sm-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-sm-3 {
    column-gap: 1rem !important;
  }
  .column-gap-sm-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-sm-5 {
    column-gap: 3rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .object-fit-md-contain {
    object-fit: contain !important;
  }
  .object-fit-md-cover {
    object-fit: cover !important;
  }
  .object-fit-md-fill {
    object-fit: fill !important;
  }
  .object-fit-md-scale {
    object-fit: scale-down !important;
  }
  .object-fit-md-none {
    object-fit: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-inline-grid {
    display: inline-grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .row-gap-md-0 {
    row-gap: 0 !important;
  }
  .row-gap-md-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-md-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-md-3 {
    row-gap: 1rem !important;
  }
  .row-gap-md-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-md-5 {
    row-gap: 3rem !important;
  }
  .column-gap-md-0 {
    column-gap: 0 !important;
  }
  .column-gap-md-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-md-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-md-3 {
    column-gap: 1rem !important;
  }
  .column-gap-md-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-md-5 {
    column-gap: 3rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .object-fit-lg-contain {
    object-fit: contain !important;
  }
  .object-fit-lg-cover {
    object-fit: cover !important;
  }
  .object-fit-lg-fill {
    object-fit: fill !important;
  }
  .object-fit-lg-scale {
    object-fit: scale-down !important;
  }
  .object-fit-lg-none {
    object-fit: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-inline-grid {
    display: inline-grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .row-gap-lg-0 {
    row-gap: 0 !important;
  }
  .row-gap-lg-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-lg-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-lg-3 {
    row-gap: 1rem !important;
  }
  .row-gap-lg-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-lg-5 {
    row-gap: 3rem !important;
  }
  .column-gap-lg-0 {
    column-gap: 0 !important;
  }
  .column-gap-lg-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-lg-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-lg-3 {
    column-gap: 1rem !important;
  }
  .column-gap-lg-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-lg-5 {
    column-gap: 3rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .object-fit-xl-contain {
    object-fit: contain !important;
  }
  .object-fit-xl-cover {
    object-fit: cover !important;
  }
  .object-fit-xl-fill {
    object-fit: fill !important;
  }
  .object-fit-xl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xl-none {
    object-fit: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-inline-grid {
    display: inline-grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .row-gap-xl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xl-5 {
    row-gap: 3rem !important;
  }
  .column-gap-xl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xl-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xl-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xl-5 {
    column-gap: 3rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .object-fit-xxl-contain {
    object-fit: contain !important;
  }
  .object-fit-xxl-cover {
    object-fit: cover !important;
  }
  .object-fit-xxl-fill {
    object-fit: fill !important;
  }
  .object-fit-xxl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xxl-none {
    object-fit: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-inline-grid {
    display: inline-grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xxl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xxl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xxl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xxl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xxl-5 {
    row-gap: 3rem !important;
  }
  .column-gap-xxl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xxl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xxl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xxl-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xxl-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xxl-5 {
    column-gap: 3rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }
  .fs-2 {
    font-size: 2rem !important;
  }
  .fs-3 {
    font-size: 1.75rem !important;
  }
  .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-inline-grid {
    display: inline-grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
.icon-list {
  padding-left: 0;
  list-style: none;
}

.icon-list li {
  display: flex;
  align-items: flex-start;
  margin-bottom: 0.25rem;
}

.icon-list li::before {
  display: block;
  flex-shrink: 0;
  width: 1.5em;
  height: 1.5em;
  margin-right: 0.5rem;
  content: "";
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23212529' viewBox='0 0 16 16'%3E%3Cpath d='M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z'/%3E%3C/svg%3E") no-repeat center center/100% auto;
}

/*$green-darkest: #255a00;
$green-darker: #027A48;
$green-lighter: #5edd86;
$green-lightest:#d2f8d7; */
/*$periwinkle: #4566ac;
$perwnkle-lightest: #a9a9ff;
$purple: #751975;*/
/*$ve-page-heading-blue: $blue2;
$ve-med-blue: $blue;
$ve-dark-blue: $blue-darker;
$ve-date-blue: $blue-darkest;*/
/*$ve-dark-red: $red-darkest;
$ve-white: $white;
$ve-white-grey: $gray-lightest;
$ve-light-grey: $gray-lighter;
$ve-med-grey: $gray;
$ve-dark-grey: $gray-darker;
$ve-black: $black;*/
.color-blue {
  color: #3d5193 !important;
}

.color-blue2 {
  color: #a5beda !important;
}

.color-blue-darkest {
  color: #0a1535 !important;
}

.color-blue-darker {
  color: #26366a !important;
}

.color-blue-lighter {
  color: #cfdded !important;
}

.color-blue-lightest {
  color: #e8f3ff !important;
}

.color-teal {
  color: #008C8A !important;
}

/*.color-mint {
    color: $mint!important;
}*/
.color-yellow {
  color: #fae385 !important;
}

.color-yellow-darkest {
  color: #906614 !important;
}

.color-yellow-lighter {
  color: #FFEAA8 !important;
}

.color-yellow-lightest {
  color: #f8efd4 !important;
}

.color-red {
  color: #bb322d !important;
}

.color-red-darkest {
  color: #7f0b19 !important;
}

.color-red-darker {
  color: #9c1212 !important;
}

.color-red-lighter {
  color: #faafa7 !important;
}

.color-red-lightest {
  color: #fcd3cd !important;
}

.color-red-orange {
  color: #EA4E47 !important;
}

.color-gray {
  color: #898987 !important;
}

.color-gray-darkest {
  color: #505050 !important;
}

.color-gray-darker {
  color: #606060 !important;
}

.color-gray-lighter {
  color: #bbbbbb !important;
}

.color-gray-lightst {
  color: #E7E7E8 !important;
}

.color-black {
  color: #111111 !important;
}

.color-white {
  color: #fcfcfc !important;
}

.background-color-blue {
  background: #3d5193;
}

.background-color-blue2 {
  background: #a5beda;
}

.background-color-blue-darkest {
  background: #0a1535;
}

.background-color-blue-darker {
  background: #26366a;
}

.background-color-blue-lighter {
  background: #cfdded;
}

.background-color-blue-lightest {
  background: #e8f3ff;
}

/*.background-color-teal {
    background: $teal;
}
.background-color-mint {
    background: $mint;
}*/
.background-color-yellow {
  background: #fae385;
}

.background-color-yellow-darkest {
  background: #906614;
}

.background-color-yellow-lighter {
  background: #FFEAA8;
}

.background-color-yellow-lightest {
  background: #f8efd4;
}

.background-color-red {
  background: #bb322d;
}

.background-color-red-darkest {
  background: #7f0b19;
}

.background-color-red-darker {
  background: #9c1212;
}

.background-color-red-lighter {
  background: #faafa7;
}

.background-color-red-lightest {
  background: #fcd3cd;
}

/*.background-color-red-orange {
    background: $red-orange;
} */
.background-color-gray {
  background: #898987;
}

.background-color-gray-darkest {
  background: #505050;
}

.background-color-gray-darker {
  background: #606060;
}

.background-color-gray-lighter {
  background: #bbbbbb;
}

.background-color-gray-lightest {
  background: #E7E7E8;
}

.background-color-black {
  background: #111111;
}

.background-color-white {
  background: #fcfcfc;
}

.breadcrumbs-nav,
.breadcrumbs {
  display: block;
  padding: 0;
  overflow: hidden;
  margin-left: -2.3rem;
  border: 0;
}
@media (max-width: 575.98px) {
  .breadcrumbs-nav,
  .breadcrumbs {
    padding: 0 0 0.25rem;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .breadcrumbs-nav,
  .breadcrumbs {
    margin-top: -0.3rem;
    margin-left: -1rem;
    margin-bottom: 0;
  }
}
@media (min-width: 992px) {
  .breadcrumbs-nav,
  .breadcrumbs {
    display: block;
    margin-left: -2.3rem;
    overflow: hidden;
    padding: 0.2rem 0;
    margin-bottom: 0.8rem;
  }
}
.breadcrumbs-nav ul,
.breadcrumbs ul {
  list-style: none !important;
  list-style-type: none !important;
}
@media (min-width: 992px) {
  .breadcrumbs-nav ul,
  .breadcrumbs ul {
    margin-left: 0;
    margin-top: 1rem;
  }
}
.breadcrumbs-nav li,
.breadcrumbs-nav span,
.breadcrumbs li,
.breadcrumbs span {
  margin: 0;
  float: left;
  font-size: 0.6875rem;
  line-height: 0.6875rem;
  color: #606060;
  padding-right: 0.4rem;
}
@media (min-width: 992px) {
  .breadcrumbs-nav li,
  .breadcrumbs-nav span,
  .breadcrumbs li,
  .breadcrumbs span {
    margin: 0;
    float: left;
    font-size: 0.6875rem;
    line-height: 0.6875rem;
    color: #505050;
  }
}
.breadcrumbs-nav a,
.breadcrumbs a {
  color: #606060;
}
.breadcrumbs-nav a:hover,
.breadcrumbs a:hover {
  color: #111111;
  border-bottom: 1px dotted #505050;
}
@media (min-width: 992px) {
  .breadcrumbs-nav a:hover, .breadcrumbs-nav a:active, .breadcrumbs-nav a:focus,
  .breadcrumbs a:hover,
  .breadcrumbs a:active,
  .breadcrumbs a:focus {
    color: #505050;
    border-bottom: 1px dotted #505050;
  }
}
.breadcrumbs-nav .current,
.breadcrumbs .current {
  cursor: default;
  color: #505050;
}
.breadcrumbs-nav .current a,
.breadcrumbs .current a {
  cursor: default;
  color: #505050;
}
.breadcrumbs-nav a,
.breadcrumbs a {
  color: #111111;
}
.breadcrumbs-nav a:first-child:before,
.breadcrumbs a:first-child:before {
  content: "";
  margin: 0;
}
.breadcrumbs-nav .current,
.breadcrumbs-nav .current:hover,
.breadcrumbs-nav .current:hover a,
.breadcrumbs-nav .current:focus,
.breadcrumbs-nav .current:focus a,
.breadcrumbs .current,
.breadcrumbs .current:hover,
.breadcrumbs .current:hover a,
.breadcrumbs .current:focus,
.breadcrumbs .current:focus a {
  text-decoration: none;
}

@media (max-width: 767.98px) {
  .wide .breadcrumbs,
  .elect-wide .breadcrumbs {
    margin-top: 1.2rem;
  }
}
@media (min-width: 992px) {
  .wide .breadcrumbs,
  .elect-wide .breadcrumbs {
    margin-top: 1.2rem;
    margin-bottom: 1.2rem;
    margin-left: 0;
    padding-left: 0;
  }
  .wide .breadcrumbs ul,
  .elect-wide .breadcrumbs ul {
    margin-left: 0;
    padding-left: 0;
  }
}

button,
.button {
  font-family: "Open Sans", sans-serif;
  border-radius: 0.5rem;
  padding: 12px 52px;
  cursor: pointer;
  font-weight: 500;
  font-variation-settings: "wght" 500;
  text-align: center;
  line-height: 1;
  font-size: 1.1rem;
  border: 0 !important;
  margin: 0 0 1.25rem;
  color: #fcfcfc;
  transition: background-color 300ms ease-out;
}
button:hover, button:active, button:focus,
.button:hover,
.button:active,
.button:focus {
  filter: saturate(0.8) brightness(110%) !important;
  -webkit-filter: saturate(0.8) brightness(110%) !important;
  -webkit-box-shadow: 0px 0px 10px 3px rgba(26, 26, 26, 0.4);
  -moz-box-shadow: 0px 0px 10px 3px rgba(26, 26, 26, 0.4);
  box-shadow: 0px 0px 10px 3px rgba(26, 26, 26, 0.4);
}

.banner-button {
  -webkit-box-shadow: 5px 5px 15px -1px rgba(0, 0, 0, 0.2);
  box-shadow: 5px 5px 15px -1px rgba(0, 0, 0, 0.2);
  margin-top: 0;
}
@media screen and (min-width: 64.063em) and (max-width: 80em) {
  .banner-button {
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (min-device-width: 768px) and (max-device-width: 1099px) and (orientation: landscape) {
  .banner-button {
    margin-right: auto;
  }
}
@media screen and (min-width: 88.063em) {
  .banner-button {
    margin-top: 10px;
  }
}

.action-button {
  text-align: center;
  -webkit-box-shadow: 5px 5px 15px -1px rgba(0, 0, 0, 0.2);
  box-shadow: 5px 5px 15px -1px rgba(0, 0, 0, 0.2);
  margin-top: 0;
}
/*
.button-yellow, 
.banner-button-yellow,
.action-button-yellow {
    background: $yellow;
    color: $blue-darkest;
    &:hover,
    &:focus {
        background: $yellow-lighter;
    }
    &:active {
        background: $yellow-lightest;
    }
}
.button-green, 
.banner-button-green,
.action-button-green {
    background: $green;
    color: $white;
    &:hover,
    &:focus {
        background: $green-lighter;
    }
    &:active {
        background: $green-lightest;
        color: $green-darkest;
    }
} */
.button-white,
.banner-button-white {
  background: #fcfcfc !important;
  color: #0a1535;
}
.button-white:hover, .button-white:focus,
.banner-button-white:hover,
.banner-button-white:focus {
  background: #E7E7E8;
  color: #26366a;
}
.button-white:active,
.banner-button-white:active {
  background: #E7E7E8;
  color: #a5beda;
}

.button-red,
.banner-button-red,
.action-button-red {
  background: #bb322d !important;
  color: #fcfcfc;
}
.button-red:hover, .button-red:focus,
.banner-button-red:hover,
.banner-button-red:focus,
.action-button-red:hover,
.action-button-red:focus {
  background: #EA4E47 !important;
}
.button-red:active,
.banner-button-red:active,
.action-button-red:active {
  background: #faafa7;
  color: #606060;
}

.button-black,
.banner-button-black,
.action-button-black {
  background: #111111 !important;
  color: #fcfcfc;
}
.button-black:hover, .button-black:focus, .button-black:active,
.banner-button-black:hover,
.banner-button-black:focus,
.banner-button-black:active,
.action-button-black:hover,
.action-button-black:focus,
.action-button-black:active {
  background: #606060 !important;
}
.button-black:active,
.banner-button-black:active,
.action-button-black:active {
  background: #898987 !important;
}

.button-blue,
.banner-button-blue,
.action-button-blue {
  background: #26366a !important;
  color: #fcfcfc;
}
.button-blue:hover, .button-blue:focus,
.banner-button-blue:hover,
.banner-button-blue:focus,
.action-button-blue:hover,
.action-button-blue:focus {
  background: #a5beda !important;
}
.button-blue:active,
.banner-button-blue:active,
.action-button-blue:active {
  background: #cfdded !important;
  color: #606060;
}

/*
.button-teal,
.banner-button-teal,
.action-button-teal {
    background: $teal;
    color: $white;
    &:hover,
    &:focus,
    &:active {
        background: $aqua;
    }
    &:active {
        background: $mint;
        color: $gray-darker;
    }
}  */
.button-icon {
  height: 1.1rem;
  width: auto;
}

.button-icon-left {
  padding-right: 8px;
}

.button-icon-right {
  padding-left: 8px;
}

@media only screen and (min-width: 40.063em) {
  button,
  .button {
    display: inline-block;
  }
}
@media only screen and (max-width: 767px) {
  .side-menu-button {
    transform: rotate(90deg);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    position: sticky;
    left: -30%;
    margin-left: -70px;
    top: 170px;
    height: 40px;
    line-height: 1;
    padding-top: 0.6rem;
    padding-bottom: 0.4rem;
    background: #9c1212;
  }
}
header {
  background: #fcfcfc !important;
}
@media (max-width: 575.98px) {
  header {
    padding-top: 3.2rem;
  }
}
@media (min-width: 992px) {
  header {
    padding-top: 1.6rem;
  }
}

.nav-container {
  background: #fcfcfc;
  align-items: center;
  display: flex;
  flex-wrap: inherit;
  justify-content: space-between;
}
@media (max-width: 575.98px) {
  .nav-container {
    height: 3rem;
  }
}
@media (max-width: 767.98px) {
  .nav-container {
    margin-top: -1rem;
  }
}
@media (min-width: 992px) {
  .nav-container {
    margin-top: -1.8rem;
  }
}

.elect-header-container {
  background: #fcfcfc !important;
  width: 100vw;
  border-bottom: 1px solid #898987;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .navbar {
    margin-top: 0.6rem;
    background: #fcfcfc !important;
  }
}
@media (min-width: 992px) {
  .navbar {
    background: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .navbar-collapse {
    margin-top: -0.25rem;
  }
}
@media (min-width: 992px) {
  .navbar-collapse {
    margin-left: -14vw;
    margin-top: 1rem;
  }
}

a.navbar-brand {
  z-index: 100;
}
@media (max-width: 767.98px) {
  a.navbar-brand {
    margin-top: -2rem;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  a.navbar-brand {
    width: 28vw;
    padding-bottom: 0;
  }
}
@media (min-width: 992px) {
  a.navbar-brand {
    margin-left: 2vw;
    max-width: 30%;
    padding-top: 0;
  }
}

.navbar-logo {
  text-align: right;
  padding-right: 40px;
}
@media (max-width: 575.98px) {
  .navbar-logo {
    max-width: 22vw;
    margin-left: -1.5rem;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .navbar-logo {
    margin-left: -3rem;
    max-width: 70vw;
    padding-right: 100px;
  }
}

#logo-image {
  z-index: 100;
}
@media (max-width: 575.98px) {
  #logo-image {
    aspect-ratio: 18/15;
    margin-left: 5vw;
    margin-top: 0;
    max-height: 3.6rem;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  #logo-image {
    aspect-ratio: 18/15;
    margin-top: -2rem;
    width: 44%;
  }
}
@media (min-width: 992px) {
  #logo-image {
    aspect-ratio: 30/15;
    margin-left: 4vw;
    margin-top: 0.3vw;
    padding-right: 3vw;
    width: 7.5vw;
  }
}

@media (max-width: 767.98px) {
  .show {
    background: #fcfcfc !important;
    margin-left: -3rem !important;
    padding-left: 3rem !important;
  }
}

.hamburger {
  background: #3d5193;
  color: #fcfcfc;
  text-align: center;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  cursor: pointer;
}
@media (max-width: 575.98px) {
  .hamburger {
    margin: 0 0 auto auto;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .hamburger {
    margin: -3rem 0 auto auto;
  }
}
.hamburger i {
  color: #fcfcfc;
  margin: auto auto auto -5px;
  font-size: 20px;
  line-height: 18px;
  width: auto;
  padding: 4px;
  font-weight: 300;
}
.hamburger:active, .hamburger:hover, .hamburger:focus {
  background: #a5beda !important;
}
.hamburger:focus {
  outline: 1;
}
.hamburger:active {
  color: #fcfcfc;
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    background: #fcfcfc;
    margin-left: -15px !important;
    padding-left: 0 !important;
    width: 100vw !important;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
}

@media (max-width: 767.98px) {
  .navbar-collapse.show {
    background: #cfdded !important;
    margin: -0.2rem -6vw auto -6vw !important;
    width: 100vw;
  }
}
@media (min-width: 992px) {
  .navbar-collapse.show {
    margin-top: 0.9rem;
  }
}

#menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-variation-settings: "wght" 600;
}
@media (max-width: 767.98px) {
  #menu {
    margin-left: 12vw;
    margin-top: 0.5rem !important;
  }
}
#menu li.nav-item {
  display: inline-block;
  font-family: "Open Sans", sans-serif;
  font-variation-settings: "wght" var(--menu-wght);
}
@media (min-width: 992px) {
  #menu li.nav-item {
    padding-left: 1vw;
    padding-right: 1vw;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  #menu li.nav-item {
    font-size: 0.85rem;
    padding-left: 1vw;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  #menu li.nav-item {
    font-size: 0.95rem;
    padding-left: 2vw;
  }
}
@media (min-width: 1400px) {
  #menu li.nav-item {
    font-size: 1.1rem;
    padding-left: 2.5vw;
  }
}
@media (max-width: 767.98px) {
  #menu li.nav-item {
    border-bottom: 1px solid #fcfcfc !important;
    margin-left: -7rem !important;
    padding-bottom: 0.2rem !important;
    padding-left: 5rem !important;
    padding-top: 0.2rem !important;
    margin-top: -0.2rem;
  }
}
#menu li.nav-item a {
  display: block;
  color: #26366a;
  letter-spacing: 0.02rem;
  text-decoration: none;
  border-bottom: none;
}
@media (max-width: 767.98px) {
  #menu li.nav-item a {
    background: none;
  }
  #menu li.nav-item a:hover, #menu li.nav-item a:active, #menu li.nav-item a:focus {
    color: #26366a !important;
  }
}
@media (min-width: 992px) {
  #menu li.nav-item a {
    background: none !important;
    background: transparent !important;
  }
  #menu li.nav-item a:hover, #menu li.nav-item a:active, #menu li.nav-item a:focus {
    color: #3d5193;
  }
}
#menu li.nav-item:last-child {
  border-bottom: 0;
}
#menu li.nav-item:hover a, #menu li.nav-item:focus a, #menu li.nav-item:active a {
  color: #3d5193;
  border-bottom: none !important;
}
@media (max-width: 575.98px) {
  #menu li.nav-item .active a {
    color: #3d5193;
  }
}
@media (min-width: 768px) {
  #menu li.nav-item .active a {
    color: #3d5193;
  }
  #menu li.nav-item .active a:hover, #menu li.nav-item .active a:focus, #menu li.nav-item .active a:active {
    background: #fcfcfc;
    color: #26366a;
  }
}
#menu li.nav-item .dropdown-menu {
  overflow: hidden;
  left: 3rem !important;
  padding-left: 0;
  background: #cfdded;
}
@media (max-width: 767.98px) {
  #menu li.nav-item .dropdown-menu {
    margin-bottom: -0.2rem;
  }
}
@media (min-width: 992px) {
  #menu li.nav-item .dropdown-menu {
    border-radius: 0 !important;
    border-top: 6px solid #26366a;
    -webkit-box-shadow: 5px 5px 15px -1px #898987;
    box-shadow: 5px 5px 15px -1px #898987;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  #menu li.nav-item .dropdown-menu {
    width: 24vw;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  #menu li.nav-item .dropdown-menu {
    width: 21vw;
  }
}
@media (min-width: 1400px) {
  #menu li.nav-item .dropdown-menu {
    width: 18vw;
  }
}
@media (max-width: 767.98px) {
  #menu li.nav-item .dropdown-menu.show {
    border-width: 0 !important;
    margin-top: -0.4rem;
  }
}
@media (max-width: 767.98px) {
  #menu li.nav-item .dropdown-menu li.dropdown-item {
    font-size: 0.85rem !important;
    padding: 0.4rem 0;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  #menu li.nav-item .dropdown-menu li.dropdown-item {
    margin-left: 0 !important;
  }
}
@media (min-width: 992px) {
  #menu li.nav-item .dropdown-menu li.dropdown-item {
    padding: 0.4rem 0 0 0.5rem;
    font-size: 0.8rem !important;
  }
}
#menu li.nav-item .dropdown-menu li.dropdown-item:first-of-type {
  margin-top: -0.2rem;
}
@media (max-width: 767.98px) {
  #menu li.nav-item .dropdown-menu li.dropdown-item:last-of-type {
    margin-bottom: 0.4rem;
  }
}
@media (min-width: 992px) {
  #menu li.nav-item .dropdown-menu li.dropdown-item:last-of-type {
    margin-bottom: 0;
  }
}
@media (max-width: 767.98px) {
  #menu li.nav-item .dropdown-menu li.dropdown-item:nth-last-child(-n+2) {
    border-bottom: 0 !important;
  }
}
#menu li.nav-item .dropdown-menu li.dropdown-item:active, #menu li.nav-item .dropdown-menu li.dropdown-item:hover, #menu li.nav-item .dropdown-menu li.dropdown-item:focus {
  background: #e8f3ff !important;
}
#menu li.nav-item .dropdown-menu li.dropdown-item a {
  font-weight: 600;
  font-variation-settings: "wght" 600;
  padding-left: 0;
  cursor: pointer;
}
@media (max-width: 767.98px) {
  #menu li.nav-item .dropdown-menu li.dropdown-item a {
    color: #606060 !important;
    font-size: 0.85rem !important;
    padding-left: 1rem;
  }
}
@media (min-width: 992px) {
  #menu li.nav-item .dropdown-menu li.dropdown-item a {
    color: #606060;
    width: 100%;
    padding: 0.2rem 0.5rem;
  }
}
@media (max-width: 767.98px) {
  #menu li.nav-item .dropdown-menu li.dropdown-item a:active, #menu li.nav-item .dropdown-menu li.dropdown-item a:hover, #menu li.nav-item .dropdown-menu li.dropdown-item a:focus {
    color: #26366a !important;
    background: #e8f3ff !important;
  }
}
@media (min-width: 992px) {
  #menu li.nav-item .dropdown-menu li.dropdown-item a:active, #menu li.nav-item .dropdown-menu li.dropdown-item a:hover, #menu li.nav-item .dropdown-menu li.dropdown-item a:focus {
    color: #3d5193;
    background: #e8f3ff !important;
    transform: ease-out 250ms;
  }
}

@media (min-width: 992px) {
  li .fa-search {
    margin-top: 0;
    margin-left: 2.5rem;
    font-size: 1.7rem;
    color: #26366a;
    padding-top: 0;
  }
}
@media (min-width: 992px) {
  li .fa-times {
    margin-left: 2.7rem;
    margin-top: 0;
    padding-top: 0;
    font-size: 1.7rem;
    color: #26366a;
  }
}
@media (min-width: 992px) {
  li.d-lg-block {
    padding-top: 0.3rem;
  }
}

@media (max-width: 575.98px) {
  .search-button-mobile-div {
    left: 44vw;
    position: relative;
    top: -0.6rem;
    width: 14vw;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .search-button-mobile-div {
    margin-left: 76vw;
    margin-top: -4.3rem;
    width: 12vw;
  }
}
@media (max-width: 575.98px) {
  .search-button-mobile-div .fa-search {
    color: #26366a;
    margin: auto;
    font-size: 40px;
    width: auto;
    text-align: center;
    padding: 4px;
    font-weight: 300;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .search-button-mobile-div .fa-search {
    margin-left: 32px;
    font-size: 2.6rem;
    color: #26366a;
    padding-top: 0;
  }
}
@media (max-width: 575.98px) {
  .search-button-mobile-div .fa-times {
    color: #a5beda;
    font-size: 40px;
    width: auto;
    text-align: center;
    padding: 4px;
    font-weight: 300;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .search-button-mobile-div .fa-times {
    margin-left: 28px;
    padding-left: 4px;
    font-size: 2.6rem;
    color: #a5beda;
  }
}

/*
.elect-title-bar {
    background: $white!important;
    margin-top: -6px;
    text-align: right;
    margin-right: 0;
    padding-left: 20px;
    padding-right:0;
    padding-top: 0;
    @include media-breakpoint-down(sm){
        height: 40px;
    }
    @include media-breakpoint-down(md) {
        background:transparent!important;
        background: none!important;
    }
    @include media-breakpoint-only(md) {
        height:65px;
        padding-left: 25px!important;
    }    
    @include media-breakpoint-up(lg)  {
        background: $white!important;
    }
    button.mobile-menu-icon {
        overflow: hidden;
        text-align:center; 
        background: $blue;
        color: $white;
        text-align: center;
        border-radius: 50%;
        cursor: pointer;
        padding:0;
        font-weight: 300;

        @include media-breakpoint-down(sm)  {
            height: 40px;
            width: 40px;
            margin: 8% 32px  auto auto;
        }
        @include media-breakpoint-only(md) {
            height: 56px;
            width: 56px;
            margin: 13% 40px auto auto;
        }
        i {
            color: $white;
            margin: auto;
            font-size: 24px;
            line-height: 18px;
            width: auto;
            text-align: center;
            padding: 4px;
            font-weight: 300;
        }
        &:focus {
            outline:0;
        }
        &:active {
            color: $white;
        }
    }       
} 
.menu-button-active {
    background: $gray!important;
}
.menu-button-standard {
    background: $blue!important;
} */
.search-button,
.searchbarHome {
  height: 40px;
}
@media (max-width: 767.98px) {
  .search-button,
  .searchbarHome {
    margin-top: 20px;
    margin-bottom: 16px;
  }
}
@media (min-width: 992px) {
  .search-button,
  .searchbarHome {
    margin-top: 10px;
  }
}
.search-button .navbar-search,
.searchbarHome .navbar-search {
  border: 1px solid #bbbbbb;
  color: #606060;
  font-family: "Open Sans", sans-serif;
  padding-left: 10px;
}
@media (max-width: 767.98px) {
  .search-button .navbar-search,
  .searchbarHome .navbar-search {
    font-size: 16px;
    width: 64vw;
    margin-left: 10vw;
    border-radius: 14px;
  }
}
@media (min-width: 992px) {
  .search-button .navbar-search,
  .searchbarHome .navbar-search {
    border-radius: 20px;
    background: #fcfcfc !important;
    height: 28px;
    border-radius: 14px;
    width: 150px;
    left: -20px;
  }
}
.search-button .navbar-search:focus a, .search-button .navbar-search:active a,
.searchbarHome .navbar-search:focus a,
.searchbarHome .navbar-search:active a {
  background: #fcfcfc !important;
}

@media (max-width: 767.98px) {
  .dropdown-toggle:after {
    display: none !important;
  }
}

*:focus:not(.focus-visible) {
  outline: none;
}

/* Optional: Customize .focus-visible */
.focus-visible {
  outline: lightgreen solid 2px;
}

#skip-to-main {
  width: 100%;
  margin: 0;
  line-height: 1.5;
  background: rgba(0, 0, 0, 0);
}

.offscreen {
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip-path: inset(1px 1px 1px 1px);
  clip-path: inset(1px, 1px, 1px, 1px);
}

.skip-to-content.offscreen:focus {
  position: relative;
  display: inline-block;
  height: auto;
  width: 100%;
  padding: 0.2em 1.25em;
  margin-bottom: -20px;
  background: #fff;
  color: #000;
  overflow: visible;
  clip-path: none;
  text-decoration: none;
  z-index: 2000;
}

@media screen and (max-width: 40em) {
  .lang-select {
    padding-top: 0;
    margin-top: -6px;
  }
}
@media screen and (min-width: 40.063em) and (max-width: 64em) {
  .lang-select {
    padding-top: 0;
    margin-top: 0;
  }
}

#google_translate_element {
  position: static;
  width: 187px;
  height: 28px;
  color: #505050;
  border-radius: 0 4px;
  padding-left: 20px;
  z-index: 2100;
  border: 0 !important;
}
@media screen and (max-width: 40em) {
  #google_translate_element {
    margin-right: auto;
    margin-left: 22vw;
    margin-top: -3.2rem;
    z-index: 10000;
  }
}
@media screen and (min-width: 40.063em) and (max-width: 64em) {
  #google_translate_element {
    margin-right: auto;
    margin-left: 38vw;
    margin-top: -10px;
  }
}
@media screen and (min-width: 64.063em) {
  #google_translate_element {
    float: right;
    margin-right: 1vw !important;
    margin-top: -1.6rem;
  }
}

.VIpgJd-ZVi9od-vH1Gmf {
  margin-left: 40vw;
}

@media screen and (min-width: 64.063em) {
  .google_translate_element-fixed {
    margin-top: 0;
  }
}
.google_translate_element-fixed .goog-te-gadget,
.google_translate_element-fixed .goog-te-gadget-simple {
  border: 1px solid #606060 !important;
  background: #fcfcfc;
}
.google_translate_element-fixed .goog-te-gadget-simple {
  width: 170px;
}
.google_translate_element-fixed .goog-te-gadget-simple img {
  margin-right: 4px;
  padding-right: 0;
}
.google_translate_element-fixed .goog-te-menu-value {
  border-bottom: 0 !important;
}

a .goog-te-menu-value,
.goog-te-menu-value span {
  color: #000;
}

.goog-te-gadget-simple {
  padding-left: 10px !important;
  padding-top: 4px !important;
  position: relative;
  z-index: 1000;
}

.goog-te-banner-frame.skiptranslate {
  display: none !important;
}

.search-bar-drop {
  border-bottom: none !important;
}

.search-bar-home {
  text-align: center;
}
@media (max-width: 767.98px) {
  .search-bar-home {
    margin-left: auto !important;
    margin-right: auto !important;
    padding-left: 20px;
  }
}
@media (min-width: 992px) {
  .search-bar-home {
    padding-left: 8vw;
  }
}

.search-dropdown {
  display: none;
  width: 100vw !important;
  text-align: center !important;
  background: #cfdded !important;
  -webkit-box-shadow: 0px 15px 10px -15px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 15px 10px -15px rgba(0, 0, 0, 0.2);
  z-index: 11000;
  /* .search-tags {
      display: flex;
      flex-direction: row;
      padding: 0;
      justify-content: center;
      align-items: flex-start;
      margin-top: -3px;
      @include media-breakpoint-down(md)  {
          width: 100%;
          font-size: 0;
      }
      @include media-breakpoint-up(lg) {
          width: 90%;
          font-size:1.2rem;
      }
     .search-tag {
          @include media-breakpoint-up(lg) {
              display: inline;
              margin: 0.3rem 0.6rem;
              padding: 6px 0 0;
              a {
                  display: block;
                  color: $blue-darker;
                  text-decoration:underline;
                  text-align: center;
                  font-weight:300;
                  margin-top:-4px;
                  padding-top:0;
                  &:hover {
                      color: $blue;
                  } 
              }             
          }
      } 
  } */
}
@media (max-width: 767.98px) {
  .search-dropdown {
    margin-left: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .search-dropdown {
    padding: 1rem 4vw 1.8rem;
  }
}
@media (max-width: 575.98px) {
  .search-dropdown {
    padding: 6px 10px 14px;
  }
}
@media (min-width: 992px) {
  .search-dropdown {
    padding: 48px 40px 28px !important;
    position: relative;
  }
}
.search-dropdown h1, .search-dropdown .h1,
.search-dropdown h2,
.search-dropdown .h2,
.search-dropdown .search-drop-title,
.search-dropdown .search-drop-subtitle {
  text-align: left !important;
  font-family: "Oswald", "HeaderFallBackMac", "HeaderFallBackWin", sans-serif;
}
.search-dropdown h1, .search-dropdown .h1,
.search-dropdown .search-drop-title {
  font-weight: 400;
  font-variation-settings: "wght" 400;
}
@media (max-width: 767.98px) {
  .search-dropdown h1, .search-dropdown .h1,
  .search-dropdown .search-drop-title {
    font-size: 1rem !important;
    padding-top: 0.4rem;
  }
}
@media (min-width: 992px) {
  .search-dropdown h1, .search-dropdown .h1,
  .search-dropdown .search-drop-title {
    font-size: 1.6rem !important;
  }
}
.search-dropdown h2, .search-dropdown .h2,
.search-dropdown .search-drop-subtitle {
  font-weight: 500;
  font-variation-settings: "wght" 500;
  color: #26366a;
}
@media (max-width: 767.98px) {
  .search-dropdown h2, .search-dropdown .h2,
  .search-dropdown .search-drop-subtitle {
    font-size: 2.3rem !important;
    line-height: 1;
    margin-top: -4px;
    margin-bottom: -8px;
  }
}
@media (min-width: 992px) {
  .search-dropdown h2, .search-dropdown .h2,
  .search-dropdown .search-drop-subtitle {
    font-size: 2.6rem !important;
    line-height: 1.1;
    margin-bottom: -3px;
  }
}
.search-dropdown form {
  margin-left: auto;
  margin-right: auto;
  background: transparent;
  z-index: 899;
}
@media (max-width: 575.98px) {
  .search-dropdown form {
    width: 100%;
    padding-left: 0;
    margin-bottom: -1.7rem;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .search-dropdown form {
    padding-left: 10px !important;
    padding-right: 10px !important;
    margin-bottom: -1.7rem;
    padding-bottom: 2rem;
  }
}
@media (min-width: 992px) {
  .search-dropdown form {
    text-align: left;
    padding-bottom: 14px;
  }
}
.search-dropdown form .label-hide {
  display: none;
}
.search-dropdown form input[type=text],
.search-dropdown form .navbar-search {
  border-radius: 6px;
  border: 1px solid #3d5193;
  outline: 1px solid #3d5193;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  background: #e8f3ff;
  font-style: italic;
  padding: 8px;
  margin-top: 24px;
  margin-bottom: 0;
  color: #898987;
  text-align: left;
}
@media (max-width: 767.98px) {
  .search-dropdown form input[type=text],
  .search-dropdown form .navbar-search {
    font-size: 1.5rem;
    height: 3rem;
    margin-left: -10px;
    width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .search-dropdown form input[type=text],
  .search-dropdown form .navbar-search {
    width: 48vw;
    font-size: 1.2rem;
    margin-top: 1rem;
  }
}
@media (min-width: 992px) {
  .search-dropdown form input[type=text],
  .search-dropdown form .navbar-search {
    font-size: 2.6rem;
    height: 4rem;
    width: 36vw;
    margin-left: 0;
  }
}
.search-dropdown form input[type=text]:active, .search-dropdown form input[type=text]:hover, .search-dropdown form input[type=text]:focus,
.search-dropdown form .navbar-search:active,
.search-dropdown form .navbar-search:hover,
.search-dropdown form .navbar-search:focus {
  border: 2px solid #26366a !important;
  outline: 2px solid #26366a !important;
}
@media (max-width: 767.98px) {
  .search-dropdown form input[type=text]:active, .search-dropdown form input[type=text]:hover, .search-dropdown form input[type=text]:focus,
  .search-dropdown form .navbar-search:active,
  .search-dropdown form .navbar-search:hover,
  .search-dropdown form .navbar-search:focus {
    -webkit-box-shadow: 5px 5px 15px -1px #cfdded !important;
    box-shadow: 5px 5px 15px -1px #cfdded !important;
    border: 3px solid #e8f3ff !important;
    background: #fcfcfc;
  }
}
@media (min-width: 992px) {
  .search-dropdown form input[type=text]:active, .search-dropdown form input[type=text]:hover, .search-dropdown form input[type=text]:focus,
  .search-dropdown form .navbar-search:active,
  .search-dropdown form .navbar-search:hover,
  .search-dropdown form .navbar-search:focus {
    -webkit-box-shadow: 5px 5px 15px -1px #adc7de !important;
    box-shadow: 5px 5px 15px -1px #adc7de !important;
    background: #fcfcfc;
  }
}
.search-dropdown form .search-go-button {
  background: none;
  color: #26366a;
  border: 0;
  width: fit-content;
  z-index: 10;
  padding: 0;
  position: relative;
}
@media (max-width: 575.98px) {
  .search-dropdown form .search-go-button {
    left: 32vw;
    margin-left: auto;
    margin-right: -5vw;
    position: relative;
    top: -3rem;
  }
  .search-dropdown form .search-go-button .fa-search {
    font-size: 2.4rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .search-dropdown form .search-go-button {
    margin-left: -4vw;
    margin-top: -20rem;
  }
  .search-dropdown form .search-go-button .fa-search {
    font-size: 3rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .search-dropdown form .search-go-button {
    right: 5vw;
    font-size: 3rem !important;
    margin-top: -4rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .search-dropdown form .search-go-button {
    font-size: 3rem !important;
    left: -4.5vw;
    margin-top: -4rem;
    top: -0.1rem;
  }
}
@media (min-width: 1400px) {
  .search-dropdown form .search-go-button {
    font-size: 3rem !important;
    left: -4vw;
    margin-top: -4rem;
    top: -0.1rem;
  }
}
@media (min-width: 992px) {
  .search-dropdown form .search-go-button .search-go:active, .search-dropdown form .search-go-button .search-go:hover, .search-dropdown form .search-go-button .search-go:focus {
    border: 0 !important;
  }
}

.search-form-masthead form {
  z-index: 11000;
  background: transparent;
}
@media (max-width: 575.98px) {
  .search-form-masthead form {
    width: 100%;
    padding-left: 0;
    margin-bottom: -1.7rem;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .search-form-masthead form {
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-bottom: 2rem;
  }
}
@media (min-width: 992px) {
  .search-form-masthead form {
    margin-right: 0;
    width: 14vw;
    text-align: right;
    margin-left: auto;
    margin-top: 0.3rem;
  }
}
.search-form-masthead form .label-hide {
  display: none;
}
.search-form-masthead form input[type=text],
.search-form-masthead form input[type=search],
.search-form-masthead form .navbar-search {
  border-radius: 6px;
  border: 1px solid #a5beda;
  outline: 1px solid #a5beda;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  background: #e8f3ff;
  font-style: italic;
  padding: 2px 8px;
  margin-top: 0.2rem;
  margin-bottom: 0;
  color: #606060;
  text-align: left;
}
@media (max-width: 767.98px) {
  .search-form-masthead form input[type=text],
  .search-form-masthead form input[type=search],
  .search-form-masthead form .navbar-search {
    font-size: 1.5rem;
    height: 3rem;
    margin-left: -10px;
    width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .search-form-masthead form input[type=text],
  .search-form-masthead form input[type=search],
  .search-form-masthead form .navbar-search {
    width: 24vw;
    font-size: 1rem;
    margin-top: 0.2rem;
  }
}
@media (min-width: 992px) {
  .search-form-masthead form input[type=text],
  .search-form-masthead form input[type=search],
  .search-form-masthead form .navbar-search {
    font-size: 1.1rem;
    max-width: 20vw;
  }
}
.search-form-masthead form input[type=text]:active, .search-form-masthead form input[type=text]:hover, .search-form-masthead form input[type=text]:focus,
.search-form-masthead form input[type=search]:active,
.search-form-masthead form input[type=search]:hover,
.search-form-masthead form input[type=search]:focus,
.search-form-masthead form .navbar-search:active,
.search-form-masthead form .navbar-search:hover,
.search-form-masthead form .navbar-search:focus {
  border: 1px solid #3d5193 !important;
  outline: 1px solid #3d5193 !important;
}
@media (max-width: 767.98px) {
  .search-form-masthead form input[type=text]:active, .search-form-masthead form input[type=text]:hover, .search-form-masthead form input[type=text]:focus,
  .search-form-masthead form input[type=search]:active,
  .search-form-masthead form input[type=search]:hover,
  .search-form-masthead form input[type=search]:focus,
  .search-form-masthead form .navbar-search:active,
  .search-form-masthead form .navbar-search:hover,
  .search-form-masthead form .navbar-search:focus {
    -webkit-box-shadow: 5px 5px 15px -1px #cfdded !important;
    box-shadow: 5px 5px 15px -1px #cfdded !important;
    border: 3px solid #e8f3ff !important;
    background: #fcfcfc;
  }
}
@media (min-width: 992px) {
  .search-form-masthead form input[type=text]:active, .search-form-masthead form input[type=text]:hover, .search-form-masthead form input[type=text]:focus,
  .search-form-masthead form input[type=search]:active,
  .search-form-masthead form input[type=search]:hover,
  .search-form-masthead form input[type=search]:focus,
  .search-form-masthead form .navbar-search:active,
  .search-form-masthead form .navbar-search:hover,
  .search-form-masthead form .navbar-search:focus {
    -webkit-box-shadow: 5px 5px 15px -1px #adc7de !important;
    box-shadow: 5px 5px 15px -1px #adc7de !important;
    background: #fcfcfc;
  }
}
.search-form-masthead form .search-go-button {
  background: none;
  color: #3d5193;
  border: 0;
  width: fit-content;
  z-index: 10;
  padding: 0;
  position: relative;
}
@media (max-width: 575.98px) {
  .search-form-masthead form .search-go-button {
    left: 32vw;
    margin-left: auto;
    margin-right: -5vw;
    position: relative;
    top: -3rem;
  }
  .search-form-masthead form .search-go-button .fa-search {
    font-size: 2.4rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .search-form-masthead form .search-go-button {
    margin-left: -4vw;
    margin-top: -20rem;
  }
  .search-form-masthead form .search-go-button .fa-search {
    font-size: 2rem !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .search-form-masthead form .search-go-button {
    font-size: 1.6rem !important;
    margin-top: -1.9rem;
    float: right;
    margin-right: 0.4rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .search-form-masthead form .search-go-button {
    font-size: 1.7rem !important;
    margin-top: -1.9rem;
    float: right;
    margin-right: 0.4rem;
  }
}
@media (min-width: 1400px) {
  .search-form-masthead form .search-go-button {
    font-size: 1.6rem !important;
    margin-top: -1.9rem;
    float: right;
    margin-right: 0.4rem;
  }
}
.search-form-masthead form .search-go-button:active, .search-form-masthead form .search-go-button:hover, .search-form-masthead form .search-go-button:focus {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}
@media (min-width: 992px) {
  .search-form-masthead form .search-go-button .search-go:active, .search-form-masthead form .search-go-button .search-go:hover, .search-form-masthead form .search-go-button .search-go:focus {
    color: #26366a;
    box-shadow: none;
    -webkit-box-shadow: none;
  }
}

.accordion {
  margin: 40px 0;
  width: 100%;
  --bs-accordion-btn-focus-border-color: $white;
  --bs-accordion-btn-focus-box-shadow: 0;
}
@media screen and (max-width: 40em) {
  .accordion {
    padding: 20px;
  }
}
@media screen and (min-width: 40.063em) {
  .accordion {
    padding: 20px 100px;
  }
}
.accordion .accordion-item {
  width: 100%;
  padding-bottom: 0;
  background: #fcfcfc !important;
  border-radius: 8px;
}
.accordion .p1 {
  font-size: 1rem;
}
.accordion .accordion-header {
  font-size: 1.2rem;
  font-weight: 500;
  font-variation-settings: "wght" 500;
  color: #111111;
  margin-bottom: -10px;
  padding-bottom: 0;
  padding-top: 6px;
}
.accordion .accordion-header:active, .accordion .accordion-header:hover, .accordion .accordion-header:focus {
  border: 0 !important;
  outline: 0 !important;
  box-shadow: 0 !important;
}
.accordion .accordion-header .collapse:active, .accordion .accordion-header .collapse:hover, .accordion .accordion-header .collapse:focus {
  border: 0 !important;
  outline: 0 !important;
  box-shadow: 0 !important;
}
.accordion .accordion-header .accordion-button {
  font-size: 1.2rem;
}
.accordion .accordion-header .accordion-button:active, .accordion .accordion-header .accordion-button:hover, .accordion .accordion-header .accordion-button:focus {
  border: 0 !important;
  outline: 0 !important;
  box-shadow: 0 !important;
  -webkit-box-shadow: 0;
  -moz-box-shadow: 0;
  border-color: #fcfcfc !important;
  font-weight: 600 !important;
  font-variation-settings: "wght" 600;
}
.accordion .accordion-header .accordion-button:not(.collapsed) {
  background: #fcfcfc !important;
  box-shadow: 0 !important;
  -webkit-box-shadow: 0;
  -moz-box-shadow: 0;
}
.accordion .accordion-header .accordion-button .accordion-title:active, .accordion .accordion-header .accordion-button .accordion-title:hover, .accordion .accordion-header .accordion-button .accordion-title:focus {
  font-weight: 700 !important;
  font-variation-settings: "wght" 700;
  color: #26366a !important;
}
.accordion .accordion-body {
  font-size: 1.2rem;
  background: #fcfcfc !important;
}

/*
.fw-accordion {
    .accordion-item {
        padding: 1rem;
        .accordion-header {
            padding-bottom: 0;
            margin-bottom: 0;
            font-weight: normal;
            font-size: 1.2rem;
            &:hover,
            &:active,
            &:focus {
                color: $blue-darker;
                font-style: normal;
            }
        }
        .accordion-body {
            p {
                font-size: 1rem;
                font-weight: normal;
            }
            img {
                float: left;
                padding-left: 1rem;
                width: 110px;
                border: 1px solid $gray-darker;
            }
        }
    }
} */
.elect-alert-banner {
  width: 100%;
  padding: 8px;
  margin: 0;
  text-align: center;
}
@media (min-width: 992px) {
  .elect-alert-banner {
    margin-top: 0 !important;
    margin-bottom: 0;
  }
}
.elect-alert-banner p {
  text-align: center;
  margin: auto;
  font-size: 1rem;
  color: inherit;
  font-weight: 500;
  font-variation-settings: "wght" 500;
  letter-spacing: 0.08rem;
}
@media (min-width: 992px) {
  .elect-alert-banner p {
    font-size: 0.9rem;
  }
}
.elect-alert-banner p a {
  text-decoration: underline !important;
  color: inherit;
}
.elect-alert-banner p a:hover, .elect-alert-banner p a:focus, .elect-alert-banner p a:active {
  transform: scale 1.1, 1.1;
}

.elect-body {
  --bs-dropdown-border-width:0!important;
  --bs-dropdown-border-color:transparent!important;
}

.dropdown-banner .show {
  background-color: #9c1212 !important;
  border-width: 0 !important;
  border-color: transparent !important;
}
.dropdown-banner .dropdown-toggle {
  background-color: #9c1212;
  font-size: 1.2rem;
  font-style: italic;
  font-weight: 600;
}
.dropdown-banner .dropdown-toggle::after {
  background-color: #9c1212;
  content: "" !important;
  display: none;
}
.dropdown-banner .dropdown-toggle:hover, .dropdown-banner .dropdown-toggle:focus, .dropdown-banner .dropdown-toggle:active {
  background-color: #9c1212;
  border: 1px solid #8f1616;
  border-radius: 0;
}
.dropdown-banner a.show {
  border: 1px solid #bb322d !important;
}
.dropdown-banner ul {
  width: 100%;
  margin: -1rem 0 0 !important;
  padding-left: 40vw;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border-radius: 0;
  background-color: #9c1212;
  list-style: disc !important;
  color: #fcfcfc;
}
.dropdown-banner ul li {
  font-weight: 600 !important;
  font-variation-settings: "wght", 600 !important;
  padding-bottom: 0.4rem;
  color: #fcfcfc;
}
.dropdown-banner ul li a {
  background-color: #9c1212;
  font-size: 1.2rem;
  color: #fcfcfc;
  text-decoration: dotted !important;
}
.dropdown-banner ul li a:hover, .dropdown-banner ul li a:focus, .dropdown-banner ul li a:active {
  background-color: #9c1212;
  color: #f8efd4;
}
.dropdown-banner ul.show {
  -webkit-box-shadow: 5px 5px 15px -1px #111111;
  box-shadow: 5px 5px 3px -1px rgba(0, 0, 0, 0.4);
}

@media (max-width: 575.98px) {
  .banner,
  .banner-with-alert,
  .banner-with-events,
  .banner-photobkg,
  .banner-event-countdown,
  .banner-2 {
    padding: 1rem !important;
  }
}
@media (min-width: 768px) {
  .banner,
  .banner-with-alert,
  .banner-with-events,
  .banner-photobkg,
  .banner-event-countdown,
  .banner-2 {
    padding: 2rem !important;
  }
}
@media (min-width: 992px) {
  .banner,
  .banner-with-alert,
  .banner-with-events,
  .banner-photobkg,
  .banner-event-countdown,
  .banner-2 {
    padding: 2.5rem 8vw !important;
  }
}
.banner h1, .banner .h1,
.banner h2,
.banner .h2,
.banner h3,
.banner .h3,
.banner-with-alert h1,
.banner-with-alert .h1,
.banner-with-alert h2,
.banner-with-alert .h2,
.banner-with-alert h3,
.banner-with-alert .h3,
.banner-with-events h1,
.banner-with-events .h1,
.banner-with-events h2,
.banner-with-events .h2,
.banner-with-events h3,
.banner-with-events .h3,
.banner-photobkg h1,
.banner-photobkg .h1,
.banner-photobkg h2,
.banner-photobkg .h2,
.banner-photobkg h3,
.banner-photobkg .h3,
.banner-event-countdown h1,
.banner-event-countdown .h1,
.banner-event-countdown h2,
.banner-event-countdown .h2,
.banner-event-countdown h3,
.banner-event-countdown .h3,
.banner-2 h1,
.banner-2 .h1,
.banner-2 h2,
.banner-2 .h2,
.banner-2 h3,
.banner-2 .h3 {
  font-weight: 500 !important;
  font-variation-settings: "wght" 500 !important;
  width: 100%;
  color: #26366a;
  font-family: "Oswald", "HeaderFallBackMac", "HeaderFallBackWin", sans-serif !important;
}

.banner h1, .banner .h1,
.banner h2,
.banner .h2,
.banner h3,
.banner .h3,
.banner-2 h1,
.banner-2 .h1,
.banner-2 h2,
.banner-2 .h2,
.banner-2 h3,
.banner-2 .h3 {
  text-align: center;
  font-size: 3rem;
}
@media (min-width: 1200px) {
  .banner h1, .banner .h1,
  .banner h2,
  .banner .h2,
  .banner h3,
  .banner .h3,
  .banner-2 h1,
  .banner-2 .h1,
  .banner-2 h2,
  .banner-2 .h2,
  .banner-2 h3,
  .banner-2 .h3 {
    font-size: 3.8vw !important;
  }
}
@media (max-width: 575.98px) {
  .banner h2, .banner .h2,
  .banner-2 h2,
  .banner-2 .h2 {
    padding-top: 0.7rem;
  }
}
.banner p,
.banner-2 p {
  font-weight: 500;
  font-variation-settings: "wght" 500;
}
@media (max-width: 767.98px) {
  .banner p,
  .banner-2 p {
    font-size: 1.5rem;
    letter-spacing: 0.007rem;
    font-weight: 500;
    font-variation-settings: "wght" 500;
  }
}
@media (min-width: 992px) {
  .banner p,
  .banner-2 p {
    font-size: 1.8vw !important;
    letter-spacing: 0.05rem;
  }
}
@media screen and (min-device-width: 768px) and (max-device-width: 1099px) and (orientation: portrait) {
  .banner .banner-image,
  .banner-2 .banner-image {
    text-align: center;
  }
}
.banner img,
.banner-2 img {
  width: 100%;
  height: auto;
  max-height: 450px;
}
@media screen and (min-device-width: 768px) and (max-device-width: 1099px) and (orientation: portrait) {
  .banner img,
  .banner-2 img {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}
.banner .banner-text,
.banner-2 .banner-text {
  padding: 1rem;
}
.banner .banner-text .banner-text-div,
.banner-2 .banner-text .banner-text-div {
  text-align: center;
  width: 100%;
  padding: 1rem;
}

.hero-photobkg {
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 767.98px) {
  .hero-photobkg {
    padding: 1rem;
  }
}
@media (min-width: 992px) {
  .hero-photobkg {
    min-height: 500px;
  }
}
.hero-photobkg .hero-photobkg-overlay {
  background: #fcfcfc;
  text-align: left;
  -webkit-box-shadow: 5px 5px 15px -1px rgba(0, 0, 0, 0.4);
  box-shadow: 5px 5px 15px -1px rgba(0, 0, 0, 0.4);
}
@media (max-width: 767.98px) {
  .hero-photobkg .hero-photobkg-overlay {
    padding: 40px 30px 30px;
    background: #e8f3ff;
  }
}
@media (min-width: 992px) {
  .hero-photobkg .hero-photobkg-overlay {
    display: flex;
    padding: 40px 60px;
  }
}
@media screen and (min-device-width: 768px) and (max-device-width: 1099px) and (orientation: landscape) {
  .hero-photobkg .hero-photobkg-overlay {
    padding: 0 40px 30px;
  }
}
.hero-photobkg .hero-photobkg-overlay .broken-line img {
  aspect-ratio: 100/1;
}
@media (max-width: 767.98px) {
  .hero-photobkg .hero-photobkg-overlay .broken-line img {
    width: 100%;
  }
}
.hero-photobkg .hero-photobkg-overlay .banner-text-box {
  padding: 1rem;
  text-align: left !important;
}
.hero-photobkg .hero-photobkg-overlay .banner-text-box h2, .hero-photobkg .hero-photobkg-overlay .banner-text-box .h2 {
  text-align: left !important;
}
.hero-photobkg .hero-photobkg-overlay .banner-text-div {
  width: 100%;
}
@media (max-width: 767.98px) {
  .hero-photobkg .hero-photobkg-overlay .banner-text-div {
    padding: 0;
    text-align: center;
  }
}
@media (min-width: 992px) {
  .hero-photobkg .hero-photobkg-overlay .banner-text-div {
    text-align: center;
    padding: 0;
    align-self: center;
  }
}
@media screen and (min-device-width: 768px) and (max-device-width: 1099px) and (orientation: landscape) {
  .hero-photobkg .hero-photobkg-overlay .banner-text-div {
    top: 10%;
    padding: 0 42px;
    text-align: center;
  }
}
@media (max-width: 767.98px) {
  .hero-photobkg .hero-photobkg-overlay .banner-text-div h2, .hero-photobkg .hero-photobkg-overlay .banner-text-div .h2 {
    margin-top: 0.6rem;
  }
}
.hero-photobkg .hero-photobkg-overlay .banner-text-div p {
  font-weight: 500;
  font-variation-settings: "wght" 500;
  width: 100%;
}
@media (max-width: 767.98px) {
  .hero-photobkg .hero-photobkg-overlay .banner-text-div p {
    text-align: left;
    font-size: 1.5rem;
  }
}
@media (min-width: 992px) {
  .hero-photobkg .hero-photobkg-overlay .banner-text-div p {
    font-size: 1.6rem;
    line-height: 1.3;
    letter-spacing: 0.05rem;
  }
}
@media (max-width: 767.98px) {
  .hero-photobkg .hero-photobkg-overlay .banner-text-div .banner-button {
    padding: 7px 30px !important;
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 64.063em) and (max-width: 80em) {
  .hero-photobkg .hero-photobkg-overlay .banner-button {
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (min-device-width: 768px) and (max-device-width: 1099px) and (orientation: landscape) {
  .hero-photobkg .hero-photobkg-overlay .banner-button {
    margin-left: -20px !important;
  }
}

@media (max-width: 575.98px) {
  .banner-2 {
    min-height: 350px;
  }
}

/*.banner-with-events {
    h4 {
        font-weight: 600;
        padding-top:30px;
    }
    p {
        font-weight: 400;
        letter-spacing: 0.05rem;
        @include media-breakpoint-down(md) { 
            font-size: 1.0rem;
        }
        @include media-breakpoint-up(lg) {
            font-size: 1.6rem;
        }
    }
    .events-component {
        @include media-breakpoint-up(lg) {
            padding-right: 120px;
            padding-top:30px;
            padding-left:0;
            margin-left: -14px;
        }
        .small {
            text-align: center;
            font-weight: 400;
        }
    }
}  
.banner-event-countdown  {
    .header-cell  {
        display: flex;
        flex-direction: column;
        height: 4.2rem;
        align-items: center;
        justify-content: space-evenly;
        h5, p {
            width: 100%;
            text-align:center;
        }
        p.small {
            font-weight:400;
            font-size: .85rem;
            padding-top: 0;
            margin-top: -2px;
            margin-bottom: auto;
        }
    }
    .lower-grid {
        width: 100%;
    }
    .event-countdown-boxes {
        width: 100%;
        @media screen and (min-width: 64.063em) {
            max-width: 40vw;
        }
    }
    .countdown-boxes {
        padding: 10px;
        @include media-breakpoint-down(md) { 
            width: 100%;
        }
    }
    h5,
    .countdown-box-header {
        text-align: center;
        font-weight: bold;
        line-height: 1;
        padding-top:12px;
        color: $blue-darker;
    }
    h3, 
    .countdown-date {
        text-align: right;
        font-weight: bold;
        font-size: 4.5rem;
        color: $blue-darker;
        margin-left: -20px;
        letter-spacing: -.1rem;
    }
    p {
        padding-top:12px;
        padding-left:0;
        margin-left: -12px;
        line-height: 1.1;
        font-weight: 400;
        margin-top:auto;
        margin-bottom:auto;
    }
    p.event-text {
        font-weight: 500!important;
        padding-top: 18px;
        line-height: 1.4;
        font-size: 1.4rem;
        color: $blue-darker;
        padding-left:0;
        margin-right: -10px;
    }
    .countdown-boxes-flex {
        display:flex;
        width: 100%;
        padding: 10px;
        .countdown-date-cell {
            width: 45%;
            align-self: center;
            @include media-breakpoint-down(md) { 
                margin-left: 16px;
            }
            h3, 
            .countdown-date {
                text-align: right;
                font-weight: bold;
                font-size: 4.5rem;
                color: $blue-darker;
                letter-spacing: -.1rem;
                padding-top: 10px;
                padding-right: 10px;
            }
        }
        .countdown-text-cell {          
            display: flex;
            justify-content:center;
            width: 55%;
            p.event-text {
                font-weight: 500!important;
                padding-top:0;
                line-height: 1.4;
                font-size: 1.4rem;
                color: $blue-darker;
                text-align: left;
                padding-left: 14px;
            }
        }
    }
} */
@media (min-width: 992px) {
  .banner-2 {
    width: 100%;
  }
}
@media (max-width: 767.98px) {
  .banner-2 .mobile-banner-text {
    letter-spacing: 0.007rem !important;
  }
}
.banner-2 .banner-text-div {
  text-align: center;
  width: 100%;
}

@media (max-width: 575.98px) {
  .news-events {
    padding: 30px 20px;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .news-events {
    padding: 10px 20px;
    margin-top: 20px;
  }
}
@media (min-width: 992px) {
  .news-events {
    padding-left: 8vw !important;
    padding-right: 8vw !important;
    margin-top: 100px;
  }
}
@media (max-width: 575.98px) {
  .news-events .shadow-box {
    margin-bottom: 18px;
    padding: 10px;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .news-events .shadow-box {
    padding: 10px;
    width: 100%;
    margin-bottom: 18px;
  }
}
@media (min-width: 992px) {
  .news-events .shadow-box {
    width: calc(25% - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    padding: 10px;
  }
}
.news-events .news-date {
  border-right: 1px solid #9c1212;
  justify-content: center;
  align-content: center;
  display: flex;
}
.news-events .news-date .date-div {
  margin-top: auto;
  margin-bottom: auto;
}
.news-events .news-date h4, .news-events .news-date .h4, .news-events .news-date h2, .news-events .news-date .h2 {
  color: #26366a;
  margin-left: auto;
  margin-right: auto;
}
.news-events .news-date h4.month, .news-events .news-date .month.h4 {
  line-height: 1;
  padding-bottom: 0;
  margin-bottom: 2px;
  padding-top: 10px;
  font-weight: normal;
}
.news-events .news-date h2.day, .news-events .news-date .day.h2 {
  line-height: 1;
  padding-top: 0;
  font-weight: 700;
  font-variation-settings: "wght" 700;
  font-size: 3.2rem;
  font-family: "Open Sans", sans-serif;
}
.news-events .news-headline {
  padding: 10px 15px;
}
.news-events .news-headline h5, .news-events .news-headline .h5 {
  color: #606060;
  font-weight: 700;
  font-variation-settings: "wght" 700;
  font-size: 1rem;
  letter-spacing: 0.02rem;
  margin-bottom: 0.4rem;
}
.news-events .news-headline p {
  font-size: 0.6rem !important;
  color: #111111;
  letter-spacing: 0;
  padding-bottom: 0.4rem;
  margin-bottom: 0;
}
.news-events svg {
  fill: #bbbbbb;
}
.news-events svg path {
  fill: #bbbbbb;
}
.news-events .headline-img {
  color: #bbbbbb;
  fill: #bbbbbb;
  width: 1.5rem;
  height: auto;
}
.news-events .headline-img svg {
  fill: #bbbbbb;
}
.news-events .headline-img svg path {
  fill: #bbbbbb;
}
.news-events .headline-img:hover, .news-events .headline-img:active {
  -webkit-filter: hue-rotate(125deg) saturate(200%) sepia(60%) invert(60%);
  filter: hue-rotate(125deg) saturate(200%) sepia(60%) invert(60%);
}

@media screen and (min-width: 64.063em) {
  .news-events-cell {
    margin-top: -60px;
    margin-bottom: 40px;
  }
}
@media (max-width: 767.98px) {
  .news-events-cell h2.title, .news-events-cell .title.h2 {
    font-size: 2rem;
    margin-bottom: -30px;
  }
}

@media (max-width: 575.98px) {
  .news-events {
    padding: 30px 20px;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .news-events {
    padding: 10px 20px;
    margin-top: 20px;
  }
}
@media (min-width: 992px) {
  .news-events {
    padding-left: 8vw !important;
    padding-right: 8vw !important;
    margin-top: 100px;
  }
}
.news-events .shadow-box {
  -webkit-box-shadow: 5px 5px 15px -1px #898987;
  box-shadow: 5px 5px 15px -1px #898987;
  min-height: 100px;
}
@media screen and (max-width: 40em) {
  .news-events .shadow-box {
    margin-bottom: 18px;
    padding: 10px;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .news-events .shadow-box {
    padding: 10px;
    width: 100%;
    margin-bottom: 18px;
  }
}
@media (min-width: 992px) {
  .news-events .shadow-box {
    width: calc(25% - 2rem);
    margin-left: 1rem;
    margin-right: 1rem;
    padding: 10px;
  }
}
.news-events .news-date {
  border-right: 1px solid #9c1212;
  justify-content: center;
  align-content: center;
  display: flex;
}
.news-events .news-date .date-div {
  margin-top: auto;
  margin-bottom: auto;
}
.news-events .news-date h4, .news-events .news-date .h4, .news-events .news-date h2, .news-events .news-date .h2 {
  color: #26366a;
  margin-left: auto;
  margin-right: auto;
}
.news-events .news-date h4.month,
.news-events .news-date .month {
  line-height: 1;
  padding-bottom: 0;
  margin-bottom: 2px;
  padding-top: 10px;
  font-weight: normal;
  color: #606060 !important;
}
.news-events .news-date h2.day,
.news-events .news-date .day {
  line-height: 1;
  padding-top: 0;
  font-weight: 700;
  font-variation-settings: "wght" 700;
  font-size: 3.2rem;
  font-family: "Open Sans", sans-serif;
  color: #606060 !important;
}
.news-events .news-headline {
  padding: 10px 15px;
}
.news-events .news-headline h5, .news-events .news-headline .h5 {
  color: #505050;
  font-weight: 700;
  font-variation-settings: "wght" 700;
  font-size: 1rem;
  letter-spacing: 0.02rem;
  margin-bottom: 0.4rem;
}
.news-events .news-headline p {
  font-size: 0.7rem !important;
  color: #111111;
  letter-spacing: 0;
  padding-bottom: 0.4rem;
  margin-bottom: 0;
}
.news-events svg {
  fill: #bbbbbb;
}
.news-events svg path {
  fill: #bbbbbb;
}
.news-events .headline-img {
  color: #bbbbbb;
  fill: #bbbbbb;
  width: 1.5rem;
  height: auto;
}
.news-events .headline-img svg {
  fill: #bbbbbb;
}
.news-events .headline-img svg path {
  fill: #bbbbbb;
}
.news-events .headline-img:hover, .news-events .headline-img:active {
  -webkit-filter: hue-rotate(125deg) saturate(200%) sepia(60%) invert(60%);
  filter: hue-rotate(125deg) saturate(200%) sepia(60%) invert(60%);
}

.line-event-banner {
  width: 100%;
}
@media (max-width: 767.98px) {
  .line-event-banner {
    padding: 1rem 8vw !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .line-event-banner {
    padding: 2rem 8vw;
  }
}
@media (min-width: 1200px) {
  .line-event-banner {
    padding: 2rem 12vw !important;
  }
}
.line-event-banner .banner-text {
  text-align: center;
}
.line-event-banner .banner-text h1, .line-event-banner .banner-text .h1,
.line-event-banner .banner-text h2,
.line-event-banner .banner-text .h2,
.line-event-banner .banner-text h3,
.line-event-banner .banner-text .h3 {
  text-align: center;
}
.line-event-banner .banner-text p {
  padding-top: 0.4rem;
  text-align: center;
}
.line-event-banner .banner-text button {
  margin-left: auto;
  margin-right: auto;
}
.line-event-banner .event-boxes {
  padding-top: 2rem;
  padding-left: 0;
  padding-right: 0;
}
.line-event-banner .event-box {
  padding: 0.8rem;
  background: #fcfcfc;
  margin-bottom: 1rem;
  border-radius: 4px;
  -webkit-box-shadow: 5px 5px 15px -1px #505050;
  box-shadow: 5px 5px 15px -1px #505050;
}
.line-event-banner .event-box .news-headline {
  padding: 3% 0 0 0.3rem;
  text-align: center;
}
.line-event-banner .event-box .news-headline .grid-y .small-9 {
  padding-left: 0.1rem;
}
.line-event-banner .event-box .news-headline h5, .line-event-banner .event-box .news-headline .h5 {
  margin-bottom: 0.2rem;
  line-height: 1.2;
}
.line-event-banner .event-box .news-headline p {
  font-size: 1rem;
  line-height: 1.1;
  margin-bottom: 0.2rem;
}
.line-event-banner .event-box .news-date {
  border-right: 5px solid #9c1212;
  justify-content: center;
  align-content: center;
  display: flex;
}
.line-event-banner .event-box .news-date .date-div {
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
}
.line-event-banner .event-box .news-date h4, .line-event-banner .event-box .news-date .h4, .line-event-banner .event-box .news-date h2, .line-event-banner .event-box .news-date .h2 {
  color: #26366a;
  margin-left: auto;
  margin-right: auto;
}
.line-event-banner .event-box .news-date .month {
  line-height: 1;
  padding-bottom: 0;
  margin-bottom: 2px;
  padding-top: 10px;
  font-weight: bold;
  color: #606060;
}
.line-event-banner .event-box .news-date .day {
  line-height: 1;
  padding-top: 0;
  font-weight: 700;
  font-variation-settings: "wght" 700;
  font-size: 3.2rem;
  font-family: "Open Sans", sans-serif;
  color: #606060;
}
.line-event-banner .event-box .headline-img {
  color: #bbbbbb;
  fill: #bbbbbb;
  width: 1.5rem;
  height: auto;
  margin-top: 0.2rem;
}
.line-event-banner .event-box .headline-img svg {
  fill: #bbbbbb;
}
.line-event-banner .event-box .headline-img svg path {
  fill: #bbbbbb;
}
.line-event-banner .event-box .headline-img:hover, .line-event-banner .event-box .headline-img:active {
  -webkit-filter: hue-rotate(125deg) saturate(200%) sepia(60%) invert(60%);
  filter: hue-rotate(125deg) saturate(200%) sepia(60%) invert(60%);
}

.lower-page-banner {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.horiz-text-banner {
  width: 100%;
}
.horiz-text-banner .banner-text-div {
  padding: 1rem;
}
.horiz-text-banner .banner-text {
  text-align: center;
}
@media (max-width: 767.98px) {
  .horiz-text-banner .banner-text {
    padding: 1rem 8vw !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .horiz-text-banner .banner-text {
    padding: 3.8rem 12vw !important;
    min-height: 320px;
  }
}
@media (min-width: 1200px) {
  .horiz-text-banner .banner-text {
    padding: 4rem 16vw !important;
    min-height: 320px;
  }
}
.horiz-text-banner .banner-text h1, .horiz-text-banner .banner-text .h1,
.horiz-text-banner .banner-text h2,
.horiz-text-banner .banner-text .h2,
.horiz-text-banner .banner-text h3,
.horiz-text-banner .banner-text .h3 {
  text-align: center;
  font-size: 2.8rem;
}
@media (min-width: 1200px) {
  .horiz-text-banner .banner-text h1, .horiz-text-banner .banner-text .h1,
  .horiz-text-banner .banner-text h2,
  .horiz-text-banner .banner-text .h2,
  .horiz-text-banner .banner-text h3,
  .horiz-text-banner .banner-text .h3 {
    font-size: 3.5vw !important;
  }
}
@media (max-width: 575.98px) {
  .horiz-text-banner .banner-text h2, .horiz-text-banner .banner-text .h2 {
    padding-top: 0.7rem;
  }
}
.horiz-text-banner .banner-text .text-div p {
  padding-top: 0.4rem;
  text-align: center;
  font-size: 1.5rem;
}
.horiz-text-banner .banner-text .text-div.color-blue-darker a,
.horiz-text-banner .banner-text .text-div.color-blue-darker p a, .horiz-text-banner .banner-text .text-div.color-blue-darkest a,
.horiz-text-banner .banner-text .text-div.color-blue-darkest p a, .horiz-text-banner .banner-text .text-div.color-black a,
.horiz-text-banner .banner-text .text-div.color-black p a, .horiz-text-banner .banner-text .text-div.color-red-darkest a,
.horiz-text-banner .banner-text .text-div.color-red-darkest p a, .horiz-text-banner .banner-text .text-div.color-red-darker a,
.horiz-text-banner .banner-text .text-div.color-red-darker p a, .horiz-text-banner .banner-text .text-div.color-red a,
.horiz-text-banner .banner-text .text-div.color-red p a, .horiz-text-banner .banner-text .text-div.color-red-orange a,
.horiz-text-banner .banner-text .text-div.color-red-orange p a, .horiz-text-banner .banner-text .text-div.color-gray-darker a,
.horiz-text-banner .banner-text .text-div.color-gray-darker p a, .horiz-text-banner .banner-text .text-div.color-gray-darkest a,
.horiz-text-banner .banner-text .text-div.color-gray-darkest p a, .horiz-text-banner .banner-text .text-div.color-teal a,
.horiz-text-banner .banner-text .text-div.color-teal p a {
  color: #26366a !important;
  text-decoration: underline !important;
}
.horiz-text-banner .banner-text .text-div.color-blue-darker a:hover, .horiz-text-banner .banner-text .text-div.color-blue-darker a:active, .horiz-text-banner .banner-text .text-div.color-blue-darker a:visited, .horiz-text-banner .banner-text .text-div.color-blue-darker a:focus,
.horiz-text-banner .banner-text .text-div.color-blue-darker p a:hover,
.horiz-text-banner .banner-text .text-div.color-blue-darker p a:active,
.horiz-text-banner .banner-text .text-div.color-blue-darker p a:visited,
.horiz-text-banner .banner-text .text-div.color-blue-darker p a:focus, .horiz-text-banner .banner-text .text-div.color-blue-darkest a:hover, .horiz-text-banner .banner-text .text-div.color-blue-darkest a:active, .horiz-text-banner .banner-text .text-div.color-blue-darkest a:visited, .horiz-text-banner .banner-text .text-div.color-blue-darkest a:focus,
.horiz-text-banner .banner-text .text-div.color-blue-darkest p a:hover,
.horiz-text-banner .banner-text .text-div.color-blue-darkest p a:active,
.horiz-text-banner .banner-text .text-div.color-blue-darkest p a:visited,
.horiz-text-banner .banner-text .text-div.color-blue-darkest p a:focus, .horiz-text-banner .banner-text .text-div.color-black a:hover, .horiz-text-banner .banner-text .text-div.color-black a:active, .horiz-text-banner .banner-text .text-div.color-black a:visited, .horiz-text-banner .banner-text .text-div.color-black a:focus,
.horiz-text-banner .banner-text .text-div.color-black p a:hover,
.horiz-text-banner .banner-text .text-div.color-black p a:active,
.horiz-text-banner .banner-text .text-div.color-black p a:visited,
.horiz-text-banner .banner-text .text-div.color-black p a:focus, .horiz-text-banner .banner-text .text-div.color-red-darkest a:hover, .horiz-text-banner .banner-text .text-div.color-red-darkest a:active, .horiz-text-banner .banner-text .text-div.color-red-darkest a:visited, .horiz-text-banner .banner-text .text-div.color-red-darkest a:focus,
.horiz-text-banner .banner-text .text-div.color-red-darkest p a:hover,
.horiz-text-banner .banner-text .text-div.color-red-darkest p a:active,
.horiz-text-banner .banner-text .text-div.color-red-darkest p a:visited,
.horiz-text-banner .banner-text .text-div.color-red-darkest p a:focus, .horiz-text-banner .banner-text .text-div.color-red-darker a:hover, .horiz-text-banner .banner-text .text-div.color-red-darker a:active, .horiz-text-banner .banner-text .text-div.color-red-darker a:visited, .horiz-text-banner .banner-text .text-div.color-red-darker a:focus,
.horiz-text-banner .banner-text .text-div.color-red-darker p a:hover,
.horiz-text-banner .banner-text .text-div.color-red-darker p a:active,
.horiz-text-banner .banner-text .text-div.color-red-darker p a:visited,
.horiz-text-banner .banner-text .text-div.color-red-darker p a:focus, .horiz-text-banner .banner-text .text-div.color-red a:hover, .horiz-text-banner .banner-text .text-div.color-red a:active, .horiz-text-banner .banner-text .text-div.color-red a:visited, .horiz-text-banner .banner-text .text-div.color-red a:focus,
.horiz-text-banner .banner-text .text-div.color-red p a:hover,
.horiz-text-banner .banner-text .text-div.color-red p a:active,
.horiz-text-banner .banner-text .text-div.color-red p a:visited,
.horiz-text-banner .banner-text .text-div.color-red p a:focus, .horiz-text-banner .banner-text .text-div.color-red-orange a:hover, .horiz-text-banner .banner-text .text-div.color-red-orange a:active, .horiz-text-banner .banner-text .text-div.color-red-orange a:visited, .horiz-text-banner .banner-text .text-div.color-red-orange a:focus,
.horiz-text-banner .banner-text .text-div.color-red-orange p a:hover,
.horiz-text-banner .banner-text .text-div.color-red-orange p a:active,
.horiz-text-banner .banner-text .text-div.color-red-orange p a:visited,
.horiz-text-banner .banner-text .text-div.color-red-orange p a:focus, .horiz-text-banner .banner-text .text-div.color-gray-darker a:hover, .horiz-text-banner .banner-text .text-div.color-gray-darker a:active, .horiz-text-banner .banner-text .text-div.color-gray-darker a:visited, .horiz-text-banner .banner-text .text-div.color-gray-darker a:focus,
.horiz-text-banner .banner-text .text-div.color-gray-darker p a:hover,
.horiz-text-banner .banner-text .text-div.color-gray-darker p a:active,
.horiz-text-banner .banner-text .text-div.color-gray-darker p a:visited,
.horiz-text-banner .banner-text .text-div.color-gray-darker p a:focus, .horiz-text-banner .banner-text .text-div.color-gray-darkest a:hover, .horiz-text-banner .banner-text .text-div.color-gray-darkest a:active, .horiz-text-banner .banner-text .text-div.color-gray-darkest a:visited, .horiz-text-banner .banner-text .text-div.color-gray-darkest a:focus,
.horiz-text-banner .banner-text .text-div.color-gray-darkest p a:hover,
.horiz-text-banner .banner-text .text-div.color-gray-darkest p a:active,
.horiz-text-banner .banner-text .text-div.color-gray-darkest p a:visited,
.horiz-text-banner .banner-text .text-div.color-gray-darkest p a:focus, .horiz-text-banner .banner-text .text-div.color-teal a:hover, .horiz-text-banner .banner-text .text-div.color-teal a:active, .horiz-text-banner .banner-text .text-div.color-teal a:visited, .horiz-text-banner .banner-text .text-div.color-teal a:focus,
.horiz-text-banner .banner-text .text-div.color-teal p a:hover,
.horiz-text-banner .banner-text .text-div.color-teal p a:active,
.horiz-text-banner .banner-text .text-div.color-teal p a:visited,
.horiz-text-banner .banner-text .text-div.color-teal p a:focus {
  color: #7f0b19 !important;
  text-decoration: underline !important;
}
.horiz-text-banner .banner-text .text-div.color-white a,
.horiz-text-banner .banner-text .text-div.color-white p a, .horiz-text-banner .banner-text .text-div.color-blue-lightest a,
.horiz-text-banner .banner-text .text-div.color-blue-lightest p a, .horiz-text-banner .banner-text .text-div.color-blue-lighter a,
.horiz-text-banner .banner-text .text-div.color-blue-lighter p a, .horiz-text-banner .banner-text .text-div.color-yellow-lightest a,
.horiz-text-banner .banner-text .text-div.color-yellow-lightest p a, .horiz-text-banner .banner-text .text-div.color-yellow-lighter a,
.horiz-text-banner .banner-text .text-div.color-yellow-lighter p a, .horiz-text-banner .banner-text .text-div.color-red-lightest a,
.horiz-text-banner .banner-text .text-div.color-red-lightest p a, .horiz-text-banner .banner-text .text-div.color-gray-lightest a,
.horiz-text-banner .banner-text .text-div.color-gray-lightest p a, .horiz-text-banner .banner-text .text-div.color-gray-lighter a,
.horiz-text-banner .banner-text .text-div.color-gray-lighter p a {
  color: #FFEAA8 !important;
  text-decoration: underline !important;
}
.horiz-text-banner .banner-text .text-div.color-white a:hover, .horiz-text-banner .banner-text .text-div.color-white a:active, .horiz-text-banner .banner-text .text-div.color-white a:visited, .horiz-text-banner .banner-text .text-div.color-white a:focus,
.horiz-text-banner .banner-text .text-div.color-white p a:hover,
.horiz-text-banner .banner-text .text-div.color-white p a:active,
.horiz-text-banner .banner-text .text-div.color-white p a:visited,
.horiz-text-banner .banner-text .text-div.color-white p a:focus, .horiz-text-banner .banner-text .text-div.color-blue-lightest a:hover, .horiz-text-banner .banner-text .text-div.color-blue-lightest a:active, .horiz-text-banner .banner-text .text-div.color-blue-lightest a:visited, .horiz-text-banner .banner-text .text-div.color-blue-lightest a:focus,
.horiz-text-banner .banner-text .text-div.color-blue-lightest p a:hover,
.horiz-text-banner .banner-text .text-div.color-blue-lightest p a:active,
.horiz-text-banner .banner-text .text-div.color-blue-lightest p a:visited,
.horiz-text-banner .banner-text .text-div.color-blue-lightest p a:focus, .horiz-text-banner .banner-text .text-div.color-blue-lighter a:hover, .horiz-text-banner .banner-text .text-div.color-blue-lighter a:active, .horiz-text-banner .banner-text .text-div.color-blue-lighter a:visited, .horiz-text-banner .banner-text .text-div.color-blue-lighter a:focus,
.horiz-text-banner .banner-text .text-div.color-blue-lighter p a:hover,
.horiz-text-banner .banner-text .text-div.color-blue-lighter p a:active,
.horiz-text-banner .banner-text .text-div.color-blue-lighter p a:visited,
.horiz-text-banner .banner-text .text-div.color-blue-lighter p a:focus, .horiz-text-banner .banner-text .text-div.color-yellow-lightest a:hover, .horiz-text-banner .banner-text .text-div.color-yellow-lightest a:active, .horiz-text-banner .banner-text .text-div.color-yellow-lightest a:visited, .horiz-text-banner .banner-text .text-div.color-yellow-lightest a:focus,
.horiz-text-banner .banner-text .text-div.color-yellow-lightest p a:hover,
.horiz-text-banner .banner-text .text-div.color-yellow-lightest p a:active,
.horiz-text-banner .banner-text .text-div.color-yellow-lightest p a:visited,
.horiz-text-banner .banner-text .text-div.color-yellow-lightest p a:focus, .horiz-text-banner .banner-text .text-div.color-yellow-lighter a:hover, .horiz-text-banner .banner-text .text-div.color-yellow-lighter a:active, .horiz-text-banner .banner-text .text-div.color-yellow-lighter a:visited, .horiz-text-banner .banner-text .text-div.color-yellow-lighter a:focus,
.horiz-text-banner .banner-text .text-div.color-yellow-lighter p a:hover,
.horiz-text-banner .banner-text .text-div.color-yellow-lighter p a:active,
.horiz-text-banner .banner-text .text-div.color-yellow-lighter p a:visited,
.horiz-text-banner .banner-text .text-div.color-yellow-lighter p a:focus, .horiz-text-banner .banner-text .text-div.color-red-lightest a:hover, .horiz-text-banner .banner-text .text-div.color-red-lightest a:active, .horiz-text-banner .banner-text .text-div.color-red-lightest a:visited, .horiz-text-banner .banner-text .text-div.color-red-lightest a:focus,
.horiz-text-banner .banner-text .text-div.color-red-lightest p a:hover,
.horiz-text-banner .banner-text .text-div.color-red-lightest p a:active,
.horiz-text-banner .banner-text .text-div.color-red-lightest p a:visited,
.horiz-text-banner .banner-text .text-div.color-red-lightest p a:focus, .horiz-text-banner .banner-text .text-div.color-gray-lightest a:hover, .horiz-text-banner .banner-text .text-div.color-gray-lightest a:active, .horiz-text-banner .banner-text .text-div.color-gray-lightest a:visited, .horiz-text-banner .banner-text .text-div.color-gray-lightest a:focus,
.horiz-text-banner .banner-text .text-div.color-gray-lightest p a:hover,
.horiz-text-banner .banner-text .text-div.color-gray-lightest p a:active,
.horiz-text-banner .banner-text .text-div.color-gray-lightest p a:visited,
.horiz-text-banner .banner-text .text-div.color-gray-lightest p a:focus, .horiz-text-banner .banner-text .text-div.color-gray-lighter a:hover, .horiz-text-banner .banner-text .text-div.color-gray-lighter a:active, .horiz-text-banner .banner-text .text-div.color-gray-lighter a:visited, .horiz-text-banner .banner-text .text-div.color-gray-lighter a:focus,
.horiz-text-banner .banner-text .text-div.color-gray-lighter p a:hover,
.horiz-text-banner .banner-text .text-div.color-gray-lighter p a:active,
.horiz-text-banner .banner-text .text-div.color-gray-lighter p a:visited,
.horiz-text-banner .banner-text .text-div.color-gray-lighter p a:focus {
  color: #f8efd4 !important;
  text-decoration: underline !important;
}
.horiz-text-banner .banner-text .text-div.color-mint a,
.horiz-text-banner .banner-text .text-div.color-mint p a, .horiz-text-banner .banner-text .text-div.color-blue a,
.horiz-text-banner .banner-text .text-div.color-blue p a, .horiz-text-banner .banner-text .text-div.color-blue2 a,
.horiz-text-banner .banner-text .text-div.color-blue2 p a, .horiz-text-banner .banner-text .text-div.color-gray a,
.horiz-text-banner .banner-text .text-div.color-gray p a {
  color: #f8efd4 !important;
  text-decoration: underline !important;
}
.horiz-text-banner .banner-text .text-div.color-mint a:hover, .horiz-text-banner .banner-text .text-div.color-mint a:active, .horiz-text-banner .banner-text .text-div.color-mint a:visited, .horiz-text-banner .banner-text .text-div.color-mint a:focus,
.horiz-text-banner .banner-text .text-div.color-mint p a:hover,
.horiz-text-banner .banner-text .text-div.color-mint p a:active,
.horiz-text-banner .banner-text .text-div.color-mint p a:visited,
.horiz-text-banner .banner-text .text-div.color-mint p a:focus, .horiz-text-banner .banner-text .text-div.color-blue a:hover, .horiz-text-banner .banner-text .text-div.color-blue a:active, .horiz-text-banner .banner-text .text-div.color-blue a:visited, .horiz-text-banner .banner-text .text-div.color-blue a:focus,
.horiz-text-banner .banner-text .text-div.color-blue p a:hover,
.horiz-text-banner .banner-text .text-div.color-blue p a:active,
.horiz-text-banner .banner-text .text-div.color-blue p a:visited,
.horiz-text-banner .banner-text .text-div.color-blue p a:focus, .horiz-text-banner .banner-text .text-div.color-blue2 a:hover, .horiz-text-banner .banner-text .text-div.color-blue2 a:active, .horiz-text-banner .banner-text .text-div.color-blue2 a:visited, .horiz-text-banner .banner-text .text-div.color-blue2 a:focus,
.horiz-text-banner .banner-text .text-div.color-blue2 p a:hover,
.horiz-text-banner .banner-text .text-div.color-blue2 p a:active,
.horiz-text-banner .banner-text .text-div.color-blue2 p a:visited,
.horiz-text-banner .banner-text .text-div.color-blue2 p a:focus, .horiz-text-banner .banner-text .text-div.color-gray a:hover, .horiz-text-banner .banner-text .text-div.color-gray a:active, .horiz-text-banner .banner-text .text-div.color-gray a:visited, .horiz-text-banner .banner-text .text-div.color-gray a:focus,
.horiz-text-banner .banner-text .text-div.color-gray p a:hover,
.horiz-text-banner .banner-text .text-div.color-gray p a:active,
.horiz-text-banner .banner-text .text-div.color-gray p a:visited,
.horiz-text-banner .banner-text .text-div.color-gray p a:focus {
  color: #fcfcfc !important;
  text-decoration: underline !important;
}
.horiz-text-banner .banner-text .text-div.color-yellow a,
.horiz-text-banner .banner-text .text-div.color-yellow p a {
  color: #111111 !important;
  text-decoration: underline !important;
}
.horiz-text-banner .banner-text .text-div.color-yellow a:hover, .horiz-text-banner .banner-text .text-div.color-yellow a:active, .horiz-text-banner .banner-text .text-div.color-yellow a:visited, .horiz-text-banner .banner-text .text-div.color-yellow a:focus,
.horiz-text-banner .banner-text .text-div.color-yellow p a:hover,
.horiz-text-banner .banner-text .text-div.color-yellow p a:active,
.horiz-text-banner .banner-text .text-div.color-yellow p a:visited,
.horiz-text-banner .banner-text .text-div.color-yellow p a:focus {
  color: #26366a !important;
  text-decoration: underline !important;
}
.horiz-text-banner .banner-text button {
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 992px) {
  .three-button-banner {
    padding-top: 2rem !important;
    padding-bottom: 1.4rem !important;
    min-height: 260px;
  }
}
.three-button-banner h1, .three-button-banner .h1,
.three-button-banner h2,
.three-button-banner .h2,
.three-button-banner h3,
.three-button-banner .h3 {
  font-size: 3rem;
}
@media (min-width: 1200px) {
  .three-button-banner h1, .three-button-banner .h1,
  .three-button-banner h2,
  .three-button-banner .h2,
  .three-button-banner h3,
  .three-button-banner .h3 {
    font-size: 4vw;
  }
}
.three-button-banner hr {
  margin-left: auto;
  margin-right: auto;
  border-top: 3px solid;
}
@media (max-width: 575.98px) {
  .three-button-banner hr {
    width: 70%;
    margin-bottom: 0;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .three-button-banner hr {
    width: 60%;
    margin-bottom: 10px;
  }
}
@media (min-width: 992px) {
  .three-button-banner hr {
    width: 50%;
  }
}
@media (max-width: 575.98px) {
  .three-button-banner hr.last {
    margin-top: -0.2rem;
    margin-bottom: 16px;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .three-button-banner hr.last {
    margin-bottom: 24px;
    margin-top: 12px !important;
  }
}
@media (min-width: 992px) {
  .three-button-banner hr.last {
    margin-top: 1rem;
  }
}
@media (min-width: 992px) {
  .three-button-banner .button-grid {
    margin-top: 0.5rem;
    padding-bottom: 0.8rem;
  }
}
@media (max-width: 575.98px) {
  .three-button-banner .button-grid .banner-button {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .banner-lg-headline {
    padding: 2rem 6vw !important;
  }
}
.banner-lg-headline h1, .banner-lg-headline .h1,
.banner-lg-headline h2,
.banner-lg-headline .h2,
.banner-lg-headline h3,
.banner-lg-headline .h3 {
  font-size: 3.8rem;
}
@media (min-width: 1200px) {
  .banner-lg-headline h1, .banner-lg-headline .h1,
  .banner-lg-headline h2,
  .banner-lg-headline .h2,
  .banner-lg-headline h3,
  .banner-lg-headline .h3 {
    font-size: 4vw;
  }
}

.cta-box,
.cta-box-mobile,
.cta-box-side,
.cta-box-alpha,
.cta-box-grey,
.cta-box-yelo {
  font-size: 0.9rem;
  border: 2px solid #bbbbbb;
  padding: 10px;
  display: block;
  margin: 5px 5px 15px 5px;
}

.cta-box-grey {
  background: #E7E7E8;
  margin-bottom: 35px;
}

.cta-box-yelo {
  background: #f8efd4;
}

.cta-box-alpha {
  background: #fcfcfc;
  background-repeat: no-repeat;
  background-position: left top;
}
.cta-box-alpha p {
  margin-left: 44px;
  font-size: 1rem;
  margin-top: 0.5rem;
}

.dark-blue-background {
  background: #26366a;
  min-height: 175px;
}

.light-blue-background {
  background: #cfdded;
  min-height: 175px;
}

.left-box {
  float: left;
  padding-right: 25px;
}

@media (max-width: 767.98px) {
  .breadcrumbs-left-nav {
    padding: 0.2rem 0px 0.25rem 0.4rem !important;
  }
}
@media (min-width: 992px) {
  .breadcrumbs-left-nav {
    padding: 1.4rem 0px 0.4rem 0.4rem !important;
  }
}

.left-nav-mobile-bar {
  margin-bottom: 0.8rem;
}

.section-dropdown button:active, .section-dropdown button:hover, .section-dropdown button:focus {
  box-shadow: none !important;
}

.left-nav-button,
.section-dropdown .dropdown-toggle {
  color: #fcfcfc !important;
  background: #9c1212 !important;
  font-weight: 600 !important;
  font-variation-settings: "wght" 600;
  border: 0;
  padding: 0;
  margin-bottom: 0.6rem;
  margin-top: 0.4rem !important;
}
.left-nav-button:hover, .left-nav-button:focus, .left-nav-button:active,
.section-dropdown .dropdown-toggle:hover,
.section-dropdown .dropdown-toggle:focus,
.section-dropdown .dropdown-toggle:active {
  padding: 0;
  color: #f8efd4;
  -webkit-box-shadow: 0 !important;
  box-shadow: 0 !important;
  outline: 0 !important;
  border: 0 !important;
}
.left-nav-button::after,
.section-dropdown .dropdown-toggle::after {
  content: "" !important;
  color: #9c1212;
}
.left-nav-button .button-text:hover, .left-nav-button .button-text:focus, .left-nav-button .button-text:active,
.section-dropdown .dropdown-toggle .button-text:hover,
.section-dropdown .dropdown-toggle .button-text:focus,
.section-dropdown .dropdown-toggle .button-text:active {
  color: #f8efd4 !important;
}
.left-nav-button .fa,
.section-dropdown .dropdown-toggle .fa {
  font-size: 2rem;
  margin-bottom: -7px;
  padding-left: 0.15rem;
  vertical-align: text-bottom;
  color: #FFEAA8;
}

.section-dropdown-menu {
  width: 82vw;
  border-radius: 0 !important;
  margin: 0.5rem 0 0 0px !important;
  padding: 0.5rem 0 0.5rem 1rem !important;
  background: #e8f3ff;
  -webkit-box-shadow: -21px 10px 19px -8px rgba(163, 163, 163, 0.4);
  -moz-box-shadow: -21px 10px 19px -8px rgba(163, 163, 163, 0.4);
  box-shadow: -21px 10px 19px -8px rgba(163, 163, 163, 0.4);
}
.section-dropdown-menu li.dropdown-item {
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: 100%;
  border-bottom: 1px solid #fcfcfc !important;
}
.section-dropdown-menu li.dropdown-item:active, .section-dropdown-menu li.dropdown-item:hover, .section-dropdown-menu li.dropdown-item:focus {
  background: #cfdded !important;
}
.section-dropdown-menu li.dropdown-item:last-child {
  border-bottom: 0 !important;
}
.section-dropdown-menu li.dropdown-item:last-child a {
  border-bottom: 0 !important;
}
.section-dropdown-menu li.dropdown-item a {
  padding: 0.2rem 0.8rem !important;
  width: 100%;
  border-bottom: 1px solid #fcfcfc;
  text-decoration: none;
  font-size: 0.9rem;
  color: #26366a;
}
.section-dropdown-menu li.dropdown-item a:hover, .section-dropdown-menu li.dropdown-item a:focus {
  color: #111111 !important;
  background: #cfdded !important;
}

.section-dropdown-menu a:last-of-type {
  border-bottom: 0 !important;
}

@media (max-width: 767.98px) {
  .offcanvas-start {
    z-index: 10000 !important;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .side-menu {
    width: 24vw;
    padding-left: 8vw;
    padding-right: 1rem;
    float: left;
    margin-top: 2.4rem;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .side-menu {
    width: 16vw;
    padding-left: 5vw;
    padding-right: 1rem;
    float: left;
    margin-top: 2.4rem;
  }
}
@media (min-width: 1400px) {
  .side-menu {
    width: 14vw;
    padding-left: 3vw;
    padding-right: 1rem;
    float: left;
    margin-top: 2.4rem;
  }
}
@media screen and (min-width: 2360px) {
  .side-menu {
    margin-left: -10vw !important;
  }
}

@media (min-width: 992px) {
  .offcanvas-header {
    display: block !important;
    padding: 0 !important;
  }
}

.main-text {
  min-height: 300px;
}
@media (max-width: 767.98px) {
  .main-text {
    padding-left: 8vw;
    padding-right: 8vw;
  }
}
@media (min-width: 992px) {
  .main-text {
    margin-left: 25vw;
    margin-right: 10vw;
    margin-top: 1rem;
    max-width: 1000px;
  }
}
.main-text .breadcrumbs {
  line-height: 1.4;
}
@media (max-width: 767.98px) {
  .main-text .breadcrumbs {
    margin-left: -2rem !important;
  }
}
@media (min-width: 992px) {
  .main-text .breadcrumbs {
    padding: 1rem 0 0.4rem 0rem !important;
    margin-bottom: 0.8rem;
  }
}
.main-text .breadcrumbs ul {
  margin-left: -1.4rem;
}

.left-nav-header {
  margin: 0;
  padding: 0;
  background: #26366a;
}
@media (max-width: 767.98px) {
  .left-nav-header {
    margin-top: -1rem;
    padding: 2rem 0 0 0;
  }
  .left-nav-header .close-check {
    margin-left: auto;
    background: transparent;
    color: #fcfcfc;
    width: 1rem;
    margin-top: -0.3rem;
    font-size: 1.2rem;
    padding-right: 3.2rem;
    float: right;
  }
}
@media (min-width: 992px) {
  .left-nav-header {
    width: 15vw;
    border: ipx solid #26366a;
  }
}
.left-nav-header h2, .left-nav-header .h2,
.left-nav-header .sidenav-header-text {
  color: #fcfcfc;
  width: 100%;
  font-weight: 500;
  font-variation-settings: "wght" 500;
  letter-spacing: 0.05rem;
  font-family: "Oswald", "HeaderFallBackMac", "HeaderFallBackWin", sans-serif !important;
}
@media (max-width: 767.98px) {
  .left-nav-header h2, .left-nav-header .h2,
  .left-nav-header .sidenav-header-text {
    padding-top: 0.5rem;
    padding-left: 8vw;
    padding-bottom: 0.5rem;
    line-height: 1.5rem;
    margin: 0 auto -1.2rem 0;
    font-size: 1.4rem;
    letter-spacing: 0.05rem;
  }
}
@media (min-width: 992px) {
  .left-nav-header h2, .left-nav-header .h2,
  .left-nav-header .sidenav-header-text {
    font-size: 1.2rem;
    padding: 0.5em;
  }
}
.left-nav-header h2 a, .left-nav-header .h2 a,
.left-nav-header .sidenav-header-text a {
  background: #26366a;
  padding: 0.5em;
  display: block;
  color: #fcfcfc;
  border: 1px solid #0a1535;
  font-size: 1rem;
  font-weight: bold;
  font-variation-settings: "wght" 600;
  color: #fcfcfc;
}
.left-nav-header h2 a button, .left-nav-header .h2 a button,
.left-nav-header .sidenav-header-text a button {
  background: none !important;
  border-radius: 0;
  cursor: pointer;
  font-size: 1.5rem;
  padding: 0 !important;
  text-align: right;
  float: right !important;
  margin-right: 3.2rem;
  margin-left: auto;
}
.left-nav-header h2 a button i, .left-nav-header .h2 a button i,
.left-nav-header .sidenav-header-text a button i {
  color: #fcfcfc;
  font-size: 1.5rem;
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .left-nav-header h2, .left-nav-header .h2,
  .left-nav-header .sidenav-header-text {
    margin: 12px 0 0 0;
  }
}

.left-nav-body {
  border-left: 1px solid #fcfcfc;
  border-right: 1px solid #fcfcfc;
  padding: 1rem 4vw 0;
  list-style-type: none;
  list-style-position: inside;
  font-family: "Roboto", sans-serif;
}
@media (max-width: 767.98px) {
  .left-nav-body {
    padding: 1rem 4vw 0;
  }
}
@media (min-width: 992px) {
  .left-nav-body {
    padding: 0;
    width: 15vw;
    margin-top: 0 !important;
  }
}
.left-nav-body ul {
  list-style-type: none;
  margin-left: 0;
  padding-left: 0;
  margin-top: 0;
  border: 0;
}
@media (max-width: 767.98px) {
  .left-nav-body ul {
    margin-top: 0;
  }
}
@media (min-width: 992px) {
  .left-nav-body ul {
    margin-top: 0 !important;
    width: 15vw;
    border: 1px solid #E7E7E8;
    padding-top: 0 !important;
  }
}
.left-nav-body ul li.nav-item {
  margin: 0;
  font-weight: 600;
  font-variation-settings: "wght" 600;
}
@media (max-width: 767.98px) {
  .left-nav-body ul li.nav-item {
    font-size: 1.2rem;
    border-bottom: 1px solid #E7E7E8;
  }
}
@media (min-width: 992px) {
  .left-nav-body ul li.nav-item {
    font-size: 0.875rem;
    border-top: 1px solid #E7E7E8;
  }
}
.left-nav-body ul li.nav-item:active, .left-nav-body ul li.nav-item:hover, .left-nav-body ul li.nav-item:focus {
  background: #E7E7E8;
}
.left-nav-body ul li.nav-item:first-child {
  border-top: 0;
}
@media (max-width: 767.98px) {
  .left-nav-body ul li.nav-item:first-child {
    padding-top: 0.3rem;
  }
}
@media (min-width: 992px) {
  .left-nav-body ul li.nav-item:first-child {
    padding-top: 0.4rem;
  }
}
.left-nav-body ul li.nav-item .selected:after {
  font-family: FontAwesome;
  content: "\f04b";
  margin-right: 1em;
  float: right;
}
.left-nav-body ul li.nav-item a,
.left-nav-body ul li.nav-item a.nav-link {
  text-decoration: none;
  border-bottom: 1px solid #fcfcfc;
  margin: 0;
}
@media (max-width: 767.98px) {
  .left-nav-body ul li.nav-item a,
  .left-nav-body ul li.nav-item a.nav-link {
    padding: 0.5em 0.75em;
  }
}
@media (min-width: 992px) {
  .left-nav-body ul li.nav-item a,
  .left-nav-body ul li.nav-item a.nav-link {
    padding: 0.6rem;
  }
}
.left-nav-body ul li.nav-item a:active, .left-nav-body ul li.nav-item a:hover, .left-nav-body ul li.nav-item a:focus,
.left-nav-body ul li.nav-item a.nav-link:active,
.left-nav-body ul li.nav-item a.nav-link:hover,
.left-nav-body ul li.nav-item a.nav-link:focus {
  color: #9c1212;
  border-bottom: 1px dotted #bb322d;
  font-weight: bold;
  font-variation-settings: "wght" 600;
}
.left-nav-body ul li.nav-item a:after,
.left-nav-body ul li.nav-item a.nav-link:after {
  font-family: FontAwesome;
  content: "\f04b";
  float: right;
}
.left-nav-body ul li.nav-item a:not(.button) {
  display: block;
  color: #111111;
}
.left-nav-body ul li.nav-item a:not(.button):active, .left-nav-body ul li.nav-item a:not(.button):hover, .left-nav-body ul li.nav-item a:not(.button):focus {
  background: #E7E7E8;
  font-weight: normal;
  border-bottom: 0px;
}
.left-nav-body span[class^=currentbranch] a {
  background: #fcfcfc;
}

.language-disclaimer {
  padding-left: 10%;
  padding-right: 10%;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
}
.language-disclaimer .row {
  background: #cfdded;
  padding: 10px 20px;
}
.language-disclaimer .row h1, .language-disclaimer .row .h1,
.language-disclaimer .row h2,
.language-disclaimer .row .h2,
.language-disclaimer .row h3,
.language-disclaimer .row .h3,
.language-disclaimer .row h4,
.language-disclaimer .row .h4,
.language-disclaimer .row h5,
.language-disclaimer .row .h5,
.language-disclaimer .row .disclaimer-header {
  text-align: center;
  color: #505050;
  font-family: "Oswald", "HeaderFallBackMac", "HeaderFallBackWin", sans-serif;
  font-weight: bold;
  font-variation-settings: "wght" 600;
  font-size: 1.25rem;
  line-height: 1.4;
  margin-bottom: 0.5rem;
  margin-top: 0;
  width: 100%;
}
.language-disclaimer .row p a {
  color: #26366a;
}

/* Footer */
.footercontainer {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 2000px;
  background: #202020;
  padding: 20px 0;
}

footer {
  color: #fcfcfc;
  margin: 1px auto;
  font-size: 13px;
}
footer a {
  color: #fcfcfc !important;
  font-size: 14px;
  font-weight: 400;
  font-variation-settings: "wght" 400;
  border-bottom: 1px solid #a5beda;
  text-decoration: none;
}
footer a:visited {
  color: #fcfcfc;
}
footer a:hover {
  color: #e8f3ff !important;
  border-bottom: 1px dotted #e8f3ff;
  font-weight: 600;
  font-variation-settings: "wght" 600;
}
footer li {
  padding-bottom: 0.5rem;
}
@media (max-width: 575.98px) {
  footer li {
    padding-top: 0.2rem;
  }
}
footer li a {
  color: #fcfcfc !important;
  font-weight: 400;
  font-variation-settings: "wght" 400;
  border-bottom: 1px solid #a5beda;
  text-decoration: none;
}
footer li a:visited {
  color: #fcfcfc;
}
footer li a:hover {
  color: #e8f3ff !important;
  border-bottom: 1px dotted #e8f3ff;
  font-weight: 600;
  font-variation-settings: "wght" 600;
}
footer .footerfeature_info {
  padding: 10px 30px;
}
footer .footerfeature_info h3, footer .footerfeature_info .h3,
footer .footerfeature_info .footer-header {
  color: #cfdded;
  font-size: 19px !important;
  padding-bottom: 10px !important;
  margin: 5px auto 2px 0 !important;
  line-height: 1.5 !important;
  font-weight: 600;
  font-variation-settings: "wght" 600;
  font-family: "Oswald", "HeaderFallBackMac", "HeaderFallBackWin", sans-serif;
}
footer .footerfeature_info p {
  color: #fcfcfc;
  font-weight: 600 !important;
  font-variation-settings: "wght" 600;
  padding-bottom: 10px !important;
  margin: 5px auto 2px 0 !important;
  line-height: 18px !important;
}
footer .footerfeature_info ul {
  margin-left: 10px;
  list-style: none;
}
@media screen and (min-width: 64.063em) and (max-width: 90em) {
  footer .footerfeature-left {
    margin-left: -50px;
  }
  footer .subfooter {
    margin-bottom: 0;
  }
}
@media only screen and (min-width: 64.063em) {
  footer .footerfeature-left {
    margin-left: 0;
  }
  footer .footerfeature-info {
    padding: 10px 20px !important;
  }
}
footer .subfooter {
  color: #fcfcfc;
  text-align: center;
  margin-bottom: 15px;
  display: inline-block;
}
footer .subfooter p {
  margin-bottom: 0px !important;
  font-size: 13px !important;
}
footer .subfooter a:link {
  color: #e8f3ff !important;
  border-bottom: 1px dotted #e8f3ff;
}
footer .subfooter a:visited {
  color: #e8f3ff !important;
}
footer .subfooter a:hover {
  color: #faafa7 !important;
  font-weight: bold;
  font-variation-settings: "wght" 600;
  border-bottom: 1px dotted #faafa7;
}
footer .subfooter a:active, footer .subfooter a:focus {
  color: #E7E7E8 !important;
  font-weight: bold;
  font-variation-settings: "wght" 600;
  border-bottom: 1px dotted #E7E7E8;
}
footer .socialmedia {
  list-style-type: none;
}
@media (max-width: 767.98px) {
  footer .socialmedia {
    margin-left: 2vw;
    margin-right: auto;
    padding-left: 0;
  }
}
footer .socialmedia li {
  background-image: url("/assets/img/socialsprite.png");
  background-repeat: no-repeat;
  display: inline-block;
  width: 45px;
  height: 45px;
}
@media (max-width: 767.98px) {
  footer .socialmedia li {
    margin: 10px 5px 5px 10px;
  }
}
@media (min-width: 992px) {
  footer .socialmedia li {
    margin: 10px 5px 5px 10px;
  }
}
footer .socialmedia li a {
  display: block;
  height: 100%;
  width: 100%;
  text-indent: -9999px;
  overflow: hidden;
  border-bottom: none !important;
}
footer .socialmedia li a:hover {
  border-bottom: 0px;
}
footer h3.social-header, footer .social-header.h3 {
  margin-left: 24px !important;
}
footer .facebook {
  background-position: 1px 3px;
  transition: all 0.3s ease-in-out;
  text-decoration: none !important;
}
footer .facebook:hover {
  background-position: 1px -42px;
}
footer .twitter {
  background-position: -44px 3px;
  transition: all 0.3s ease-in-out;
  text-decoration: none !important;
}
footer .twitter:hover {
  background-position: -44px -42px;
}
footer .youtube {
  background-position: -87px 3px;
  transition: all 0.3s ease-in-out;
  text-decoration: none !important;
}
footer .youtube:hover {
  background-position: -87px -42px;
}
footer .linkedin {
  background-position: -133px 3px;
  transition: all 0.3s ease-in-out;
  text-decoration: none !important;
}
footer .linkedin:hover {
  background-position: -133px -42px;
}
footer .instagram {
  background-position: -226px 3px;
  transition: all 0.3s ease-in-out;
  text-decoration: none !important;
}
footer .instagram:hover {
  background-position: -226px -42px;
}
@media (max-width: 575.98px) {
  footer .omegalogo {
    display: none;
  }
}
footer .omegalogo img {
  vertical-align: text-top;
  max-width: 150px;
  aspect-ratio: 1/1;
}
@media (min-width: 768px) {
  footer .omegalogo img {
    float: right;
    margin-right: 30%;
    margin-left: auto;
    margin-top: -100px;
  }
}

.feature-icon-section {
  padding: 1rem;
  width: 100%;
  margin-bottom: 1.2rem;
}
@media screen and (min-width: 90em) {
  .feature-icon-section {
    padding: 0.8rem 1.5rem 1rem !important;
  }
}
.feature-icon-section .align-left {
  text-align: left;
}
.feature-icon-section .align-right {
  text-align: right;
}
.feature-icon-section .align-center {
  text-align: center;
}
.feature-icon-section h1, .feature-icon-section .h1,
.feature-icon-section h2 h3,
.feature-icon-section .h2 h3,
.feature-icon-section h2 .h3,
.feature-icon-section .h2 .h3 {
  width: 100%;
  font-weight: 600 !important;
  font-variation-settings: "wght" 600;
}
@media (max-width: 767.98px) {
  .feature-icon-section h1, .feature-icon-section .h1,
  .feature-icon-section h2 h3,
  .feature-icon-section .h2 h3,
  .feature-icon-section h2 .h3,
  .feature-icon-section .h2 .h3 {
    font-size: 1.4rem;
    padding-bottom: 0.3rem;
    margin-bottom: 1rem !important;
  }
}
@media (min-width: 992px) {
  .feature-icon-section h1, .feature-icon-section .h1,
  .feature-icon-section h2 h3,
  .feature-icon-section .h2 h3,
  .feature-icon-section h2 .h3,
  .feature-icon-section .h2 .h3 {
    margin-bottom: 0 !important;
  }
}
.feature-icon-section a {
  width: 100%;
}
.feature-icon-section .feature-icon-cell {
  text-align: center;
}
@media (max-width: 767.98px) {
  .feature-icon-section .feature-icon-cell {
    padding: 0;
    margin-bottom: 0.7rem !important;
  }
}
@media (min-width: 992px) {
  .feature-icon-section .feature-icon-cell {
    padding: 0.5rem !important;
    margin: 0.5rem !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}
@media screen and (min-device-width: 768px) and (max-device-width: 1099px) and (orientation: portrait) {
  .feature-icon-section .feature-icon-cell {
    padding: 1vw;
    text-align: center !important;
  }
}
@media screen and (min-device-width: 768px) and (max-device-width: 1099px) and (orientation: landscape) {
  .feature-icon-section .feature-icon-cell {
    padding: 2vw;
    text-align: center !important;
  }
}
.feature-icon-section .feature-icon-cell a {
  text-decoration: none !important;
}
.feature-icon-section .feature-icon-cell a:active, .feature-icon-section .feature-icon-cell a:hover, .feature-icon-section .feature-icon-cell a:focus {
  text-decoration: underline;
  color: #26366a;
}
@media (max-width: 767.98px) {
  .feature-icon-section .feature-icon-cell .icon-image {
    padding: 14px 0 14px 4px !important;
    display: flex;
  }
}
@media (min-width: 992px) {
  .feature-icon-section .feature-icon-cell .icon-image {
    padding: 0.3rem 0 0;
    margin: 0;
  }
}
.feature-icon-section .feature-icon-cell img,
.feature-icon-section .feature-icon-cell svg {
  height: auto;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
@media (max-width: 767.98px) {
  .feature-icon-section .feature-icon-cell img,
  .feature-icon-section .feature-icon-cell svg {
    margin: -4px 6px 0 0;
    padding: 3px 12px 0 12px;
  }
}
@media (min-width: 992px) {
  .feature-icon-section .feature-icon-cell img,
  .feature-icon-section .feature-icon-cell svg {
    width: auto;
    height: 80px;
    margin: 0.5rem auto;
  }
}
.feature-icon-section .feature-icon-cell img:active, .feature-icon-section .feature-icon-cell img:hover, .feature-icon-section .feature-icon-cell img:focus,
.feature-icon-section .feature-icon-cell svg:active,
.feature-icon-section .feature-icon-cell svg:hover,
.feature-icon-section .feature-icon-cell svg:focus {
  filter: blur(1px) brightness(80%) contrast(60%) invert(29%) opacity(77%) saturate(47%);
}
.feature-icon-section .feature-icon-cell i {
  font-size: 6rem;
  color: inherit;
  text-align: center;
}
@media (max-width: 767.98px) {
  .feature-icon-section .feature-icon-cell i {
    font-size: 6rem;
    justify-self: center;
    align-self: center;
  }
}
@media (min-width: 992px) {
  .feature-icon-section .feature-icon-cell i {
    font-size: 7rem;
    padding-bottom: 2.5rem;
  }
}
.feature-icon-section .feature-icon-cell i:active, .feature-icon-section .feature-icon-cell i:hover, .feature-icon-section .feature-icon-cell i:focus {
  filter: blur(1px) brightness(80%) contrast(60%) invert(29%) opacity(77%) saturate(47%);
}
.feature-icon-section .feature-icon-cell h4, .feature-icon-section .feature-icon-cell .h4,
.feature-icon-section .feature-icon-cell .icon-title {
  width: 100%;
  font-weight: 600;
  font-variation-settings: "wght" 600;
}
@media (max-width: 767.98px) {
  .feature-icon-section .feature-icon-cell h4, .feature-icon-section .feature-icon-cell .h4,
  .feature-icon-section .feature-icon-cell .icon-title {
    text-align: left;
    font-size: 1.2rem;
    padding-top: 0.5rem;
    padding-bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    padding-left: 10px;
    padding-right: 6px;
  }
}
@media (min-width: 992px) {
  .feature-icon-section .feature-icon-cell h4, .feature-icon-section .feature-icon-cell .h4,
  .feature-icon-section .feature-icon-cell .icon-title {
    text-align: center;
    font-size: 1rem;
    padding-top: 0;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
@media screen and (min-device-width: 768px) and (max-device-width: 1099px) and (orientation: portrait) {
  .feature-icon-section .feature-icon-cell h4, .feature-icon-section .feature-icon-cell .h4,
  .feature-icon-section .feature-icon-cell .icon-title {
    margin-top: -1.5rem;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
  }
}
@media screen and (min-device-width: 768px) and (max-device-width: 1099px) and (orientation: landscape) {
  .feature-icon-section .feature-icon-cell h4, .feature-icon-section .feature-icon-cell .h4,
  .feature-icon-section .feature-icon-cell .icon-title {
    margin-top: -1.5rem;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
  }
}
.feature-icon-section .feature-icon-cell .icon-title {
  font-family: "Oswald", "HeaderFallBackMac", "HeaderFallBackWin", sans-serif;
  font-weight: 500;
  font-variation-settings: "wght" 500;
  text-align: center;
  color: #26366a !important;
  text-decoration: none !important;
}
.feature-icon-section .feature-icon-cell .icon-title:active, .feature-icon-section .feature-icon-cell .icon-title:hover, .feature-icon-section .feature-icon-cell .icon-title:focus {
  text-decoration: underline;
  color: #3d5193;
}
@media (max-width: 767.98px) {
  .feature-icon-section .feature-icon-cell .icon-text {
    padding-top: 16px;
  }
}
@media screen and (min-device-width: 810px) and (max-device-width: 1099px) and (orientation: portrait) {
  .feature-icon-section .feature-icon-cell .icon-text {
    padding-left: 6px !important;
  }
}
@media screen and (min-device-width: 810px) and (max-device-width: 1099px) and (orientation: landscape) {
  .feature-icon-section .feature-icon-cell .icon-text {
    padding-left: 6px !important;
  }
}
@media (min-width: 992px) {
  .feature-icon-section .feature-icon-cell .icon-text {
    padding-bottom: 0.5rem;
  }
}
.feature-icon-section .feature-icon-cell .text-div {
  text-align: left;
}
@media (max-width: 767.98px) {
  .feature-icon-section .feature-icon-cell .text-div {
    padding-right: 10px;
  }
}
.feature-icon-section .feature-icon-cell .text-div p {
  line-height: 1.2;
}
@media (max-width: 767.98px) {
  .feature-icon-section .feature-icon-cell .text-div p {
    font-size: 0.8rem;
    padding-left: 10px;
    padding-right: 6px;
  }
}
@media (min-width: 992px) {
  .feature-icon-section .feature-icon-cell .text-div p {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    font-size: 0.8rem;
  }
}
@media screen and (min-device-width: 810px) and (max-device-width: 1099px) and (orientation: portrait) {
  .feature-icon-section .feature-icon-cell .text-div p {
    width: 100% !important;
  }
}
@media screen and (min-device-width: 810px) and (max-device-width: 1099px) and (orientation: landscape) {
  .feature-icon-section .feature-icon-cell .text-div p {
    width: 100% !important;
  }
}
.feature-icon-section .feature-icon-cell hr.feature-icon-hr {
  border-top: 1px #bbbbbb solid;
  margin-right: 4vw;
  margin-left: 4vw;
  margin-top: 0;
}
@media (max-width: 767.98px) {
  .feature-icon-section .feature-icon-cell hr.feature-icon-hr {
    margin-top: -10px;
  }
}
@media (min-width: 992px) {
  .feature-icon-section col-md-4 i {
    font-size: 8rem;
  }
}

.rounded-shadow .row .feature-icon-cell {
  background: #fcfcfc !important;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2) !important;
  border-radius: 12px !important;
}

.lower-pg-icon-section {
  padding: 1.5rem;
  width: 100%;
}
.lower-pg-icon-section .align-left {
  text-align: left;
}
.lower-pg-icon-section .align-right {
  text-align: right;
}
.lower-pg-icon-section .align-center {
  text-align: center;
}
.lower-pg-icon-section h1, .lower-pg-icon-section .h1,
.lower-pg-icon-section h2 h3,
.lower-pg-icon-section .h2 h3,
.lower-pg-icon-section h2 .h3,
.lower-pg-icon-section .h2 .h3 {
  width: 100%;
  font-weight: 600 !important;
  font-variation-settings: "wght" 600;
}
@media (max-width: 767.98px) {
  .lower-pg-icon-section h1, .lower-pg-icon-section .h1,
  .lower-pg-icon-section h2 h3,
  .lower-pg-icon-section .h2 h3,
  .lower-pg-icon-section h2 .h3,
  .lower-pg-icon-section .h2 .h3 {
    margin-bottom: 1rem !important;
  }
}
.lower-pg-icon-section a {
  width: 100%;
}
.lower-pg-icon-section .feature-icon-cell {
  text-align: center;
}
@media (max-width: 767.98px) {
  .lower-pg-icon-section .feature-icon-cell {
    padding: 0;
    margin-bottom: 0.7rem !important;
  }
}
@media (min-width: 992px) {
  .lower-pg-icon-section .feature-icon-cell {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}
@media screen and (min-device-width: 768px) and (max-device-width: 1099px) and (orientation: portrait) {
  .lower-pg-icon-section .feature-icon-cell {
    padding: 1vw;
    text-align: center !important;
  }
}
@media screen and (min-device-width: 768px) and (max-device-width: 1099px) and (orientation: landscape) {
  .lower-pg-icon-section .feature-icon-cell {
    padding: 2vw;
    text-align: center !important;
  }
}
.lower-pg-icon-section .feature-icon-cell a {
  text-decoration: none !important;
}
.lower-pg-icon-section .feature-icon-cell a:active, .lower-pg-icon-section .feature-icon-cell a:hover, .lower-pg-icon-section .feature-icon-cell a:focus {
  text-decoration: underline;
  color: #3d5193;
}
@media (max-width: 767.98px) {
  .lower-pg-icon-section .feature-icon-cell .icon-image {
    padding: 0.8rem 0 0.8rem 0.2rem !important;
    display: flex;
  }
}
@media (min-width: 992px) {
  .lower-pg-icon-section .feature-icon-cell .icon-image {
    padding-top: 0.7rem;
  }
}
.lower-pg-icon-section .feature-icon-cell img,
.lower-pg-icon-section .feature-icon-cell svg {
  height: auto;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
@media (max-width: 767.98px) {
  .lower-pg-icon-section .feature-icon-cell img,
  .lower-pg-icon-section .feature-icon-cell svg {
    margin: -4px 6px 10px 0;
    padding: 10px 12px;
  }
}
@media (min-width: 992px) {
  .lower-pg-icon-section .feature-icon-cell img,
  .lower-pg-icon-section .feature-icon-cell svg {
    width: auto;
    height: 80px;
    margin: 28px auto;
  }
}
@media screen and (min-device-width: 768px) and (max-device-width: 1099px) and (orientation: portrait) {
  .lower-pg-icon-section .feature-icon-cell img,
  .lower-pg-icon-section .feature-icon-cell svg {
    width: 65%;
    margin: auto auto -10px auto;
  }
}
@media screen and (min-device-width: 768px) and (max-device-width: 1099px) and (orientation: landscape) {
  .lower-pg-icon-section .feature-icon-cell img,
  .lower-pg-icon-section .feature-icon-cell svg {
    width: 75%;
    margin: auto;
  }
}
.lower-pg-icon-section .feature-icon-cell img:active, .lower-pg-icon-section .feature-icon-cell img:hover, .lower-pg-icon-section .feature-icon-cell img:focus,
.lower-pg-icon-section .feature-icon-cell svg:active,
.lower-pg-icon-section .feature-icon-cell svg:hover,
.lower-pg-icon-section .feature-icon-cell svg:focus {
  transform: translateY(-10px);
  filter: invert(24%) sepia(50%) saturate(0%) hue-rotate(275deg) brightness(102%) contrast(85%);
}
.lower-pg-icon-section .feature-icon-cell i {
  font-size: 6rem;
  color: inherit;
  text-align: center;
}
@media (max-width: 767.98px) {
  .lower-pg-icon-section .feature-icon-cell i {
    font-size: 6rem;
    justify-self: center;
    align-self: center;
  }
}
@media (min-width: 992px) {
  .lower-pg-icon-section .feature-icon-cell i {
    font-size: 7rem;
    padding-bottom: 2.5rem;
  }
}
.lower-pg-icon-section .feature-icon-cell i:active, .lower-pg-icon-section .feature-icon-cell i:hover, .lower-pg-icon-section .feature-icon-cell i:focus {
  transform: translateY(-10px);
  filter: invert(24%) sepia(50%) saturate(0%) hue-rotate(275deg) brightness(102%) contrast(85%);
}
@media (max-width: 767.98px) {
  .lower-pg-icon-section .feature-icon-cell .icon-text {
    padding-top: 16px;
  }
}
@media screen and (min-device-width: 810px) and (max-device-width: 1099px) and (orientation: portrait) {
  .lower-pg-icon-section .feature-icon-cell .icon-text {
    padding-left: 6px !important;
  }
}
@media screen and (min-device-width: 810px) and (max-device-width: 1099px) and (orientation: landscape) {
  .lower-pg-icon-section .feature-icon-cell .icon-text {
    padding-left: 6px !important;
  }
}
.lower-pg-icon-section .feature-icon-cell .icon-text h4, .lower-pg-icon-section .feature-icon-cell .icon-text .h4,
.lower-pg-icon-section .feature-icon-cell .icon-text .icon-title {
  width: 100%;
  font-weight: 600;
  font-variation-settings: "wght" 600;
}
@media (max-width: 767.98px) {
  .lower-pg-icon-section .feature-icon-cell .icon-text h4, .lower-pg-icon-section .feature-icon-cell .icon-text .h4,
  .lower-pg-icon-section .feature-icon-cell .icon-text .icon-title {
    text-align: left;
    font-size: 0.9rem;
    padding-top: 14px;
    padding-bottom: 0;
    margin-bottom: 6px;
    padding-left: 10px;
    padding-right: 6px;
  }
}
@media (min-width: 992px) {
  .lower-pg-icon-section .feature-icon-cell .icon-text h4, .lower-pg-icon-section .feature-icon-cell .icon-text .h4,
  .lower-pg-icon-section .feature-icon-cell .icon-text .icon-title {
    text-align: center;
    font-size: 1.1rem;
    padding-top: 0.9rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media screen and (min-device-width: 768px) and (max-device-width: 1099px) and (orientation: portrait) {
  .lower-pg-icon-section .feature-icon-cell .icon-text h4, .lower-pg-icon-section .feature-icon-cell .icon-text .h4,
  .lower-pg-icon-section .feature-icon-cell .icon-text .icon-title {
    margin-top: -1.5rem;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
  }
}
@media screen and (min-device-width: 768px) and (max-device-width: 1099px) and (orientation: landscape) {
  .lower-pg-icon-section .feature-icon-cell .icon-text h4, .lower-pg-icon-section .feature-icon-cell .icon-text .h4,
  .lower-pg-icon-section .feature-icon-cell .icon-text .icon-title {
    margin-top: -1.5rem;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
  }
}
.lower-pg-icon-section .feature-icon-cell .icon-text .icon-title {
  font-family: "Oswald", "HeaderFallBackMac", "HeaderFallBackWin", sans-serif;
  font-weight: 500;
  font-variation-settings: "wght" 500;
  text-align: center;
  color: #26366a !important;
  text-decoration: none !important;
}
.lower-pg-icon-section .feature-icon-cell .icon-text .icon-title:active, .lower-pg-icon-section .feature-icon-cell .icon-text .icon-title:hover, .lower-pg-icon-section .feature-icon-cell .icon-text .icon-title:focus {
  text-decoration: underline;
  color: #3d5193;
}
.lower-pg-icon-section .feature-icon-cell .icon-text .text-div {
  text-align: left;
}
@media (max-width: 767.98px) {
  .lower-pg-icon-section .feature-icon-cell .icon-text .text-div {
    padding-right: 10px;
  }
}
@media (max-width: 767.98px) {
  .lower-pg-icon-section .feature-icon-cell .icon-text .text-div p {
    font-size: 0.8rem;
    padding-left: 10px;
    padding-right: 6px;
  }
}
@media (min-width: 992px) {
  .lower-pg-icon-section .feature-icon-cell .icon-text .text-div p {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (min-device-width: 810px) and (max-device-width: 1099px) and (orientation: portrait) {
  .lower-pg-icon-section .feature-icon-cell .icon-text .text-div p {
    width: 100% !important;
  }
}
@media screen and (min-device-width: 810px) and (max-device-width: 1099px) and (orientation: landscape) {
  .lower-pg-icon-section .feature-icon-cell .icon-text .text-div p {
    width: 100% !important;
  }
}
.lower-pg-icon-section .feature-icon-cell .icon-text hr.feature-icon-hr {
  border-top: 1px #bbbbbb solid;
  margin-right: 4vw;
  margin-left: 4vw;
  margin-top: 0;
}
@media (max-width: 767.98px) {
  .lower-pg-icon-section .feature-icon-cell .icon-text hr.feature-icon-hr {
    margin-top: -10px;
  }
}
@media (min-width: 992px) {
  .lower-pg-icon-section .feature-icon-cell .col-md-4 i {
    font-size: 8rem;
  }
}
.lower-pg-icon-section .rounded-shadow .feature-icon-cell .container {
  background: #fcfcfc;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
}

@media (max-width: 575.98px) {
  .highlight-component {
    padding: 0 1rem 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .highlight-component {
    padding: 2rem !important;
  }
}
@media (min-width: 992px) {
  .highlight-component {
    padding: 2rem 8vw !important;
  }
}
@media (max-width: 575.98px) {
  .highlight-component .highlight-text-div {
    width: 100vw;
    margin: 3vw;
    text-align: left;
  }
}
@media (min-width: 768px) {
  .highlight-component .highlight-text-div {
    text-align: left;
  }
}
.highlight-component .button-div {
  width: 100%;
  text-align: center;
}
@media (max-width: 575.98px) {
  .highlight-component .button-div {
    padding-top: 0.8rem;
  }
}
@media (min-width: 768px) {
  .highlight-component .button-div {
    padding-top: 0.5rem;
  }
}
.highlight-component h1, .highlight-component .h1,
.highlight-component h2,
.highlight-component .h2,
.highlight-component h3,
.highlight-component .h3 {
  font-weight: 600;
  font-variation-settings: "wght" 600;
  width: 100%;
  margin-bottom: 1rem;
}
@media (max-width: 575.98px) {
  .highlight-component h1, .highlight-component .h1,
  .highlight-component h2,
  .highlight-component .h2,
  .highlight-component h3,
  .highlight-component .h3 {
    padding-top: 0.7rem;
    padding-bottom: 0.2rem;
    font-size: 2.5rem;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .highlight-component h1, .highlight-component .h1,
  .highlight-component h2,
  .highlight-component .h2,
  .highlight-component h3,
  .highlight-component .h3 {
    font-size: 2.3rem;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .highlight-component h1, .highlight-component .h1,
  .highlight-component h2,
  .highlight-component .h2,
  .highlight-component h3,
  .highlight-component .h3 {
    font-size: 2.3rem;
  }
}
@media (min-width: 1200px) {
  .highlight-component h1, .highlight-component .h1,
  .highlight-component h2,
  .highlight-component .h2,
  .highlight-component h3,
  .highlight-component .h3 {
    font-size: 3rem;
  }
}
.highlight-component p {
  font-weight: 500;
  font-variation-settings: "wght" 500;
}
@media (max-width: 575.98px) {
  .highlight-component p {
    font-size: 1.5rem;
    letter-spacing: 0.003rem !important;
    font-weight: 500;
    font-variation-settings: "wght" 500;
  }
}
@media (min-width: 1200px) {
  .highlight-component p {
    font-size: 1.3rem;
    letter-spacing: 0.02rem;
  }
}
@media (min-width: 768px) {
  .highlight-component .order-md-1 p {
    margin-right: 1.2rem;
  }
}
@media (min-width: 768px) {
  .highlight-component .order-md-2 p {
    margin-left: 1.2rem;
  }
}
.highlight-component .highlight-image-div {
  padding: 0;
}
@media (max-width: 575.98px) {
  .highlight-component .highlight-image-div {
    width: 100%;
    margin: 0 0 1rem;
  }
}
@media (min-width: 768px) {
  .highlight-component .highlight-image-div {
    margin: 0;
  }
}
.highlight-component .highlight-image-div img {
  margin: 0;
  padding: 0;
  width: 100%;
}
.highlight-component .tag {
  font-size: 1rem;
  font-family: "Oswald", "HeaderFallBackMac", "HeaderFallBackWin", sans-serif;
  font-weight: 400;
  font-variation-settings: "wght" 400;
  text-transform: uppercase;
  color: #606060;
}
@media (max-width: 575.98px) {
  .highlight-component .tag {
    margin-bottom: 0.3rem;
  }
}
@media (min-width: 768px) {
  .highlight-component .tag {
    margin-bottom: 0.6rem;
  }
}
.highlight-component .subhead {
  font-size: 1.6rem;
  font-family: "Oswald", "HeaderFallBackMac", "HeaderFallBackWin", sans-serif;
  font-weight: 700;
  font-variation-settings: "wght" 700;
}

tr.hidden {
  display: none;
}

.wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

@media (max-width: 575.98px) {
  .previous-page-link {
    float: left;
    padding-left: 10px;
  }
}

.table-wrapper {
  margin: 0 auto 0;
  max-height: 500px;
  overflow-y: scroll;
  position: relative;
  transition: all 0.2s ease-out;
  max-width: 2000px;
}
@media (min-width: 768px) {
  .table-wrapper {
    width: 100%;
  }
}

.card-list-table {
  table-layout: fixed;
  margin-bottom: 0;
  width: 100%;
  font-size: 1rem;
}
@media (max-width: 575.98px) {
  .card-list-table {
    background: transparent;
  }
}
@media (min-width: 768px) {
  .card-list-table {
    background: #fcfcfc;
  }
}
.card-list-table tbody tr {
  background: #fcfcfc;
}
@media (max-width: 575.98px) {
  .card-list-table tbody tr {
    display: block;
    padding: 5px 10px;
    margin: 0 0 10px 0;
    border: 1px solid #bbbbbb;
    -webkit-box-shadow: 0px 0px 4px 3px rgba(26, 26, 26, 0.1);
    -moz-box-shadow: 0px 0px 4px 3px rgba(26, 26, 26, 0.1);
    box-shadow: 0px 0px 4px 3px rgba(26, 26, 26, 0.1);
  }
}
@media (min-width: 768px) {
  .card-list-table tbody tr {
    margin: 0;
    display: table-row;
    padding-bottom: 0;
    border-bottom: 1px solid #bbbbbb;
  }
}
.card-list-table tbody td {
  border: 0;
  position: relative;
}
@media (max-width: 575.98px) {
  .card-list-table tbody td {
    display: block;
    padding: 0 10px 10px 40%;
  }
}
@media (min-width: 768px) {
  .card-list-table tbody td {
    cursor: pointer;
    display: table-cell;
    padding: 20px 24px;
    transition: background 0.2s ease-out;
    vertical-align: middle;
  }
}
@media (max-width: 575.98px) {
  .card-list-table tbody td:last-of-type {
    margin-bottom: -5px !important;
  }
}
.card-list-table tbody td:after {
  content: "";
  width: calc(100% - 30px);
  margin: 0 auto;
  height: 1px;
  background: #bbbbbb;
  position: absolute;
  left: 0;
  right: 0;
  top: -6px;
}
@media (max-width: 575.98px) {
  .card-list-table tbody td:after {
    display: block;
  }
}
@media (min-width: 768px) {
  .card-list-table tbody td:after {
    display: none;
  }
}
.card-list-table tbody td:before {
  color: #26366a;
  font-size: 1rem;
  display: table-cell;
  font-weight: 700;
  font-variation-settings: "wght" 700;
  height: 100%;
  left: 15px;
  margin: auto;
  position: absolute;
  vertical-align: middle;
  white-space: nowrap;
  width: 40%;
}
.card-list-table tbody td:first-of-type::after {
  visibility: hidden;
}
@media (min-width: 768px) {
  .card-list-table tbody td tr:hover td {
    background: #FFEAA8;
  }
}
@media (max-width: 575.98px) {
  .card-list-table thead {
    display: none;
  }
}
@media (min-width: 768px) {
  .card-list-table thead tr {
    background: #E7E7E8;
    color: #26366a;
    border-bottom: 1px #bbbbbb;
  }
}
.card-list-table thead th {
  color: #26366a;
  letter-spacing: 0.5pt;
}
@media (min-width: 768px) {
  .card-list-table thead th {
    padding: 12px 24px;
  }
}

.table-responsive-stack tr {
  font-size: 0.8rem;
}
@media (max-width: 575.98px) {
  .table-responsive-stack tr {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    border-bottom: 3px solid #ccc;
    display: block;
  }
}
@media (max-width: 575.98px) {
  .table-responsive-stack td,
  .table-responsive-stack th {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }
}
@media (max-width: 575.98px) {
  .table-responsive-stack td {
    display: block;
    /* ie 9 fix */
    float: left\9 ;
    width: 100%;
  }
}
.table-responsive-stack .table-responsive-stack-thead {
  font-weight: bold;
  font-variation-settings: "wght" 600;
}

div[id^=excel] table,
div[id^=excel] .table-responsive-stack {
  border: 1px solid #E7E7E8;
  border-collapse: collapse;
  margin: 1rem 0;
  padding: 0;
  width: 100%;
}
div[id^=excel] table caption,
div[id^=excel] .table-responsive-stack caption {
  font-size: 1.5em;
  margin: 0.5em 0 0.75em;
}
div[id^=excel] table tr,
div[id^=excel] .table-responsive-stack tr {
  font-size: 0.8rem;
  background: #fcfcfc;
  border: 1px solid #E7E7E8;
  padding: 0.3rem;
}
div[id^=excel] table tr:nth-child(odd):not(first-child) td,
div[id^=excel] .table-responsive-stack tr:nth-child(odd):not(first-child) td {
  background: #f4f4f4;
}
div[id^=excel] table tr:first-child,
div[id^=excel] .table-responsive-stack tr:first-child {
  font-weight: bold;
  font-variation-settings: "wght" 600;
}
div[id^=excel] table th,
div[id^=excel] table td,
div[id^=excel] .table-responsive-stack th,
div[id^=excel] .table-responsive-stack td {
  padding: 0.3rem;
}
@media (min-width: 768px) {
  div[id^=excel] table th,
  div[id^=excel] table td,
  div[id^=excel] .table-responsive-stack th,
  div[id^=excel] .table-responsive-stack td {
    text-align: center;
  }
}
div[id^=excel] table th,
div[id^=excel] .table-responsive-stack th {
  font-size: 0.8rem;
  background: #E7E7E8;
}
@media (max-width: 575.98px) {
  div[id^=excel] table {
    border: 0;
  }
  div[id^=excel] table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  div[id^=excel] table tr {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    display: block;
    border-bottom: 0 !important;
    margin-bottom: 0.625em;
  }
  div[id^=excel] table tr:first-of-type {
    display: none;
  }
  div[id^=excel] table caption {
    font-size: 1.3em;
  }
  div[id^=excel] table td,
  div[id^=excel] table th {
    display: block;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }
  div[id^=excel] table td {
    width: 100%;
    border-bottom: 1px solid #E7E7E8;
    display: block;
    font-size: 0.8rem;
    text-align: right;
  }
  div[id^=excel] table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    font-variation-settings: "wght" 600;
    text-transform: uppercase;
  }
  div[id^=excel] table td:last-child {
    border-bottom: 0;
  }
}

.special h3, .special .h3 {
  font-size: 1.25rem;
  text-transform: uppercase;
  font-weight: 600;
  font-variation-settings: "wght" 600;
}

.news-section {
  padding-top: 20px;
}
@media screen and (min-width: 772px) and (max-width: 769px) and (orientation: portrait) {
  .news-section {
    margin-top: -200px !important;
    margin-left: 1em !important;
    margin-right: 1em !important;
  }
}
@media screen and (min-width: 770px) and (max-width: 800px) and (orientation: portrait) {
  .news-section {
    margin-top: -160px !important;
    margin-left: 1em !important;
    margin-right: 1em !important;
  }
}
@media (max-width: 575.98px) {
  .news-section {
    margin-top: -280px;
    margin-left: 1.5%;
    margin-right: 1.5%;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .news-section {
    margin-top: -220px;
    margin-left: 1.5%;
    margin-right: 1.5%;
  }
}
@media (min-width: 992px) {
  .news-section {
    margin-left: 16%;
    margin-right: 16%;
  }
}

.news-grid {
  max-width: 1400px;
  margin-left: auto !important;
  margin-right: auto !important;
}

.snippet,
.news-listing {
  min-height: 5em;
  margin-bottom: 1.5em;
}
.snippet img,
.snippet .date-stamp,
.news-listing img,
.news-listing .date-stamp {
  float: left;
  margin-right: 0.5em;
}
.snippet p,
.news-listing p {
  margin-bottom: 5px;
  padding-top: 0;
  margin-top: -4px;
  font-size: 0.85rem;
  font-family: "Roboto", sans-serif;
  text-transform: capitalize;
}
.snippet .title,
.news-listing .title {
  border-bottom: 1px solid #26366a;
  font-weight: normal;
  font-size: 1rem;
  color: #505050;
  font-weight: 500;
  font-variation-settings: "wght" 500;
  margin-top: 10px;
  padding-top: 0;
  text-decoration: none !important;
}
.snippet .date-stamp,
.news-listing .date-stamp {
  width: 4rem;
  text-align: right;
  padding-top: 0;
}
.snippet .date-stamp .month,
.news-listing .date-stamp .month {
  color: #26366a;
  padding: 0.05rem 0 0 0;
  font-size: 1.2em;
  text-align: right;
  font-weight: 600;
  font-variation-settings: "wght" 600;
}
.snippet .date-stamp .day,
.news-listing .date-stamp .day {
  padding: 0 0 0 0.5em;
  font-size: 2em;
  line-height: 0.9;
  font-weight: 200;
  font-variation-settings: "wght" 200;
  border-top: none;
  color: #26366a;
}
.snippet .date-stamp .day a:hover,
.news-listing .date-stamp .day a:hover {
  text-decoration: underline;
  color: #9c1212;
  font-weight: bold;
  font-variation-settings: "wght" 600;
}

.news a:hover, .news a:focus {
  color: #9c1212;
  font-weight: bold;
  font-variation-settings: "wght" 600;
  text-decoration: underline;
}
.news .snippet-text {
  padding-left: 0;
  margin-left: -16px;
  padding-top: 0.2rem;
}

@media (min-width: 1400px) {
  .election-party {
    padding-left: 11vw !important;
    padding-right: 11vw !important;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .election-party {
    padding-left: 10vw !important;
    padding-right: 10vw !important;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .election-party {
    padding-left: 10vw !important;
    padding-right: 10vw !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .election-party {
    padding-left: 15vw !important;
    padding-right: 15vw !important;
  }
}
@media (max-width: 575.98px) {
  .election-party {
    padding-left: 12vw !important;
    padding-right: 12vw !important;
    padding-top: 0 !important;
    margin-top: 0 !important;
  }
}
@media screen and (min-width: 1800px) {
  .election-party {
    padding-left: 16vw !important;
    padding-right: 16vw !important;
  }
}
.election-party .left-button,
.election-party .right-button {
  padding: 0 1rem;
}
.election-party .middle-col {
  text-align: center;
  padding-top: 1.5rem;
}
@media (min-width: 992px) and (max-width: 1399.98px) {
  .election-party .middle-col {
    padding-bottom: 0;
  }
}
.election-party .middle-col h1.pres, .election-party .middle-col .pres.h1 {
  font-size: 3.6vw !important;
  letter-spacing: -0.04rem !important;
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .election-party .middle-col h1.pres, .election-party .middle-col .pres.h1 {
    font-size: 4rem !important;
  }
}
@media (max-width: 575.98px) {
  .election-party .middle-col h1.pres, .election-party .middle-col .pres.h1 {
    font-size: 3.6rem !important;
  }
}
.election-party .middle-col h2.prim, .election-party .middle-col .prim.h2 {
  font-size: 2.5vw !important;
  line-height: 2.6rem !important;
  padding-top: 0 !important;
  margin-top: -0.6rem;
  letter-spacing: -0.05rem !important;
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .election-party .middle-col h2.prim, .election-party .middle-col .prim.h2 {
    font-size: 2.8rem !important;
  }
}
@media (max-width: 575.98px) {
  .election-party .middle-col h2.prim, .election-party .middle-col .prim.h2 {
    font-size: 2.5rem !important;
  }
}
@media (min-width: 992px) {
  .election-party .middle-col img {
    padding: 0 3vw;
  }
}
.election-party .second-row {
  text-align: center;
  margin-top: 0.5vw;
  padding-top: 0;
}
@media (min-width: 992px) {
  .election-party .second-row {
    margin-top: -4.5vw;
  }
}
.election-party .second-row div {
  padding-bottom: 1.2rem;
}
.election-party .second-row div,
.election-party .results-row div {
  text-align: center;
  font-weight: 500;
  font-size: 1.7rem;
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .election-party .second-row div,
  .election-party .results-row div {
    font-size: 1.6rem !important;
  }
}
@media (min-width: 1200px) {
  .election-party .second-row div,
  .election-party .results-row div {
    font-size: 1.5vw !important;
  }
}
.election-party .second-row div button,
.election-party .results-row div button {
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 2100px) {
  .election-party {
    padding-left: 20vw;
    padding-right: 20vw;
  }
  .election-party .left-button img,
  .election-party .right-button img {
    height: 100%;
    width: auto;
    text-align: center;
  }
  .election-party .second-row {
    margin-top: 0;
    padding-top: 0;
  }
}
@media (min-width: 1400px) {
  .election-party .middle-col img {
    padding-left: 1vw;
    padding-right: 1vw;
  }
}
.activeHeader {
  background-color: #66afe9 !important;
  color: #fff !important;
}

.activeCell {
  background-color: rgba(0, 0, 0, 0.075);
}

.even {
  background-color: #fff;
}

.odd {
  background-color: #f9f9f9;
}

.ezActiveRow {
  background-color: #2852a8 !important;
  color: #fff;
}

.ezSelectedRow {
  background-color: #316ac5 !important;
  color: #fff;
}

.ezActiveCell {
  background-color: #d9e8fb !important;
  color: #000 !important;
  font-weight: bold;
}

.ezETSelectedCell {
  background-color: #ffdc61 !important;
  font-weight: bold;
  color: #000 !important;
}

.ezUnselectable {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.ezInputEditor {
  width: 95%;
  height: auto;
  font-size: inherit;
  border: 1px solid #aaccf6;
}

.ezTextareaEditor {
  width: 95%;
  height: 35px;
  font-size: inherit;
  border: 1px solid #aaccf6;
}

.ezSelectEditor {
  width: 100%;
  font-size: inherit;
  border: 1px solid #aaccf6;
}

.ezModifiedCell {
  background: transparent url("themes/bg_mod_cell.png") 0 0 no-repeat;
}

select[multiple=multiple].ezSelectEditor {
  height: 35px;
}

.ezCommandEditor {
  margin: 2px;
}

.ezCommandEditor button, .ezCommandEditor input[type=button] {
  min-height: 22px;
  margin: 1px;
  padding: 3px;
  border: 1px solid #ccc;
  background: #fff;
  border-radius: 4px 4px 4px 4px;
  -moz-border-radius: 4px 4px 4px 4px;
}

.ezCommandEditor button:hover, .ezCommandEditor input[type=button]:hover {
  border: 1px solid #999;
}

.ezCommandEditor img {
  border: 0;
  vertical-align: middle;
  margin: 2px;
}

.ezOpacity {
  opacity: 0.6;
}

.alignLeft {
  text-align: left;
}

.alignCenter {
  text-align: center;
}

.alignRight {
  text-align: right;
}

.div_checklist {
  width: 100%;
  height: 90px;
  line-height: 30px;
  border: 1px solid #f4f4f4;
  overflow: auto;
  text-align: left;
  background-color: #fff;
  color: #444;
}

.div_checklist ul.flt_checklist {
  padding: 0 !important;
  margin: 0 !important;
  list-style: none !important;
}

.div_checklist li.flt_checklist_item {
  padding: 1px !important;
  margin: 0 !important;
  font-size: inherit;
  border-bottom: 1px solid #f4f4f4 !important;
}

.div_checklist li.flt_checklist_item:hover {
  background-color: #335ea8 !important;
  color: #fff !important;
}

.div_checklist label {
  display: block !important;
  font-weight: inherit !important;
}

.div_checklist input {
  vertical-align: middle !important;
  margin: 2px 5px 3px 1px !important;
}

.flt_checklist_item_disabled {
  background-color: #e5e5e5;
}

.flt_checklist_slc_item {
  background-color: #335ea8 !important;
  color: #fff !important;
}

.fltrow {
  height: 1em;
  background-color: #eaeaea;
}

.fltrow td {
  border-bottom: 1px solid #ccc !important;
  border-top: 1px solid #f4f4f4;
  border-left: 1px solid #ccc;
  border-right: 1px solid #f4f4f4;
  padding: 0.2em !important;
}

.fltrow td:last-child {
  border-right: 1px solid #ccc;
}

.btnflt {
  height: 35px;
  font-family: inherit;
  font-size: inherit;
  vertical-align: middle;
  margin: 0 2px 0 2px;
  padding: 0 1px 0 1px;
}

.btnflt_icon {
  font-family: inherit;
  font-size: inherit;
  width: 35px;
  height: 35px;
  cursor: pointer !important;
  border: 0 !important;
  vertical-align: middle;
  background: transparent url("themes/btn_filter.png") center center no-repeat !important;
}

.flt, .flt_s, .single_flt {
  font-family: inherit;
  font-size: inherit;
  display: block;
  color: #444;
  background-color: #fff;
  border: 1px inset #f4f4f4;
  margin: 0;
  padding: 0 0 0 0.2em;
  width: 100%;
  height: 35px;
  vertical-align: middle;
  border-radius: 2px;
  box-sizing: border-box;
}

.flt:focus, .flt_s:focus, .single_flt:focus {
  border-color: #66afe9;
  outline: 0 none;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(102, 175, 233, 0.6);
  -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(102, 175, 233, 0.6);
}

select.flt_multi {
  font-family: inherit;
  font-size: inherit;
  color: #444;
  background-color: #fff;
  border: 1px solid #f4f4f4;
  margin: 0;
  padding: 0.2em;
  width: 100%;
  height: 90px;
  vertical-align: middle;
  box-sizing: border-box;
}

select.flt_multi option {
  padding-top: 5px;
  padding-bottom: 5px;
}

.flt_s {
  width: 60%;
  box-sizing: initial;
  display: initial;
}

.single_flt {
  width: 70%;
  box-sizing: initial;
  display: initial;
}

div.popUpFilter {
  position: relative;
  background: #fff;
  -webkit-box-shadow: 3px 3px 2px #888;
  -moz-box-shadow: 3px 3px 2px #888;
  box-shadow: 3px 3px 2px #888;
  margin: 30px auto 0 0;
  position: absolute;
  display: none;
  width: 100px;
  background-color: #eaeaea;
  border: 1px solid #eaeaea;
  padding: 0;
}

div.popUpFilter:after, div.popUpFilter:before {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

div.popUpFilter:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #fff;
  border-width: 10px;
  margin-left: -10px;
}

div.popUpFilter:before {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #eaeaea;
  border-width: 12px;
  margin-left: -12px;
}

.popUpPlaceholder {
  position: relative;
}

div.grd_Cont {
  -webkit-box-shadow: 4px 4px 10px 0 rgba(50, 50, 50, 0.75);
  -moz-box-shadow: 4px 4px 10px 0 rgba(50, 50, 50, 0.75);
  box-shadow: 4px 4px 10px 0 rgba(50, 50, 50, 0.75);
  width: 800px;
  height: auto;
  overflow: hidden;
  background-color: #c8e0fb;
  border: 1px solid #99bbe8;
}

div.grd_Cont .fltrow {
  background-color: transparent;
}

div.grd_Cont .flt {
  border: 1px solid #99bbe8;
  width: 100%;
}

div.grd_Cont .flt :focus {
  border: 1px solid #558dd9;
}

div.grd_Cont .even {
  background-color: #fff;
}

div.grd_Cont .odd {
  background-color: #dfe8f6;
}

div.grd_Cont .no-results {
  background-color: transparent;
}

div.grd_Cont .sort-arrow {
  position: initial;
}

div.grd_tblCont {
  height: 400px;
  width: 800px;
  background: #fff;
  overflow-x: auto;
  overflow-y: scroll;
}

div.grd_headTblCont {
  display: block;
  margin-right: 20px;
  height: auto;
  overflow: hidden;
  border-bottom: 1px solid #99bbe8;
  background-color: #c8e0fb;
}

div.grd_tblCont table, div.grd_headTblCont table {
  border-collapse: collapse;
  table-layout: fixed;
  box-sizing: initial;
}

div.grd_tblCont table th, div.grd_headTblCont table th, div.grd_headTblCont table td {
  height: 35px;
  background-color: #c8e0fb;
  padding: 0.1em 0.5em;
  color: #333;
  border-right: 1px solid #99bbe8 !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

div.grd_headTblCont table td {
  padding: 0.2em 0.2em;
}

div.grd_tblCont table td {
  padding: 0.5em 0.7em;
  border-bottom: 1px solid #99bbe8;
  overflow: hidden;
  text-overflow: ellipsis;
}

.grd_inf {
  clear: both;
  width: auto;
  height: 35px;
  background-color: #c8e0fb;
  margin: 0;
  padding: 1px 3px 1px 3px;
  border-top: 1px solid #99bbe8;
}

.grd_inf a {
  color: #333;
  text-decoration: none;
  font-weight: bold;
}

.grd_inf a:hover {
  text-decoration: underline;
  background-color: transparent;
}

.grd_inf input.reset:hover {
  background-color: transparent;
}

.grd_inf .mdiv {
  width: 40% !important;
}

.grd_inf .ldiv div {
  border: 0;
}

.grd_inf .helpBtn {
  border: 0 !important;
}

.grd_inf div.status {
  position: absolute;
  float: none !important;
  height: auto !important;
  margin: 19px 0 !important;
  font-size: 12px;
  color: #333;
  border: 0 !important;
}

.grd_inf div.tot {
  border: 0 !important;
}

.helpBtn {
  display: inline-block;
  height: 27px;
  margin: 0;
  padding: 8px 15px 0 15px;
  vertical-align: top;
}

.helpBtn:hover {
  background-color: #f4f4f4;
}

div.helpCont {
  position: relative;
  background: #fff;
  -webkit-box-shadow: 3px 3px 2px #888;
  -moz-box-shadow: 3px 3px 2px #888;
  box-shadow: 3px 3px 2px #888;
  position: absolute;
  display: none;
  width: 300px;
  padding: 10px;
  margin: 45px 0 0 -150px;
  border: 1px solid #ccc;
  line-height: 20px;
  font-size: inherit;
  color: #333;
  background: #fff;
  text-align: left;
  z-index: 1000;
}

div.helpCont:after, div.helpCont:before {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

div.helpCont:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #fff;
  border-width: 10px;
  margin-left: -10px;
}

div.helpCont:before {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ccc;
  border-width: 12px;
  margin-left: -12px;
}

div.helpCont a {
  color: #c00;
  text-decoration: underline;
  font-weight: normal;
}

div.helpCont a.close {
  color: #333 !important;
  text-decoration: none !important;
  font-weight: bold;
}

div.helpCont a.close:hover {
  text-decoration: none;
}

div.helpCont hr {
  border: 1px solid #ccc;
}

div.helpFooter {
  margin: 10px 0 0 0;
}

div.helpFooter h4, div.helpFooter .h4 {
  margin: 2px 2px 2px 2px;
  color: #333;
}

span.keyword {
  font-weight: 700;
  font-style: italic;
  border-bottom: 1px dotted #ccc;
}

.loader {
  position: absolute;
  padding: 0.5em 0.7em;
  margin: 10em 0 0 3em;
  width: auto;
  z-index: 1000;
  font-weight: 600;
  background-color: #a7a7a8;
  vertical-align: middle;
  border-radius: 10px;
  color: #fff;
  text-shadow: 1px 1px #333;
}

.no-results {
  display: none;
  color: #333;
  margin: 0;
  padding: 1em 0;
  text-align: center;
  max-height: 5em;
  background-color: #f4f4f4;
}

select.pgSlc {
  height: 35px;
  margin: 0;
  border: 1px solid #f4f4f4;
  background-color: #fff;
  vertical-align: middle;
}

select.pgSlc:focus {
  border-color: #66afe9;
  outline: 0 none;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(102, 175, 233, 0.6);
  -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(102, 175, 233, 0.6);
}

input.pgNbInp {
  height: 35px;
  margin: 0;
  border: 1px solid #f4f4f4;
  background-color: #fff;
  width: 35px;
}

input.pgNbInp:focus {
  border-color: #66afe9;
  outline: 0 none;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(102, 175, 233, 0.6);
  -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(102, 175, 233, 0.6);
}

input.pgInp, .nextPage, .previousPage, .firstPage, .lastPage {
  height: 35px;
  margin: 0;
  border: 1px solid #f4f4f4;
  background-color: #fff;
  vertical-align: middle;
  width: 35px;
  border: 0;
  font-weight: bold;
}

input.pgInp:focus, .nextPage:focus, .previousPage:focus, .firstPage:focus, .lastPage:focus {
  border-color: #66afe9;
  outline: 0 none;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(102, 175, 233, 0.6);
  -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(102, 175, 233, 0.6);
}

.nextPage {
  background: transparent url("themes/btn_next_page.gif") center center no-repeat !important;
}

.nextPage:hover {
  background-color: #f4f4f4 !important;
}

.previousPage {
  background: transparent url("themes/btn_previous_page.gif") center center no-repeat !important;
}

.previousPage:hover {
  background-color: #f4f4f4 !important;
}

.firstPage {
  background: transparent url("themes/btn_first_page.gif") center center no-repeat !important;
}

.firstPage:hover {
  background-color: #f4f4f4 !important;
}

.lastPage {
  background: transparent url("themes/btn_last_page.gif") center center no-repeat !important;
}

.lastPage:hover {
  background-color: #f4f4f4 !important;
}

span.nbpg {
  padding: 0 5px;
}

select.rspg {
  height: 35px;
  margin: 0;
  border: 1px solid #f4f4f4;
  background-color: #fff;
  margin: 0 0 0 5px;
  vertical-align: middle;
}

select.rspg:focus {
  border-color: #66afe9;
  outline: 0 none;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(102, 175, 233, 0.6);
  -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(102, 175, 233, 0.6);
}

span.rspgSpan {
  font-size: inherit;
}

input.reset {
  display: inline-block;
  width: 35px;
  height: 35px;
  border: 0;
  background: transparent url("themes/btn_clear_filters.png") center center no-repeat;
  vertical-align: top;
}

input.reset:hover {
  background-color: #f4f4f4;
}

a.reset {
  font-weight: normal !important;
  line-height: 35px;
  padding: 5px 5px;
}

div.tot {
  float: left;
  overflow: hidden;
  min-width: 150px;
  height: 100%;
  margin: 0;
  padding: 0.5em;
  vertical-align: middle;
}

div.tot span {
  font-weight: 500;
}

.sort-arrow {
  position: absolute;
  display: none;
  width: 11px;
  height: 11px;
  margin: 0;
  background-position: center center;
  background-repeat: no-repeat;
}

.descending {
  display: inline;
  background-image: url("themes/downsimple.png");
}

.ascending {
  display: inline;
  background-image: url("themes/upsimple.png");
}

div.status {
  float: left;
  overflow: hidden;
  min-width: 120px;
  height: 100%;
  margin: 0;
  padding: 0.5em;
}

div.status span {
  font-size: inherit;
}

table.TF {
  font-family: inherit;
  border-spacing: 0;
  border: 0;
}

table.TF th {
  height: 35px;
  margin: 0;
  background-color: #eaeaea;
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #f4f4f4;
  border-left: 1px solid #ccc;
  border-right: 1px solid #f4f4f4;
  padding: 0.1em 0.7em;
  color: #333;
}

table.TF th:last-child {
  border-right: 1px solid #ccc;
}

table.TF td {
  margin: 0;
  padding: 0.5em 0.7em;
  border-bottom: 1px solid #c6c6c6;
  text-overflow: ellipsis;
}

table.TF.resp {
  display: block;
  overflow-x: auto;
  overflow-y: hidden;
}

table.TF.resp .sort-arrow {
  position: initial;
}

table.TF.sticky th {
  position: sticky;
  top: -1px;
}

.inf {
  clear: both;
  width: auto;
  height: 35px;
  min-width: 400px;
  background-color: #fff;
  font-size: inherit;
  margin: 0;
  padding: 0;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  overflow: hidden;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.inf a {
  color: #333;
  text-decoration: none;
  font-weight: bold;
  box-sizing: initial;
}

.inf a:hover {
  text-decoration: underline;
}

.ldiv {
  float: left;
  width: 30%;
  position: inherit;
  text-align: left;
}

.ldiv:empty:after {
  content: " ";
}

.mdiv {
  float: left;
  width: 38%;
  position: inherit;
  text-align: center;
  padding: 0;
}

.mdiv:empty:after {
  content: " ";
}

.rdiv {
  float: right;
  width: 30%;
  position: inherit;
  text-align: right;
}

.rdiv:empty:after {
  content: " ";
}

.sortable thead th:not(.no-sort) {
  cursor: pointer;
}
.sortable thead th:not(.no-sort)::after, .sortable thead th:not(.no-sort)::before {
  transition: color 0.1s ease-in-out;
  font-size: 1.2em;
  color: transparent;
}
.sortable thead th:not(.no-sort)::after {
  margin-left: 3px;
  content: "▸";
}
.sortable thead th:not(.no-sort):hover::after {
  color: inherit;
}
.sortable thead th:not(.no-sort)[aria-sort=descending]::after {
  color: inherit;
  content: "▾";
}
.sortable thead th:not(.no-sort)[aria-sort=ascending]::after {
  color: inherit;
  content: "▴";
}
.sortable thead th:not(.no-sort).indicator-left::after {
  content: "";
}
.sortable thead th:not(.no-sort).indicator-left::before {
  margin-right: 3px;
  content: "▸";
}
.sortable thead th:not(.no-sort).indicator-left:hover::before {
  color: inherit;
}
.sortable thead th:not(.no-sort).indicator-left[aria-sort=descending]::before {
  color: inherit;
  content: "▾";
}
.sortable thead th:not(.no-sort).indicator-left[aria-sort=ascending]::before {
  color: inherit;
  content: "▴";
}

.sortable {
  --stripe-color: #e4e4e4;
  --th-color: #fff;
  --th-bg: #808080;
  --td-color: #000;
  --td-on-stripe-color: #000;
  border-spacing: 0;
}
.sortable tbody tr:nth-child(odd) {
  background-color: var(--stripe-color);
  color: var(--td-on-stripe-color);
}
.sortable thead th {
  background: var(--th-bg);
  color: var(--th-color);
  font-weight: normal;
  text-align: left;
  text-transform: capitalize;
  vertical-align: baseline;
  white-space: nowrap;
}
.sortable td {
  color: var(--td-color);
}
.sortable td,
.sortable th {
  padding: 10px;
}
.sortable td:first-child,
.sortable th:first-child {
  border-top-left-radius: 4px;
}
.sortable td:last-child,
.sortable th:last-child {
  border-top-right-radius: 4px;
}

@font-face {
  font-family: "font-awesome";
  src: url("../fonts/fontawesome-webfont.woff");
  font-display: swap;
}
@font-face {
  font-family: "Oswald";
  src: url("../fonts/Oswald-VariableFont_wght.ttf") format("truetype-variations"), url("../fonts/oswald-variablefont-wght-webfont.woff2") format(‘woff2 supports variations’), url("../fonts/oswald-variablefont-wght-webfont.woff2") format("woff2-variations");
  font-weight: 200 700;
  font-display: swap;
}
@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSans-VariableFont-wdth-wght.woff2") format(‘woff2 supports variations’), url("../fonts/OpenSans-VariableFont-wdth-wght.woff2") format("woff2-variations");
  font-weight: 300 800;
  font-display: swap;
}
@font-face {
  font-family: "HeaderFallbackMac";
  src: local("Arial Narrow Bold");
  font-weight: 700 !important;
  size-adjust: 105%;
  ascent-override: 108%;
  descent-override: 20%;
  line-gap-override: 6%;
}
@font-face {
  font-family: "HeaderFallbackWin";
  src: local("Franklin Gothic Demi Cond");
  size-adjust: 110%;
  ascent-override: 108%;
  descent-override: 20%;
  line-gap-override: 6%;
}
:root {
  --textfont-wght: 300;
  --header-wght: 800;
  --menu-wght:600;
}

body {
  margin: 0;
  padding: 0;
  background: #fcfcfc;
}

p,
li,
td {
  font-variation-settings: "wght" var(--textfont-wght);
}

p {
  line-height: 1.5;
  margin: 0.6rem 0;
}

h1, .h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-variation-settings: "wght" var(--header-wght);
}

.bi {
  fill: currentcolor;
}

h1, .h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
.search-drop-title {
  font-family: "Oswald", "HeaderFallBackMac", "HeaderFallBackWin", sans-serif !important;
}

@media (min-width: 992px) {
  h1, .h1 {
    font-size: 2.3rem;
  }
}

@media (min-width: 992px) {
  h2, .h2 {
    font-size: 1.9rem;
  }
}

@media (min-width: 992px) {
  h3, .h3 {
    font-size: 1.65em;
  }
}

@media (min-width: 992px) {
  h4, .h4 {
    font-size: 1.4rem;
  }
}

@media (min-width: 992px) {
  h5, .h5 {
    font-size: 1.2rem;
  }
}

a {
  color: #485fae;
  font-weight: 500;
}

.elect-home {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}

@media (min-width: 992px) {
  .elect-wide {
    padding-left: 14vw;
    padding-right: 14vw;
    max-width: 1600px;
  }
}

.img-responsive {
  width: 100% !important;
  padding-bottom: 0.5rem;
}

.manual-language-disclaimer {
  padding: 0.5rem;
  font-size: 0.9rem;
  margin-bottom: 1rem;
  margin-top: 0.8rem;
  background: #e8f3ff;
  border: 1px solid #26366a;
}
.manual-language-disclaimer .cell {
  padding: 10px 20px;
}
.manual-language-disclaimer .cell h1, .manual-language-disclaimer .cell .h1,
.manual-language-disclaimer .cell h2,
.manual-language-disclaimer .cell .h2,
.manual-language-disclaimer .cell h3,
.manual-language-disclaimer .cell .h3,
.manual-language-disclaimer .cell h4,
.manual-language-disclaimer .cell .h4,
.manual-language-disclaimer .cell h5,
.manual-language-disclaimer .cell .h5 {
  text-align: center;
  color: #0a1535;
  font-size: 1.4rem;
}
.manual-language-disclaimer .cell p {
  text-align: center;
}
.manual-language-disclaimer .cell small, .manual-language-disclaimer .cell .small {
  text-align: center;
  font-weight: normal;
}
@media screen and (max-width: 64em) {
  .manual-language-disclaimer .cell small, .manual-language-disclaimer .cell .small {
    font-size: 0.8rem;
  }
}

.content-box-component {
  padding: 0.7rem 1rem 0;
  margin-bottom: 1rem;
}

#quick-link-drop {
  padding: 3vw 10vw;
}
#quick-link-drop ul {
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 575.98px) {
  #quick-link-drop ul {
    width: 90%;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  #quick-link-drop ul {
    width: 70%;
  }
}
@media (min-width: 992px) {
  #quick-link-drop ul {
    width: 30%;
  }
}

html {
  visibility: visible;
  opacity: 1;
}

html {
  scroll-behavior: smooth;
}

body {
  position: relative;
}

.scrolltop-wrap {
  box-sizing: border-box;
  position: absolute;
  top: 12rem;
  right: 2rem;
  bottom: 0;
  pointer-events: none;
  backface-visibility: hidden;
}
.scrolltop-wrap #scrolltop-bg {
  fill: #a5beda;
}
.scrolltop-wrap #scrolltop-arrow {
  fill: white;
}
.scrolltop-wrap a:hover #scrolltop-bg {
  fill: #c2d3e6;
}
.scrolltop-wrap a:hover #scrolltop-arrow {
  fill: white;
}
@supports (-moz-appearance: meterbar) {
  .scrolltop-wrap {
    clip: rect(0, 3rem, auto, 0);
  }
}
.scrolltop-wrap a {
  position: fixed;
  position: sticky;
  top: -5rem;
  width: 3rem;
  height: 3rem;
  margin-bottom: -5rem;
  transform: translateY(100vh);
  backface-visibility: hidden;
  display: inline-block;
  text-decoration: none;
  user-select: none;
  pointer-events: all;
  outline: none;
  overflow: hidden;
}
.scrolltop-wrap a svg {
  display: block;
  border-radius: 50%;
  width: 100%;
  height: 100%;
}
.scrolltop-wrap a svg path {
  transition: all 0.1s;
}
.scrolltop-wrap a #scrolltop-arrow {
  transform: scale(0.66);
  transform-origin: center;
}
@media print {
  .scrolltop-wrap {
    display: none !important;
  }
}

/*html {
  overflow-y: scroll;
  overflow-x: hidden;
  &::before {
    content: "";
    display: block;
    backface-visibility: hidden;
    position: fixed;
    top: 0;
    right: -5px;
    bottom: 0;
    left: -5px;
    background: url("https://images.pexels.com/photos/62693/pexels-photo-62693.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260")
      center center no-repeat;
    background-size: cover;
    filter: brightness(1.14) blur(2px);
  }
} */