.language-disclaimer {
    padding-left:10%;
    padding-right:10%;
    margin-top:10px;
    margin-bottom:10px;
    width: 100%;
    .row {
        background:$blue-lighter;
        padding:10px 20px;
        h1,
        h2,
        h3,
        h4,
        h5,
        .disclaimer-header { 
            text-align:center;
            color:$gray-darkest;
            font-family: 'Oswald', 'HeaderFallBackMac', 'HeaderFallBackWin', sans-serif;
            font-weight: bold;
            font-variation-settings: 'wght' 600;
            font-size: 1.25rem;
            line-height: 1.4;
            margin-bottom: 0.5rem;
            margin-top: 0;
            width: 100%;
        }
        p {
            a {
                color: $blue-darker;
            }
        }
    }
}