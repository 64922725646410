
/* Footer */
.footercontainer {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 2000px;
      background:$black2;
      padding: 20px 0;
}
footer {
    color: $white;
    margin: 1px auto;
    font-size: 13px;   
    a {
        color: $white !important;
        font-size: 14px;
        font-weight: 400;
        font-variation-settings: 'wght' 400;
        border-bottom: 1px solid $blue2;
        text-decoration: none;
        &:visited {
            color: $white;
        }
        &:hover {
            color: $blue-lightest !important;
            border-bottom: 1px dotted $blue-lightest;
            font-weight:600;
            font-variation-settings: 'wght' 600;
        }        
    }
    li {
        padding-bottom: .5rem;
        @include media-breakpoint-down(sm) {
            padding-top: .2rem;
        }
        a {
            color: $white !important;
            font-weight: 400;
            font-variation-settings: 'wght' 400;
            border-bottom: 1px solid $blue2;
            text-decoration: none;
            &:visited {
                color: $white;
            }
            &:hover {
                color: $blue-lightest !important;
                border-bottom: 1px dotted $blue-lightest;
                font-weight:600;
                font-variation-settings: 'wght' 600;
            }        
        }
    }
    .footerfeature_info {
        padding: 10px 30px;
        h3,
        .footer-header {
            color: $blue-lighter;
            font-size: 19px!important;
            padding-bottom: 10px!important;
            margin: 5px auto 2px 0!important;
            line-height: 1.5!important;
            font-weight: 600;
            font-variation-settings: 'wght' 600;
            font-family: 'Oswald', 'HeaderFallBackMac', 'HeaderFallBackWin', sans-serif;
        }   
        p {
            color: $white;
            font-weight: 600!important;
            font-variation-settings: 'wght' 600;
            padding-bottom: 10px !important;
            margin: 5px auto 2px 0!important;
            line-height: 18px !important;
        }
        ul {
            margin-left: 10px;
            list-style: none;
        }
    }

    @media screen and (min-width:64.063em) and (max-width:90em) {
        .footerfeature-left {
           margin-left: -50px;
        }
        .subfooter {
            margin-bottom: 0;
        }
    }
    @media only screen and (min-width: 64.063em) {
        .footerfeature-left {
            margin-left: 0;
        }
        .footerfeature-info {
            padding: 10px 20px!important;
        }
    }

    .subfooter {
        color: $white;
        text-align: center;
        margin-bottom: 15px;
        display: inline-block;
        p {
            margin-bottom: 0px !important;
            font-size: 13px !important;
        }
        a {
            &:link {
                color: $blue-lightest !important;
                border-bottom: 1px dotted$blue-lightest;
            }
            &:visited {
                color: $blue-lightest!important;
            }
            &:hover  {
                color: $red-lighter!important;
                font-weight:bold;
                font-variation-settings: 'wght' 600;
                border-bottom: 1px dotted $red-lighter;
            }
            &:active,
            &:focus {
                color: $gray-lightest!important;
                font-weight:bold;
                font-variation-settings: 'wght' 600;
                border-bottom: 1px dotted $gray-lightest;
            }
        }
    }

    .socialmedia {
        list-style-type: none;
        @include media-breakpoint-down(md) {
            margin-left:2vw;
            margin-right:auto;
            padding-left:0;
        }
        li {
            background-image: url('/assets/img/socialsprite.png');
            background-repeat: no-repeat;
            display: inline-block;
            width: 45px;
            height: 45px;
            @include media-breakpoint-down(md) {
                margin: 10px 5px 5px 10px;
            }
            @include media-breakpoint-up(lg) {
                margin: 10px 5px 5px 10px;
            }
            a {
                display: block;
                height: 100%;
                width: 100%;
                text-indent: -9999px;
                overflow: hidden;
                border-bottom: none!important;
                &:hover {
                    border-bottom: 0px;
                }            
            }       
        }
    }
    h3.social-header {
        margin-left: 24px!important;
    }
    .facebook {
        background-position: 1px 3px;
        transition: all .3s ease-in-out;
        text-decoration: none!important;
        &:hover {
            background-position: 1px -42px;        
        }
    }

    .twitter {
        background-position: -44px 3px;
        transition: all .3s ease-in-out;
        text-decoration: none!important;
        &:hover {
            background-position: -44px -42px;       
        }
    }
    .youtube {
        background-position: -87px 3px;
        transition: all .3s ease-in-out;
        text-decoration: none!important;
        &:hover {
            background-position: -87px -42px;
        }
    }
    .linkedin {
        background-position: -133px 3px;
        transition: all .3s ease-in-out;
        text-decoration: none!important;
        &:hover {
            background-position: -133px -42px;        
        }
    }
    .instagram {
        background-position: -226px 3px;
        transition: all .3s ease-in-out;
        text-decoration: none!important;
        &:hover {
            background-position: -226px -42px;        
        }
    }
    .omegalogo {
        @include media-breakpoint-down(sm) {
            display: none;
        }
        img {
            vertical-align: text-top;
            max-width: 150px;
            aspect-ratio: 1 / 1;
            @include media-breakpoint-up(md) {
                float: right;
                margin-right: 30%;
                margin-left: auto;
                margin-top: -100px;
            }
        }
    }
}