
.breadcrumbs-left-nav {
    @include media-breakpoint-down(md) {
        padding: 0.2rem 0px 0.25rem 0.4rem!important; 
    }
    @include media-breakpoint-up(lg) {
        padding: 1.4rem 0px 0.4rem 0.4rem!important; 
    }
}

.left-nav-mobile-bar {
    margin-bottom: .8rem;   
}
.section-dropdown button {
    &:active,
    &:hover,
    &:focus {
        box-shadow: none!important;
    }
}
.left-nav-button,
.section-dropdown .dropdown-toggle {
    color: $white!important;
    background:$red-darker!important;
    font-weight: 600!important;
    font-variation-settings: 'wght' 600;
    border:0; 
    padding:0;
    margin-bottom: 0.6rem;
    margin-top: 0.4rem!important;
    &:hover,
    &:focus,
    &:active {
       padding:0;
       color: $yellow-lightest;
       -webkit-box-shadow: 0!important;
       box-shadow: 0!important;
       outline: 0!important;
       border: 0!important;
    }   
   &::after {
       content:""!important;
       color:$red-darker;
    }
    .button-text {
        &:hover,
        &:focus,
        &:active {
            color:$yellow-lightest!important;
        }
    }
    .fa {
        font-size: 2rem;
        margin-bottom: -7px;
        padding-left:.15rem;
        vertical-align:text-bottom;
        color:$yellow-lighter;  
    }
}
.section-dropdown-menu {
    width: 82vw;
    border-radius: 0!important;
    margin: 0.5rem 0 0 0px!important;
    padding: 0.5rem 0 0.5rem 1rem!important;
    background: $blue-lightest;
    -webkit-box-shadow: -21px 10px 19px -8px rgba(163,163,163,0.4);
    -moz-box-shadow: -21px 10px 19px -8px rgba(163,163,163,0.4);
    box-shadow: -21px 10px 19px -8px rgba(163,163,163,0.4);
    li.dropdown-item {
        margin-left:0!important;
        margin-right:0!important;
        width:100%;
        border-bottom: 1px solid $white!important;
        &:active,
        &:hover,
        &:focus {
            background:$blue-lighter!important;
        }
        &:last-child {
            border-bottom: 0!important;
            a {
                border-bottom:0!important;
            }
        }
        a {
            padding:0.2rem 0.8rem!important;
            width:100%;
            border-bottom: 1px solid $white;
            text-decoration: none;
            font-size: .9rem;
            color: $blue-darker;
            &:hover,
            &:focus {
                color: $black!important;
                background:$blue-lighter!important;
            }
        }
    }
}
.section-dropdown-menu a:last-of-type {
    border-bottom:0!important;
}
.offcanvas-start {
    @include media-breakpoint-down(md) {
            z-index: 10000!important;
    }
}
.side-menu {
    @include media-breakpoint-only(lg) {
        width: 24vw;
        padding-left: 8vw;
        padding-right: 1rem;
        float: left;
        margin-top: 2.4rem;
    }
    @include media-breakpoint-only(xl) {
        width: 16vw;
        padding-left: 5vw;
        padding-right: 1rem;
        float: left;
        margin-top: 2.4rem;
    }
    @include media-breakpoint-up(xxl) {
        width: 14vw;
        padding-left: 3vw;
        padding-right: 1rem;
        float: left;
        margin-top: 2.4rem;
    }
    @media screen and (min-width: 2360px) {
        margin-left: -10vw!important;
    }
}
.offcanvas-header {
    @include media-breakpoint-up(lg) {
        display: block!important;
        padding:0!important;
    }
}
.main-text {
    min-height:300px;
    @include media-breakpoint-down(md) {
        padding-left:8vw;
        padding-right:8vw;
    }
    @include media-breakpoint-up(lg) {
        margin-left: 25vw;
        margin-right: 10vw;
        margin-top:1rem;
        max-width: 1000px;
    }
    .breadcrumbs {
        line-height: 1.4;
        @include media-breakpoint-down(md) {
            margin-left: -2.0rem!important;
        }
        @include media-breakpoint-up(lg) {
            padding: 1rem 0 0.4rem 0rem!important;
            margin-bottom: .8rem;
        } 
        ul {
            margin-left: -1.4rem;
        }
    }
}
.left-nav-header {
    margin: 0;
    padding: 0;
    background: $blue-darker;  
    @include media-breakpoint-down(md) {
        margin-top:-1rem;
        padding: 2rem 0 0 0;
       // width: 100%;
        .close-check {
            margin-left: auto;
            background: transparent;
            color: $white;
            width: 1rem;
            margin-top: -0.3rem;
            font-size: 1.2rem;
            padding-right:3.2rem;
            float:right;
        }
    }
    @include media-breakpoint-up(lg) {
        width:15vw;
        border: ipx solid $blue-darker;
    }
    h2,
    .sidenav-header-text {
        color:$white;
        width:100%;
        font-weight: 500;
        font-variation-settings: 'wght' 500;
        letter-spacing: .05rem;
        font-family: 'Oswald',  'HeaderFallBackMac', 'HeaderFallBackWin', sans-serif!important;
        @include media-breakpoint-down(md) {
            padding-top: .5rem;
            padding-left: 8vw;
            padding-bottom: .5rem;
            line-height: 1.5rem;
            margin: 0 auto -1.2rem 0;
            font-size: 1.4rem;
            letter-spacing: .05rem;
        }
        @include media-breakpoint-up(lg) {
            font-size: 1.2rem;
            padding: 0.5em;
        }
        a {
            background: $blue-darker;  
            padding: .5em;
            display: block;
            color: $white; 
            border: 1px solid $blue-darkest;
            font-size: 1rem;
            font-weight: bold;  
            font-variation-settings: 'wght' 600;
            color: $white;   
            button {
                background: none!important;
                border-radius: 0;
                cursor: pointer;
                font-size: 1.5rem;
                padding: 0!important;
                text-align: right;
                float:right!important;
                margin-right:3.2rem;
                margin-left:auto;
                i {
                    color:$white;
                    font-size: 1.5rem;
                }
            }	
        }
        @include media-breakpoint-only(md) {
            margin: 12px 0 0 0;
        }
    }
}

.left-nav-body {
    border-left: 1px solid $white;
    border-right: 1px solid $white;
    padding: 1rem 4vw 0;
    list-style-type: none;
    list-style-position: inside;
    font-family: "Roboto", sans-serif;
    @include media-breakpoint-down(md) {
        padding: 1rem 4vw 0; 
    }
    @include media-breakpoint-up(lg) {
        padding: 0;
        width: 15vw;
        margin-top:0!important; 
    }
    ul {
        list-style-type: none;
        margin-left:0;
        padding-left:0;
        margin-top:0;
        border:0;
        @include media-breakpoint-down(md) {
            margin-top:0;
        }
        @include media-breakpoint-up(lg) {
            margin-top:0!important;
            width:15vw;
            border: 1px solid $gray-lightest;
            padding-top:0!important;
            
        }
        li.nav-item {
            margin: 0;
            font-weight: 600;
            font-variation-settings: 'wght' 600;
            @include media-breakpoint-down(md) {
                font-size:1.2rem;
                border-bottom: 1px solid $gray-lightest;
            }
            @include media-breakpoint-up(lg) {
                font-size: 0.875rem;
                border-top: 1px solid $gray-lightest;
            }
            &:active,
            &:hover, 
            &:focus {
                background: $gray-lightest;
            }
            &:first-child {
                //margin-top:-.5rem;
                border-top:0;
                @include media-breakpoint-down(md) {
                    padding-top:.3rem;
                }
                @include media-breakpoint-up(lg) {
                    padding-top:.4rem;
                }
            }
            .selected:after {
                font-family: FontAwesome;
                content: "\f04b ";
                margin-right: 1em;
                float: right;
            }
            a,
            a.nav-link {
                text-decoration: none;
                border-bottom: 1px solid $white;
                margin: 0;
                @include media-breakpoint-down(md) {
                    padding: .5em .75em;
                }
                @include media-breakpoint-up(lg) {
                    padding: .6rem;
                }
                &:active,
                &:hover,
                &:focus {
                    color: $red-darker;
                    border-bottom: 1px dotted $red;
                    font-weight: bold;
                    font-variation-settings: 'wght' 600;
                }
                &:after {
                    font-family: FontAwesome;
                    content: "\f04b ";
                    float: right;
                }
            }
            a:not(.button) {
                display: block;
                color: $black; 
                &:active,
                &:hover,
                &:focus {
                    background:$gray-lightest;
                    font-weight: normal;
                    border-bottom:0px;  
                }
            }
        }
    }
    span[class^="currentbranch"] a {
        background: $white;
    }
}