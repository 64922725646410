.cta-box, 
    .cta-box-mobile,
    .cta-box-side, 
    .cta-box-alpha,
    .cta-box-grey, 
    .cta-box-yelo {
        font-size: 0.9rem;
        border: 2px solid $gray-lighter;
        padding: 10px;
        display: block;
        margin: 5px 5px 15px 5px;
    }

    .cta-box-grey {
        background:$gray-lightest;
        margin-bottom:35px;
    }
    .cta-box-yelo {
        background:$yellow-lightest;
    }
    
    .cta-box-alpha {
        background:$white;        
        background-repeat: no-repeat;
        background-position: left top;
        p {
            margin-left:44px;
            font-size: 1.0rem;
            margin-top: .5rem;
        }
    }

    .dark-blue-background{
        background: $blue-darker;
        min-height: 175px;
    }

    .light-blue-background{
        background: $blue-lighter;
        min-height: 175px;  
    }

    .left-box {
        float: left;
        padding-right: 25px;
    }