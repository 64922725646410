.feature-icon-section {
    padding: 1rem;
    width: 100%;
    margin-bottom: 1.2rem;
    @media screen and (min-width: 90em) {
        padding: 0.8rem 1.5rem 1rem!important;
    }
    .align-left {
        text-align: left;
    }
    .align-right {
        text-align: right;
    }
    .align-center {
        text-align: center;
    }
    h1,
    h2
    h3 {
        width: 100%;
        font-weight: 600!important;
        font-variation-settings: 'wght' 600;
        @include media-breakpoint-down(md) {
            font-size: 1.4rem;
            padding-bottom: 0.3rem;
            margin-bottom: 1rem!important;
        }
        @include media-breakpoint-up(lg) {
            margin-bottom:0!important;
        }
     }
     a {
         width: 100%;
     }
    .feature-icon-cell {
        text-align: center;
        @include media-breakpoint-down(md)  {
            padding: 0;
            margin-bottom: .7rem!important;
        }
        @include media-breakpoint-up(lg)  {
            padding:.5rem!important;
            margin: .5rem!important;
            display: flex;
            flex-direction:column;
            justify-content: space-between;
            align-items: center; 
        }
        @media screen and (min-device-width:768px) and (max-device-width: 1099px) and (orientation:portrait) {
            padding: 1vw;
            text-align:center!important;
        }
        @media screen and (min-device-width:768px) and (max-device-width: 1099px) and (orientation:landscape) {
            padding: 2vw;
            text-align: center!important;
        }
        a {
            text-decoration: none!important;
            &:active,
            &:hover,
            &:focus {
                text-decoration: underline;
                color:$blue-darker;
            }
        }
        .icon-image {
            @include media-breakpoint-down(md)  {
                padding: 14px 0 14px 4px!important;
                display:flex;
            }
            @include media-breakpoint-up(lg) {
                padding: .3rem 0 0;
                margin:0;
            }
        }
        img,
        svg {
            height:auto;
            margin-left:auto;
            margin-right: auto;
            text-align:center;
            @include media-breakpoint-down(md)  {
                margin: -4px 6px 0 0;
                padding: 3px 12px 0 12px;
            }
            @include media-breakpoint-up(lg) {
                width: auto;
                height: 80px;
                margin:0.5rem auto;
            }
            &:active, 
            &:hover,
            &:focus {
               filter: blur(1px) brightness(80%) contrast(60%) invert(29%) opacity(77%) saturate(47%);
            }
        }
        i {
            font-size: 6rem;
            color: inherit;
            text-align:center;
            @include media-breakpoint-down(md)  {
                font-size: 6rem;
                justify-self: center;
                align-self: center;
            }
            @include media-breakpoint-up(lg) {
                font-size: 7rem;
                padding-bottom: 2.5rem;
            }
            &:active, 
            &:hover,
            &:focus {
               filter: blur(1px) brightness(80%) contrast(60%) invert(29%) opacity(77%) saturate(47%);
            }
        }
        h4,
        .icon-title {
            width: 100%;
            font-weight:600;
            font-variation-settings: 'wght' 600;
            @include media-breakpoint-down(md)  {
                text-align: left;
                font-size: 1.2rem;
                padding-top:.5rem;
                padding-bottom:0;
                margin-top:auto;
                margin-bottom:auto;
                padding-left: 10px;
                padding-right: 6px;
            }
            @include media-breakpoint-up(lg) {
                text-align:center;
                font-size: 1rem;
                padding-top:0;
                padding-left: .5rem;
                padding-right: .5rem;
            }
            @media screen and (min-device-width:768px) and (max-device-width: 1099px) and (orientation:portrait) {
                margin-top: -1.5rem;
                text-align: center;
                padding-left:0;
                padding-right:0;
            }
            @media screen and (min-device-width:768px) and (max-device-width: 1099px) and (orientation:landscape) {
                margin-top: -1.5rem;
                text-align: center;
                padding-left:0;
                padding-right:0;
            }
        }
        .icon-title {
            font-family: 'Oswald', 'HeaderFallBackMac', 'HeaderFallBackWin', sans-serif;
            font-weight: 500;
            font-variation-settings: 'wght' 500;
            text-align: center;
            color: $blue-darker!important;
            text-decoration: none!important;
            &:active,
            &:hover,
            &:focus {
                text-decoration: underline;
                color: $blue;

            }
        }
        .icon-text {
            @include media-breakpoint-down(md)  {
                padding-top:16px;
            }
            @media screen and (min-device-width:810px) and (max-device-width: 1099px) and (orientation:portrait) {
                padding-left: 6px!important;
             }
             @media screen and (min-device-width:810px) and (max-device-width: 1099px) and (orientation:landscape) {
                padding-left: 6px!important;
             }
             @include media-breakpoint-up(lg)  {
              padding-bottom: .5rem;
             }
        }
        .text-div {
            text-align: left;
            @include media-breakpoint-down(md)  {
                padding-right: 10px;
            }
            p {
                line-height: 1.2;
                @include media-breakpoint-down(md)  {
                    font-size: 0.8rem;
                    padding-left: 10px;
                    padding-right: 6px;
                }
                @include media-breakpoint-up(lg)  {
                    width: 80%;
                    margin-left:auto;
                    margin-right: auto;
                    font-size: 0.8rem;
                }
                @media screen and (min-device-width:810px) and (max-device-width: 1099px) and (orientation:portrait) {
                    width: 100%!important;
                 }
                 @media screen and (min-device-width:810px) and (max-device-width: 1099px) and (orientation:landscape) {
                    width: 100%!important;
                 }
            }
        }
        hr.feature-icon-hr {
            border-top: 1px $gray-lighter solid;
            margin-right:4vw;
            margin-left: 4vw;
            margin-top:0;
            @include media-breakpoint-down(md)  {
                margin-top: -10px;
            }
        }
    }  
    col-md-4 {
        i {
            @include media-breakpoint-up(lg)  {
                font-size:  8rem;
            }
        }
    }
}
.rounded-shadow {
    .row {
        .feature-icon-cell {
            background: $white!important;
            box-shadow: 0px 0px 30px $black-opaque-20!important;
            border-radius: 12px!important;
         }
    }
}

// icon section for lower-level pagegs with left menu
.lower-pg-icon-section {
    padding: 1.5rem;
    width: 100%;
    .align-left {
        text-align: left;
    }
    .align-right {
        text-align: right;
    }
    .align-center {
        text-align: center;
    }
    h1,
    h2
    h3 {
        width: 100%;
        font-weight: 600!important;
        font-variation-settings: 'wght' 600;
        @include media-breakpoint-down(md) {
            margin-bottom: 1rem!important;
        }
     }
     a {
         width: 100%;
     }
    .feature-icon-cell {
        text-align: center;
        @include media-breakpoint-down(md)  {
            padding: 0;
            margin-bottom: .7rem!important;
        }
        @include media-breakpoint-up(lg)  {
            padding:1rem;
            display: flex;
            flex-direction:column;
            justify-content: space-between;
            align-items: center; 
        }
        @media screen and (min-device-width:768px) and (max-device-width: 1099px) and (orientation:portrait) {
            padding: 1vw;
            text-align:center!important;
        }
        @media screen and (min-device-width:768px) and (max-device-width: 1099px) and (orientation:landscape) {
            padding: 2vw;
            text-align: center!important;
        }
        a {
            text-decoration: none!important;
            &:active,
            &:hover,
            &:focus {
                text-decoration: underline;
                color:$blue;
            }
        }
        .icon-image {
            @include media-breakpoint-down(md)  {
                padding: .8rem 0 .8rem .2rem!important;
                display:flex;
            }
            @include media-breakpoint-up(lg) {
                padding-top: .7rem;
            }
        }
        img,
        svg {
            height:auto;
            margin-left:auto;
            margin-right: auto;
            text-align:center;
            @include media-breakpoint-down(md)  {
                margin: -4px 6px 10px 0;
                padding: 10px 12px;
            }
            @include media-breakpoint-up(lg)  {
                width: auto;
                height: 80px;
                margin: 28px auto;
            }
            @media screen and (min-device-width:768px) and (max-device-width: 1099px) and (orientation:portrait) {
                width: 65%;
                margin:auto auto -10px auto;
            }
            @media screen and (min-device-width:768px) and (max-device-width: 1099px) and (orientation:landscape) {
                width: 75%;
                margin:auto;
            }
            &:active, 
            &:hover,
            &:focus {
                transform: translateY(-10px);
                filter: invert(24%) sepia(50%) saturate(0%) hue-rotate(275deg) brightness(102%) contrast(85%);
            }
        }
        i {
            font-size: 6rem;
            color: inherit;
            text-align:center;
            @include media-breakpoint-down(md)  {
                font-size: 6rem;
                justify-self: center;
                align-self: center;
            }
            @include media-breakpoint-up(lg)  {
                font-size: 7rem;
                padding-bottom: 2.5rem;
            }
            &:active, 
            &:hover,
            &:focus {
                transform: translateY(-10px);
                filter: invert(24%) sepia(50%) saturate(0%) hue-rotate(275deg) brightness(102%) contrast(85%);
            }
        }
        .icon-text {
            @include media-breakpoint-down(md)  {
                padding-top:16px;
            }
            @media screen and (min-device-width:810px) and (max-device-width: 1099px) and (orientation:portrait) {
                padding-left: 6px!important;
             }
             @media screen and (min-device-width:810px) and (max-device-width: 1099px) and (orientation:landscape) {
                padding-left: 6px!important;
             }
             h4,
             .icon-title {
                 width: 100%;
                 font-weight:600;
                 font-variation-settings: 'wght' 600;
                 @include media-breakpoint-down(md)  {
                     text-align: left;
                     font-size: 0.9rem;
                     padding-top:14px;
                     padding-bottom:0;
                     margin-bottom: 6px;
                     padding-left: 10px;
                     padding-right: 6px;
                 }
                 @include media-breakpoint-up(lg) {
                     text-align:center;
                     font-size: 1.1rem;
                     padding-top:.9rem;
                     padding-left: 1rem;
                     padding-right: 1rem;
                 }
                 @media screen and (min-device-width:768px) and (max-device-width: 1099px) and (orientation:portrait) {
                     margin-top: -1.5rem;
                     text-align: center;
                     padding-left:0;
                     padding-right:0;
                 }
                 @media screen and (min-device-width:768px) and (max-device-width: 1099px) and (orientation:landscape) {
                     margin-top: -1.5rem;
                     text-align: center;
                     padding-left:0;
                     padding-right:0;
                 }
             }
             .icon-title {
                 font-family: 'Oswald', 'HeaderFallBackMac', 'HeaderFallBackWin', sans-serif;
                 font-weight: 500;
                 font-variation-settings: 'wght' 500;
                 text-align: center;            
                 color: $blue-darker!important;
                 text-decoration: none!important;
                 &:active,
                 &:hover,
                 &:focus {
                     text-decoration: underline;
                     color: $blue;
     
                 }
             }      
            .text-div {
                text-align: left;
                @include media-breakpoint-down(md)  {
                    padding-right: 10px;
                }
                p {
                    @include media-breakpoint-down(md)  {
                        font-size: 0.8rem;
                        padding-left: 10px;
                        padding-right: 6px;
                    }
                    @include media-breakpoint-up(lg)  {
                        width: 80%;
                        margin-left:auto;
                        margin-right: auto;
                    }
                    @media screen and (min-device-width:810px) and (max-device-width: 1099px) and (orientation:portrait) {
                        width: 100%!important;
                    }
                    @media screen and (min-device-width:810px) and (max-device-width: 1099px) and (orientation:landscape) {
                        width: 100%!important;
                    }
                }
            }
            hr.feature-icon-hr {
                border-top: 1px $gray-lighter solid;
                margin-right:4vw;
                margin-left: 4vw;
                margin-top:0;
                @include media-breakpoint-down(md)  {
                    margin-top: -10px;
                }
            }
        }  
        .col-md-4 {
            i {
                @include media-breakpoint-up(lg)  {
                    font-size:  8rem;
                }
            }
        }
    }
    .rounded-shadow {
        .feature-icon-cell{
        .container {
            background: $white;
            box-shadow: 0px 0px 30px $black-opaque-20;
            border-radius: 12px;
            }
        }
    }
}