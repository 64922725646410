.breadcrumbs-nav,
.breadcrumbs {
    display: block;
    padding: 0;
    overflow: hidden;
    margin-left: -2.3rem;
    border:0;
    @include media-breakpoint-down(sm) {
        padding: 0 0 .25rem;
    }
    @include media-breakpoint-only(md) {
        margin-top: -0.3rem;
        margin-left: -1rem;
        margin-bottom: 0;
    }
    @include media-breakpoint-up(lg) {
        display: block;
        margin-left: -2.3rem;
        overflow: hidden;
        padding: 0.2rem 0;
        margin-bottom: .8rem;     
    }

    ul {
        list-style: none!important;
        list-style-type: none!important;
        @include media-breakpoint-up(lg) {
            margin-left:0;
            margin-top: 1rem;
        }
    }
    li,
    span {
        margin: 0;
        float: left;
        font-size: 0.6875rem;
        line-height: 0.6875rem;
        color: $gray-darker;
        padding-right: 0.4rem;
        @include media-breakpoint-up(lg) {
            margin: 0;
            float: left;
            font-size: 0.6875rem;
            line-height: 0.6875rem;
            color: $gray-darkest;
        }
    }
    a {
        color: $gray-darker;
        &:hover {
            color: $black;
            border-bottom: 1px dotted $gray-darkest;
        }
        @include media-breakpoint-up(lg){
            &:hover,
            &:active,
            &:focus {
                color: $gray-darkest;
                border-bottom: 1px dotted $gray-darkest;
            }
        }
    } 
    .current {
        cursor: default;
        color: $gray-darkest;
        a {
            cursor: default;
            color: $gray-darkest;
        }
    }
     a {
        color: $black;
        &:first-child {
            &:before {
                content: "";
                margin: 0;
            }
        }
    }

    .current,
    .current:hover,
    .current:hover a,
    .current:focus,
    .current:focus a {
        text-decoration: none;
    }
}
.wide,
.elect-wide {
    .breadcrumbs {
        @include media-breakpoint-down(md) {
            margin-top:1.2rem;
        }
        @include media-breakpoint-up(lg) {
            margin-top:1.2rem;
            margin-bottom: 1.2rem;
            margin-left:0;
            padding-left:0;
            ul {
                margin-left:0;
                padding-left:0;
            }
        }
    }
}
