.election-party {
    @include media-breakpoint-up(xxl) {
        padding-left: 11vw!important; 
        padding-right:11vw!important;
    }
    @include media-breakpoint-only(xl) {
        padding-left: 10vw!important; 
        padding-right:10vw!important;
    }
    @include media-breakpoint-only(lg) {
        padding-left: 10vw!important; 
        padding-right:10vw!important;
    }
    @include media-breakpoint-only(md) {
        padding-left:15vw!important;
        padding-right: 15vw!important;
    }
    @include media-breakpoint-down(sm) {
        padding-left:12vw!important;
        padding-right: 12vw!important;
        padding-top:0!important;
        margin-top:0!important;
    }
    
    @media screen and (min-width:1800px) {
        padding-left: 16vw!important; 
        padding-right:16vw!important;
    }
    .left-button,
    .right-button {
        padding:0 1rem;
    }
    .middle-col {
        text-align:center;
        padding-top:1.5rem;
        @include media-breakpoint-between(lg,xxl) {
            padding-bottom:0;
        }
        h1.pres {
            font-size: 3.6vw!important;
            letter-spacing:-.04rem!important;
            @include media-breakpoint-only(md) {
                font-size: 4rem!important;
            }
            @include media-breakpoint-down(sm) {
                font-size: 3.6rem!important;
            }
        }
        h2.prim {
            font-size:2.5vw!important;
            line-height: 2.6rem!important;
            padding-top:0!important;
            margin-top:-.6rem;
            letter-spacing:-.05rem!important;
            @include media-breakpoint-only(md) {
                font-size: 2.8rem!important
            }
            @include media-breakpoint-down(sm) {
                font-size: 2.5rem!important
            }
        }
        img {
            @include media-breakpoint-up(lg) {
                padding: 0 3vw;
            }
        }
    }
    .second-row {
        text-align:center;
        margin-top: .5vw;
        padding-top:0;
        @include media-breakpoint-up(lg) {
            margin-top:-4.5vw;
        }
        div {
            padding-bottom: 1.2rem;
        }
    }
    .second-row,
    .results-row {
        div {
            text-align:center; 
            font-weight: 500;
            font-size: 1.7rem;
            @include media-breakpoint-only(lg) {
                font-size:1.6rem!important;
            }
            @include media-breakpoint-up(xl) {
                font-size:1.5vw!important;
            }
            button {
                margin-left:auto;
                margin-right:auto;
            }
        }
    }
}
@media (min-width: 2100px) {
    .election-party {
        padding-left: 20vw;
        padding-right: 20vw;
    }
    .election-party .left-button img,
    .election-party .right-button img {
        height:100%;
        width:auto;
        text-align:center;
    }
    .election-party .second-row {
        margin-top:0;
        padding-top:0;
    }
}
@media (min-width:1400px) {
    .election-party .middle-col img {
        padding-left:1vw;
        padding-right: 1vw;
    }
}
