header {
    background: $white!important;
    @include media-breakpoint-down(sm) {
        padding-top:3.2rem;
    }
    @include media-breakpoint-up(lg) {
        padding-top: 1.6rem;
    }   
}

.nav-container {
    background: $white;
    align-items: center;
    display: flex;
    flex-wrap: inherit;
    justify-content: space-between;
    @include media-breakpoint-down(sm) {
       height: 3rem;
    }
    @include media-breakpoint-down(md) {
      margin-top:-1.0rem;
    }
    @include media-breakpoint-up(lg) {
       margin-top: -1.8rem;

    }
}
.elect-header-container {
    background: $white!important;
    width:100vw;
    border-bottom: 1px solid $gray;
}
.navbar {
    @include media-breakpoint-only(md) {
        margin-top:.6rem;
        background: $white!important;
    }
    @include media-breakpoint-up(lg) {
        background: transparent!important;
    }
}
.navbar-collapse {
    @include media-breakpoint-down(md) {
        margin-top:-.25rem;
    }
    @include media-breakpoint-up(lg) {
        margin-left: -14vw;
        margin-top: 1rem;
    }
}
a.navbar-brand {
    z-index:100;
    @include media-breakpoint-down(md) {
        margin-top: -2rem;
    }
    @include media-breakpoint-only(md) {
        width: 28vw;
        padding-bottom:0;
    }
    @include media-breakpoint-up(lg) {
        margin-left:2vw;
        max-width: 30%;
        padding-top: 0;
    }
}
.navbar-logo {
    text-align:right;
    padding-right: 40px;
    @include media-breakpoint-down(sm) {
        max-width: 22vw;
        margin-left: -1.5rem;
    }
    @include media-breakpoint-only(md) {
        margin-left:-3rem;
        max-width: 70vw;
        padding-right: 100px;
    }
}
#logo-image {
    z-index:100;
    @include media-breakpoint-down(sm) {
        aspect-ratio:18/15;
        margin-left: 5vw;
        margin-top: 0;
        max-height: 3.6rem;
    }
    @include media-breakpoint-only(md) {
        aspect-ratio:18/15;
        margin-top: -2rem;
        width: 44%;
    }
    @include media-breakpoint-up(lg) {
        aspect-ratio: 30 / 15;
        margin-left: 4vw;
        margin-top: .3vw;
        padding-right: 3vw;
        width: 7.5vw;
    }     
}

    
.show {
    @include media-breakpoint-down(md){
        background: $white!important;
        margin-left: -3rem!important;
        padding-left: 3rem!important;
    }
}
.hamburger {
    background: $blue;
    color: $white;
    text-align: center;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    cursor: pointer;
    @include media-breakpoint-down(sm) {
        margin: 0 0 auto auto;
    }
    @include media-breakpoint-only(md) {
        margin: -3rem 0 auto auto;
    }
    i {
        color: $white;
        margin: auto auto auto -5px;
        font-size: 20px;
        line-height: 18px;
        width: auto;
        padding: 4px;
        font-weight: 300;
    }
    &:active,
    &:hover,
    &:focus {
        background: $blue2!important;
    }
    &:focus {
        outline:1;
    }
    &:active {
        color: $white;
    }    
}
.navbar-expand-lg {
    @include media-breakpoint-up(lg) {
        background: #fcfcfc;
        margin-left: -15px !important;
        padding-left: 0 !important;
        width: 100vw !important;
        flex-wrap: nowrap;
        justify-content: flex-start;
    }
}
.navbar-collapse.show {
    @include media-breakpoint-down(md) {
        background: $blue-lighter!important;
        margin: -.2rem -6vw auto -6vw!important;
        width: 100vw;
    }
    @include media-breakpoint-up(lg) {
        margin-top: .9rem;
    }
}

#menu {
    list-style-type:none;
    margin:0;
    padding:0;
    font-weight: 600;
    font-variation-settings: 'wght' 600;
    @include media-breakpoint-down(md) {
        margin-left:12vw;
        margin-top: 0.5rem!important;
    }
    li.nav-item {
        display:inline-block;
        font-family: 'Open Sans', sans-serif;
        font-variation-settings: "wght" var(--menu-wght);
        @include media-breakpoint-up(lg) {
            padding-left: 1vw;
            padding-right: 1vw;
        }
        @include media-breakpoint-only(lg) {
            font-size:.85rem;
            padding-left:1vw;
        }
        @include media-breakpoint-only(xl) {
            font-size:.95rem;
            padding-left:2vw;
        }
        @include media-breakpoint-up(xxl) {
            font-size:1.1rem;
            padding-left:2.5vw;
        }
        @include media-breakpoint-down(md) {
            border-bottom: 1px solid $white!important;
            margin-left: -7rem!important;
            padding-bottom: 0.2rem!important;
            padding-left: 5rem!important;
            padding-top: 0.2rem!important;
            margin-top: -0.2rem;
        }
        a {
            display:block;
            color: $blue-darker;
            letter-spacing:0.02rem;
            text-decoration: none;
            border-bottom: none;
            @include media-breakpoint-down(md) {
                background: none;
                &:hover,
                &:active,
                &:focus {
                color: $blue-darker!important;
                }
            }
            @include media-breakpoint-up(lg) {
                background: none!important;
                background: transparent!important;
                &:hover,
                &:active,
                &:focus {
                    color: $blue;
                }
            }
        }
        &:last-child {
            border-bottom: 0;
        }
        &:hover a,
        &:focus a,
        &:active a {
            color: $blue;
            border-bottom: none!important;
        } 
        .active {
            a {
                @include media-breakpoint-down(sm)  {
                    color: $blue;
                }
                @include media-breakpoint-up(md)  {
                    color: $blue;
                    &:hover,
                    &:focus,
                    &:active {
                        background: $white;
                        color: $blue-darker;
                    }
                }
            }
        }
        .dropdown-menu  {
            overflow: hidden;
            left:3rem!important;
            padding-left:0;
            background: $blue-lighter;
            @include media-breakpoint-down(md) {
                margin-bottom:-.2rem;
            }
            @include media-breakpoint-up(lg) {
                border-radius:0!important;
                border-top:6px solid $blue-darker;
                -webkit-box-shadow: 5px 5px 15px -1px $gray; 
                box-shadow: 5px 5px 15px -1px  $gray;
            }
            @include media-breakpoint-only(lg) {
                width: 24vw;
            }
            @include media-breakpoint-only(xl) {
                width: 21vw;
            }
            @include media-breakpoint-only(xxl) {
                width: 18vw;
            }
            &.show {
                @include media-breakpoint-down(md) {
                        border-width: 0!important;
                        margin-top:-.4rem;
                }
            }
            li.dropdown-item {
                @include media-breakpoint-down(md) {
                    font-size: .85rem!important;
                    padding:.4rem 0;
                }
                @include media-breakpoint-only(lg) {
                    margin-left:0!important;
                }
                @include media-breakpoint-up(lg) {
                    padding:.4rem 0 0 .5rem;
                    font-size: .8rem!important;
                }
                &:first-of-type {
                    margin-top:-.2rem;
                }
                &:last-of-type {
                    @include media-breakpoint-down(md) {
                        margin-bottom: .4rem;
                    }
                    @include media-breakpoint-up(lg) {
                        margin-bottom:0;
                    }
                }
                &:nth-last-child(-n + 2){
                    @include media-breakpoint-down(md) {
                        border-bottom: 0!important;
                    }
                }
                &:active,
                &:hover,
                &:focus {
                    background: $blue-lightest!important;
                }
                a {
                    font-weight: 600;
                    font-variation-settings: 'wght' 600;
                    padding-left:0;
                    cursor: pointer;
                    @include media-breakpoint-down(md) {
                        color: $gray-darker!important;
                        font-size: .85rem!important;
                        padding-left:1rem;
                    }
                    @include media-breakpoint-up(lg) {
                        color: $gray-darker;
                        width:100%;
                        padding: .2rem .5rem;
                    }
                    &:active,
                    &:hover,
                    &:focus {
                        @include media-breakpoint-down(md) {
                            color: $blue-darker!important;
                            background: $blue-lightest!important;
                        }
                        @include media-breakpoint-up(lg) {
                            // color: $gray-darkest!important;
                            color: $blue; 
                            background: $blue-lightest!important; 
                            transform: ease-out 250ms;
                        }                           
                    }
                }
            }
        }
    }
}

li {
    .fa-search {
        @include media-breakpoint-up(lg) {
            margin-top: 0;
            margin-left: 2.5rem;
            font-size: 1.7rem;
            color: $blue-darker;
            padding-top:0;
        }
    }
    .fa-times {
        @include media-breakpoint-up(lg) {
            margin-left: 2.7rem;
            margin-top: 0;
            padding-top: 0;
            font-size: 1.7rem;
            color: $blue-darker;
        }
    }
    &.d-lg-block {
        @include media-breakpoint-up(lg) {
            padding-top: .3rem;
        }
    } 
}


.search-button-mobile-div {
    @include media-breakpoint-down(sm) {
        left: 44vw;
        position: relative;
        top: -.6rem;
        width: 14vw;
    }
    @include media-breakpoint-only(md) {
        margin-left: 76vw;
        margin-top:-4.3rem;
        width: 12vw;
    }
    .fa-search {
        @include media-breakpoint-down(sm) {
            color: $blue-darker;
            margin: auto;
            font-size: 40px;
            width: auto;
            text-align: center;
            padding: 4px;
            font-weight: 300;
        }
        @include media-breakpoint-only(md) {
            margin-left: 32px;
            font-size: 2.6rem;
            color: $blue-darker;
            padding-top:0;
        }
    }
    .fa-times {
        @include media-breakpoint-down(sm) {
            color: $blue2;
            font-size: 40px;
            width: auto;
            text-align: center;
            padding: 4px;
            font-weight: 300;
        }
        @include media-breakpoint-only(md) {
           // margin-top: 8px;
            margin-left: 28px;
            padding-left:4px;
            font-size: 2.6rem;
            color: $blue2;
        }
    }
}
/*
.elect-title-bar {
    background: $white!important;
    margin-top: -6px;
    text-align: right;
    margin-right: 0;
    padding-left: 20px;
    padding-right:0;
    padding-top: 0;
    @include media-breakpoint-down(sm){
        height: 40px;
    }
    @include media-breakpoint-down(md) {
        background:transparent!important;
        background: none!important;
    }
    @include media-breakpoint-only(md) {
        height:65px;
        padding-left: 25px!important;
    }    
    @include media-breakpoint-up(lg)  {
        background: $white!important;
    }
    button.mobile-menu-icon {
        overflow: hidden;
        text-align:center; 
        background: $blue;
        color: $white;
        text-align: center;
        border-radius: 50%;
        cursor: pointer;
        padding:0;
        font-weight: 300;

        @include media-breakpoint-down(sm)  {
            height: 40px;
            width: 40px;
            margin: 8% 32px  auto auto;
        }
        @include media-breakpoint-only(md) {
            height: 56px;
            width: 56px;
            margin: 13% 40px auto auto;
        }
        i {
            color: $white;
            margin: auto;
            font-size: 24px;
            line-height: 18px;
            width: auto;
            text-align: center;
            padding: 4px;
            font-weight: 300;
        }
        &:focus {
            outline:0;
        }
        &:active {
            color: $white;
        }
    }       
} 
.menu-button-active {
    background: $gray!important;
}
.menu-button-standard {
    background: $blue!important;
} */
.search-button,
.searchbarHome {
    height: 40px;
    @include media-breakpoint-down(md) {
        margin-top:20px;
        margin-bottom: 16px;
    }
   @include media-breakpoint-up(lg) {
        margin-top: 10px;
    }
    .navbar-search {
        border: 1px solid $gray-lighter;
        color: $gray-darker;
        font-family: 'Open Sans', sans-serif;
        padding-left: 10px;               
        @include media-breakpoint-down(md){
        //  height: 40px;
            font-size: 16px;
            width:64vw;
            margin-left:10vw;
            border-radius: 14px;
        }
       @include media-breakpoint-up(lg){
            border-radius: 20px;
            background: $white!important;
            height: 28px;
            border-radius: 14px;
            width: 150px;
            left:-20px;
        }
        &:focus a,
        &:active a {
            background: $white!important;
        }
    }
}
.dropdown-toggle:after {
    @include media-breakpoint-down(md){
        display:none!important;
    }
}
