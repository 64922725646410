
    *:focus:not(.focus-visible) {
        outline: none;
    }
    
    /* Optional: Customize .focus-visible */
    .focus-visible {
        outline: lightgreen solid 2px;
    }
    
    #skip-to-main {
    width: 100%;
    margin:0;
    line-height: 1.5;
    background: #0000;
    }
    .offscreen {
    position: absolute;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip-path: inset(1px 1px 1px 1px);
    clip-path: inset(1px, 1px, 1px, 1px);
    }

    .skip-to-content.offscreen:focus {
    position: relative;
    display: inline-block;
    height: auto;
    width: 100%;
    padding: 0.2em 1.25em;
    margin-bottom: -20px;
    background:#fff;
    color: #000;
    overflow: visible;
    clip-path: none;
    text-decoration: none;
    z-index:2000;
    }
        