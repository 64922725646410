.highlight-component {
    @include media-breakpoint-down(sm)  {
        padding: 0 1rem 1rem!important;
    }
    @include media-breakpoint-only(md)  {
        padding:2rem!important;
    }
    @include media-breakpoint-up(lg) {
        padding: 2rem 8vw!important;
    }
    .highlight-text-div {
        @include media-breakpoint-down(sm) {
            width: 100vw;
            margin: 3vw;
            text-align: left;
        }
        @include media-breakpoint-up(md) {
            text-align: left;
        }
    }
    .button-div {
        width: 100%;
        text-align:center;
        @include media-breakpoint-down(sm) {
            padding-top: .8rem;
        }
        @include media-breakpoint-up(md) {
            padding-top: .5rem;
        }
    }
    h1,
    h2,
    h3 {
        font-weight: 600;
        font-variation-settings: 'wght' 600;
        width: 100%;

        margin-bottom: 1rem;
        @include media-breakpoint-down(sm) {
            padding-top: .7rem;
            padding-bottom: .2rem;
            font-size: 2.5rem;
        }
        @include media-breakpoint-only(md) {
            font-size: 2.3rem;
        }   
        @include media-breakpoint-only(lg) {
            font-size: 2.3rem;
        } 
        @include media-breakpoint-up(xl) {
            font-size: 3rem;
        }     
    }
    p {
        font-weight: 500;
        font-variation-settings: 'wght' 500;
        @include media-breakpoint-down(sm)  {
            font-size: 1.5rem;
            letter-spacing: 0.003rem!important;
            font-weight: 500;
            font-variation-settings: 'wght' 500;
        }
        @include media-breakpoint-up(xl) {
            font-size: 1.3rem;
            letter-spacing: 0.02rem;
        }
    }
    .order-md-1 p {
        @include media-breakpoint-up(md) {
            margin-right: 1.2rem;
        }
    }
    .order-md-2 p {
        @include media-breakpoint-up(md) {
            margin-left: 1.2rem;
        }
    }
    .highlight-image-div {
        padding: 0;
        @include media-breakpoint-down(sm) {
            width: 100%;
            margin: 0 0 1rem;
        }
        @include media-breakpoint-up(md) {
            margin:0;
        }
        img {
            margin:0;
            padding:0;
            width: 100%;
        }
    }
    .tag {
        font-size: 1rem;
        font-family: 'Oswald',  'HeaderFallBackMac', 'HeaderFallBackWin', sans-serif;
        font-weight: 400;
        font-variation-settings: 'wght' 400;
        text-transform: uppercase;
        color: $gray-darker;
        @include media-breakpoint-down(sm) {
            margin-bottom: .3rem;
        }
        @include media-breakpoint-up (md) {
            margin-bottom: .6rem;
        }
    }
    .subhead {
        font-size: 1.6rem;
        font-family: 'Oswald', 'HeaderFallBackMac', 'HeaderFallBackWin', sans-serif;
        font-weight: 700;
        font-variation-settings: 'wght' 700;
    }
}

  